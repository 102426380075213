import {
  Box,
  Button,
  makeStyles,
  Paper,
  FormControlLabel,
  Modal,
  Switch,
  IconButton,
  CircularProgress,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import { DatePicker } from "formik-material-ui-pickers";
import TimePicker from "react-datepicker";
import { UI_DateFormat, getDecryptedHashWithSecret } from "../../Common/Const/index";
import DateFnsUtils from "@date-io/date-fns";
import { Cancel } from "@material-ui/icons";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import s3FileUpload from "react-s3";
import clsx from "clsx";
import { API_Path } from "../../Common/Const/index";
import { connect } from "react-redux";
import CommonGif from "../../Common/CommonGif";
import CustomImage from "../../Common/CustomImage";
import ConfirmationModal from "../../Common/ConfirmationModal";
import SnackBar from "../../../components/SnackBar";
import UploadVideo from "../../Common/UploadVideo";
import ReactPlayer from "react-player";
import CommentBox from "./Editer";
import CommonMap from "../../Common/CommonMap";
import PdfIcon from "../../../assets/images/pdf.png";
import WordIcon from "../../../assets/images/word.png";
import ExcelIcon from "../../../assets/images/excel.png";
import PptIcon from "../../../assets/images/ppt.png";
import Imageicon from "../../../assets/images/imageicon.svg";
import Videoicon from "../../../assets/images/videoicon.svg";
import Docmenticon from "../../../assets/images/docmenticon.svg";
import Gificon from "../../../assets/images/gificon.svg";
import Recordicon from "../../../assets/images/recordicon.svg";
import Livevideoicon from "../../../assets/images/livevidoicon.svg";
import Location from "../../../assets/images/location.svg";
import { useHistory } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import FormikInput from "../../Common/FormikComponents/FormikInput";
import { ImageSizecompress } from "../../../lib/uriUtils";
import { Uploader } from "../../../lib/upload"
const useStyles = makeStyles((theme) => ({
  paperPostInput: {
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.13)",
    borderRadius: "12px",
    backgroundColor: "#fff",
    margin: "20px 0",
    position: "relative",
    display: "flex",
    padding: "10px",
    flexDirection: "column",
  },
  instantDebate: {
    margin: "40px 0 0px 0",
    padding: "15px 0 30px",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "transparent",
      borderRadius: "12px",
      padding: " 10px 0px",
    },
    "& .MuiFormControl-root": {
      "& legend": {
        fontSize: "14px",
        color: "#313236",
        fontWeight: "500",
        position: "absolute",
        top: "-35px",
        [theme.breakpoints.up("md")]: {},
      },
    },

    "& .MuiFormControlLabel-label": {
      fontSize: "12px",
      color: "#758194",
    },
  },
  timeField: {
    "& .timeLabel": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      margin: "15px 0 4px 0",
    },
    "& .react-datepicker-wrapper": {
      "& input": {
        color: "#758194",
        height: "40px",
        padding: "10.5px 14px",
        fontSize: "12px",
        boxSizing: "border-box",
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
      },
    },
  },
  centerDiv: {
    maxWidth: 425,
    margin: "auto",
  },

  videoInput: {
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.13)",
    borderRadius: "12px",
    backgroundColor: "#fff",
    position: "absolute",
    top: "40%",
    left: "40%",
    padding: "12px 12px 0 12px",
  },
  boxPostInput: {
    width: "89%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  createVurbfooter: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center",
    flexWrap : "wrap",
  },
  quillEditor: {
    margin: "10px 0",
    "& .ql-container": {
      "& .ql-editor": {
        backgroundColor: "#fff",
        paddingTop: "6px",
        // maxHeight:"60px"
      },
      "& .ql-tooltip": {
        display: "none",
      },
    },
  },
  descriptionEditor: {
    height: "92px",
    margin: "10px 0",
    border: "1px solid #c4c4c4",
    borderRadius: "5px",
    padding: "2px",
    "& .ql-container": {
      "& .ql-editor": {
        backgroundColor: "#fff",
        paddingTop: "6px",
        padding: "0px",
        // maxHeight:"60px"
      },
      "& .ql-tooltip": {
        display: "none",
      },
    },
  },
  titleEditor: {
    outline: "none",
    height: "43px",
    width: "426px",
    margin: "10px 0",
    border: "1px solid #c4c4c4",
    borderRadius: "5px",
    padding: "2px",
    "& .ql-container": {
      "& .ql-editor": {
        backgroundColor: "#fff",
        paddingTop: "6px",
        maxHeight: "60px",
      },
      "& .ql-tooltip": {
        display: "none",
      },
    },
  },
  leftCreateSection: {
    width: "675px",
    padding: "36px",
    backgroundColor: "#fff",
    position: "absolute",
    top: "87px",
    left: "400px",
  },
  activeStatus: {
    position: "absolute",
    right: "8px",
    top: "0px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "20px",
    },
  },
  textField: {
    marginTop: theme.spacing(5),
    maxHeight: theme.spacing(5),
    minWidth: "inherit",
    width: "100%",
    "& .MuiInputBase-formControl": {
      maxHeight: theme.spacing(5),
      minWidth: "inherit",
      "& .MuiOutlinedInput-input": {
        height: theme.spacing(5),
        padding: "10.5px 14px",
        fontSize: "12px",
        color: "#758194",
        boxSizing: "border-box",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      color: "#313236",
      fontWeight: "500",
      transform: "translate(0px, -22px) scale(1)",
    },
    "& fieldset": {
      top: "0",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0",
      position: "absolute",
      bottom: "-17px",
    },
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
  },
  gifBtn: {
    border: "1px solid #ffc003",
    borderRadius: "6px",
    color: "white",
    backgroundColor: "#ffc003",
    fontSize: "22px",
  },
  inputImage: {
    position: "absolute",
    color: "#ffc003",
    top: "-7px",
    left: "44px",
    borderRadius: "14px",
    fontSize: "16px",
  },
  inputVideo: {
    position: "absolute",
    color: "#ffc003",
    borderRadius: "14px",
    fontSize: "16px",
    top: "15px",
    backgroundColor: "white",
    cursor: "pointer",
    zIndex: "1111",
    left: "117px",
  },
  inputMap: {
    position: "absolute",
    color: "#ffc003",
    borderRadius: "14px",
    fontSize: "28px",
    top: "6px",
    backgroundColor: "white",
    cursor: "pointer",
    zIndex: "1111",
    right: "8px",
  },
  flexJCSB: {
    display: "flex",
    borderTop: "solid 1px #cdd1d8",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column",
    },
  },
  videoModal: {
    width: "50%",
  },
  gifModal: {
    width: "60%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    width: "16px",
    height: "16px",
    background: "#ffc003",
    color: "#fff",
    "&:hover": {
      background: "#ffc003",
    },
    "& svg": {
      width: 16,
      height: 16,
    },
  },
  description: {
    height: "50px",
  },
  iconName: {
    fontSize: "small",
    color: "black",
    paddingLeft: "8px",
    paddingTop: "3px",
  },
}));

let postType = 0;
let limit = 750;  // No. of characters allowed in content.

const CreateVurb = (props) => {
  const classes = useStyles();
  const editerRef = useRef();
  const addref = useRef();
  const history = useHistory();
  const [editorState, setEditorState] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [images, setImage] = useState([]);
  const [video, setVideo] = useState([]);
  const [docs, setDocs] = useState([]);
  const [location, setLocation] = useState(null);
  const [postStatus, setPostStatus] = useState(true);
  const [file, setFile] = useState({});
  const [postBtn, setpostBtn] = useState(true);
  const [progressLoader, setProgressLoader] = useState(false);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [cameraModal, setCameraModal] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [fieldValue, setFieldValue] = useState();
  const [gifModal, setGifModal] = useState(false);
  const [instantVal, setInstant] = useState(0);
  const [schedule, setSchedule] = useState(false);
  const [titleValue, setTitleValue] = useState("");
  const [descriptionvalue, setDescriptionvalue] = useState("");
  const [liveStreamModal, setliveStreamModal] = useState(false);
  const [timeVal, setTimeVal] = useState(new Date());
  const [mapOpen, setMapOpen] = useState(false);
  const [profileKey, setProfileKey] = useState("")
  const [profileSecret, setProfileSecret] =useState("")
  const [videoBucket, setVideoBucket] = useState("");
  const [imageBucket, setImageBucket] = useState("")
  const source = axios.CancelToken.source();
  let {  REACT_APP_AWS_S3_REGINE, REACT_APP_AWS_S3_ACCESSID, REACT_APP_AWS_S3_SECRET_ACCESSID,REACT_APP_AWS_S3_IMAGE_BUGKET_NAME,REACT_APP_AWS_S3_VIDEO_BUGKET_NAME,REACT_APP_VIDEO_BASEURL } = process.env
  // const config = {
  //   bucketName: REACT_APP_AWS_S3_IMAGE_BUGKET_NAME,
  //   region: REACT_APP_AWS_S3_REGINE,
  //   accessKeyId: REACT_APP_AWS_S3_ACCESSID,
  //   secretAccessKey: REACT_APP_AWS_S3_SECRET_ACCESSID,
  // };
  const config = {
    region: REACT_APP_AWS_S3_REGINE,
    accessKeyId: "",
    secretAccessKey: "",
  };
  useEffect(() => {
    if (props.hashed) {
      let data = getDecryptedHashWithSecret(props.hashed);
      setVideoBucket(data.videoBucket);
      setImageBucket(data.vurbImageBucket);
      setProfileKey(data.profileApiKey);
      setProfileSecret(data.profileApiSecret);
    }
  }, [props]);
  useEffect(() => {
    props.vurbpost &&
      editerRef &&
      editerRef.current &&
      editerRef.current.editor.setContents( 
        editerRef &&
          editerRef.current.editor.clipboard.convert( props.post.content)
      );
    if (props && props.post) {
      props.post.video && setVideo(props.post.video);
      props.post.post_img && setImage(props.post.post_img);
      props.post.docs && setDocs(props.post.docs);
      props.post.type && setPostStatus(!props.post.type);
      props.post.location && setLocation(props.post.location);
      if(props.post.post_type)  {postType = props.post.post_type;}
      if (props.post.post_type === 4) {
        setliveStreamModal(true);
        props.post.live && setTitleValue(props.post.live.title);
        props.post.live && setTimeVal(new Date(props.post.live.start_time));
        props.post.live && setInstant(parseInt(props.post.live.event_type));
        setTimeout(() => {
          props.post.live &&
            addref &&
            addref.current &&
            addref.current.editor.setContents(
              addref &&
                addref.current.editor.clipboard.convert(
                  props.post.live.description
                )
            );
        }, 100);
      }
    }
  }, [props.vurbpost]);
  const editVurb = async (inputObj) => {
    return await axios
      .put(API_Path + "api/vurb/update", inputObj, {
        cancelToken: source.token,
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        props.onSuccess(response.data);
      })
      .catch((e) => console.log("err", e));
  };
  const createVurb = async (inputObj) => {
    return await axios
      .post(API_Path + "api/vurb/create", inputObj, {
        cancelToken: source.token,
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          let result = response.data.result;
          result.user_id = result.created_by;
          postType !== 4 && props.onSuccess(result, response.data.message);
          setpostBtn(true);
          if (postType === 4) {
            postType = 0;
            return result;
          }
          postType = 0;
          clearPost();
        }
      })
      .catch((e) => console.log("err", e));
  };

  const handlePost = async () => {
    let editor = editerRef.current.unprivilegedEditor;
    let mentionIds = [],
      hashtags = [],
      content = editorState;
    
    editor.getContents().ops.map((op) => {
      if (typeof op.insert === "string") {
        op.insert.split(" ").map((val,i) => {
          if (val.includes("#") && val.length > 1) {
            let hashvalue = val.replaceAll("\n", "");
            let html = `<span class="mention" data-index=${i} data-denotation-char="#"data-value="<a href=&quot;${process.env.REACT_APP_API_PATH}deeplink?type=hashtag&amp;token=${hashvalue.substring(1)}&quot;target=_blank >${hashvalue.substring(1)}" data-id="${hashvalue}"data-link="${process.env.REACT_APP_API_PATH}deeplink?type=hashtag&amp;token=${hashvalue.substring(1)}"><span><span class="ql-mention-denotation-char">#</span><a href="${ process.env.REACT_APP_API_PATH}deeplink?type=hashtag&amp;token=${hashvalue.substring(1)}" target="_blank">${hashvalue.substring(1)}</a></span></span>`;
            content = content.replaceAll(hashvalue, html);
            html = "";
            hashtags.push(hashvalue);
          }
          return null;
        });
        return op;
      }
      if (op.insert.mention) {
        if (op.insert.mention.denotationChar === "@") {
          mentionIds.push(op.insert.mention.id);
        }
        if (op.insert.mention.denotationChar === "#") {
          hashtags.push(op.insert.mention.id);
        }
        return op;
      }
      return null;
    });
    let editerEle = document.querySelectorAll('#vurb-add'), origin_content = '';
    if (editerEle.length===1) {
      origin_content = editerEle[0].innerText || editerEle[0].textContent;
    }else{
      origin_content = editerEle[1].innerText || editerEle[1].textContent;
    };
    const inputObj = {
      _id: props.post && props.post._id ? props.post._id : null,
      user_id: props.user._id,
      content: content,
      type: postStatus ? "0" : "1",
      post_type: postType,
      device: "web",
      post_img: images,
      video: video,
      docs: docs,
      location: location,
      mentionIds: mentionIds || [],
      vurb_imgs:(props.post && props.post.vurb_imgs) || [],
      hashtags,
      origin_content:origin_content.trim()
    };
    if (postBtn) {
      props.vurbpost ? editVurb(inputObj) : createVurb(inputObj);
    }
  };
  const handleClear = (type, index) => {
    let img = [...images];
    let file =
      type === "video" ? video[0] : type === "doc" ? docs[0] : img[index];
    fileDelete(file, type);
    img.splice(index, 1);
    setImage([...img]);
    type === "video" && setVideo([]);
    type === "doc" && setDocs([]);
  };
  const goliveStreamModal = () => {
    let checkPackage = currentPackage.findIndex((val) => val.package_name === "Go Live Subscription");
    if (checkPackage !== -1) {
      setliveStreamModal(true);
      setTimeout(() => {
        addref &&
          addref.current &&
          addref.current.editor.setContents(
            addref && addref.current.editor.clipboard.convert(editorState)
          );
      }, 100);
    }else{
      history.push({
        pathname: "/payment",
        state: { type: "Go Live Subscription",},
      });
    }
  };
  const goLive = async (state) => {
    if (titleValue.trim() === "") {
      setSnackbarState({
        open: true,
        message: " Title is empty ",
        severity: "error",
      });
    } else if (addref.current.editor.getText().trim().length === 0) {
      setSnackbarState({
        open: true,
        message: " Description is empty ",
        severity: "error",
      });
    } else {
      let startDate = "",
        tm = "";
      postType = 4;
      if (timeVal) {
        tm = timeVal.getHours() + ":" + timeVal.getMinutes();
      }
      if (instantVal) {
        let dt = moment(timeVal).format("YYYY/MM/DD") + " " + tm;
        let d = new Date(dt);
        startDate = moment([
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          d.getHours(),
          d.getMinutes(),
        ])
          .utc()
          .format();
      }
      let editor = addref.current.unprivilegedEditor;
      let mentionIds = [],
        hashtags = [],
        content = descriptionvalue;
      editor.getContents().ops.map((op) => {
        if (typeof op.insert === "string") {
          op.insert.split(" ").map((val) => {
            if (val.includes("#") && val.length > 1) {
              let hashvalue = val.replace("\n", "");
              let html = `<span class="mention"><span><a href="${
                process.env.REACT_APP_API_PATH
              }deeplink?type=hashtag&amp;token=${hashvalue.substring(
                1
              )}" target="_blank">${hashvalue}</a></span></span>`;
              content = content.replace(hashvalue, html);
              html = "";
              hashtags.push(hashvalue);
            }
            return null;
          });
          return op;
        }
        if (op.insert.mention) {
          if (op.insert.mention.denotationChar === "@") {
            mentionIds.push(op.insert.mention.id);
          }
          if (op.insert.mention.denotationChar === "#") {
            hashtags.push(op.insert.mention.id);
          }
          return op;
        }
        return null;
      });
      let editerEle = document.getElementById('vurb-live'), origin_content = '';
      if (editerEle) {
        origin_content = editerEle.innerText || editerEle.textContent;
      }
      const inputObj = {
        _id: props.post && props.post._id ? props.post._id : null,
        user_id: props.user._id,
        title: titleValue || "",
        description: descriptionvalue || "",
        event_type: instantVal,
        start_time: instantVal ? startDate : "",
        type: postStatus ? "0" : "1",
        post_type: postType,
        device: "web",
        location: location,
        mentionIds: mentionIds || [],
        hashtags,
        origin_content:origin_content.trim()
      };
      // console.log(new Date(startDate),"val",startDate,"moment",moment(new Date(startDate)))
      const result = props.vurbpost
        ? await editVurb(inputObj)
        : await createVurb(inputObj);
      !props.vurbpost &&
        history.push({
          pathname: "/vurbsDetail",
          state: { postObj: { post: result } },
        });
    }
  };
  const fileUpload = async (file, type) => {
    // config.bucketName = type === "video" ? REACT_APP_AWS_S3_VIDEO_BUGKET_NAME :REACT_APP_AWS_S3_IMAGE_BUGKET_NAME;
    config.bucketName = type === "video" ? videoBucket :imageBucket;
    config.accessKeyId = profileKey;
    config.secretAccessKey = profileSecret;
    file = new File(
      [file],
      `vurbs__${props.user._id}__${Date.now().toString()}__${file.name}`
    );
    return await s3FileUpload
      .uploadFile(file, config)
      .then((data) => data)
      .catch((e) => console.log("err", e));
  };
  const fileDelete = async (url, type) => {
    // config.bucketName = type === "video" ? REACT_APP_AWS_S3_VIDEO_BUGKET_NAME : REACT_APP_AWS_S3_IMAGE_BUGKET_NAME;
    config.bucketName = type === "video" ? videoBucket :imageBucket;
    config.accessKeyId = profileKey;
    config.secretAccessKey = profileSecret;
    const fileName = url.split("/")[url.split("/").length - 1];
    await s3FileUpload
      .deleteFile(fileName, config)
      .then((data) => console.log(data))
      .catch((e) => console.log("err", e));
  };
  // const cancelPost = async () => {
  //   if (images.length > 0) { for (let img of images) { await fileDelete(img, 'image') } }
  //   video.length > 0 && await fileDelete(video[0], 'video')
  //   docs.length > 0 && await fileDelete(docs[0], 'docs')
  //   clearPost();
  // };
  const clearPost = () => {
    editerRef &&
      editerRef.current.editor.deleteText(
        0,
        editerRef.current.editor.getLength()
      );
    props.onSuccess();
    setImage([]);
    setVideo([]);
    setDocs([]);
    setEditorState("");
    setPostStatus(true);
    setLocation(null);
    postType = 0;
  };
  // console.log(editorState)
  const handleEditor = (e) => {
    const quill = editerRef.current && editerRef.current.getEditor();
    // let limit = 200;   
    quill.enable();
    quill.on("text-change", (delta, old, source) => {
      if (quill.getLength() > limit) {
        quill.deleteText(limit, quill.getLength());
      }
    });
    setEditorState(e);
  };
  const handleDescription = (e) => {
    const quill = addref.current && addref.current.getEditor();
    
    quill.on("text-change", (delta, old, source) => {
      if (quill.getLength() > limit) {
        quill.deleteText(limit, quill.getLength());
      }
    });
    setDescriptionvalue(e);
  };
  const handleLiveTitle = (e) => {
    let limit = 60;
    let data = e.target.value.replace(/[^\w\s]/gi, "");
    if (data.length <= limit) {
      setTitleValue(data);
    }
  };
  const handlePostImage = async (e) => {
    let tempImage = [...images];
    if (
      tempImage.length > 0
        ? tempImage.length + e.target.files.length <= 4
        : e.target.files.length <= 4
    ) {
      setProgressLoader("images");
      for (let file of e.target.files) {
        console.log('Before file upload Size',file.size / (1024 ** 2))
        let compressorfile=await ImageSizecompress(file)
        console.log('After file upload Size',compressorfile.size / (1024 ** 2))
        let result = await fileUpload(compressorfile, "image");
        tempImage.push(result.location);
      }
      setProgressLoader(false);
    } else {
      setSnackbarState({
        open: true,
        message: "4 Images or 1 Video less than 60 Sec is allowed",
        severity: "error",
      });
    }
    setImage(tempImage);
    // setPostType(1)
    postType = 1;
  };
  const handleGif = (gif) => {
    setGifModal(false);
    let tempGif = [...images];
    if (tempGif.length < 4) {
      tempGif.push(gif);
    } else {
      setSnackbarState({
        open: true,
        message: "4 Images or 4 Gif is allowed",
        severity: "error",
      });
    }
    if (tempGif.length > 0) {
      let filterGif = tempGif.filter(
        (data) => data.split(".")[data.split(".").length - 1] === "gif"
      );
      if (filterGif.length === tempGif.length) {
        postType = 6;
      } else {
        postType = 1;
      }
    }
    setImage(tempGif);
  };
  const handlePostVideo = async (e) => {
    let file = e.target.files[0];
    let video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = async () => {
      // window.URL.revokeObjectURL(video.src);
      // console.log(video.duration, video.src, file)
      if (video.duration > 60) {
        setSnackbarState({
          open: true,
          message: "Video should be less than 60 Sec",
          severity: "error",
        });
        return false;
      } else {
        const videoUploaderOptions = {
          file: file,
          fileName:   `${props.user.usertagging?props.user.usertagging:props.user.firstName}__VW_${Date.now().toString()}_${file.name.trim().replace(/\s/g,"")}`,
          // fileName:   `vurb_${Date.now().toString()}_${file.name}`,
          token:props.auth.accessToken
        }
  
        let percentage = undefined
        setProgressLoader("video");
        const uploader = new Uploader(videoUploaderOptions)
        // setUploader(uploader)
  
        uploader
          .onProgress(({ percentage: newPercentage }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage
              // console.log(`${percentage}%`)
            }
          })
          .onError((error) => {
            setFile(undefined)
            console.error(error)
          })
         
       await uploader.start();
       uploader.onComplete((responseData)=>{
        // console.log("oncomplete responseData",responseData);
        if(responseData&&responseData.data){
          setProgressLoader(false);
          let url = responseData.data.Location.split('/')
          setVideo([`${REACT_APP_VIDEO_BASEURL}${url[url.length - 1]}`,responseData.data.Location]);
          // setVideo([responseData.data.Location]);
          postType = 3;
        }else{
          setProgressLoader(false);
          setSnackbarState({
            open: true,
            message: "Some thing went wrong",
            severity: "error",
          });
        }
       })
      //  const fileData=await uploader.complete();
      //  console.log('fileData',fileData);
        // return 
        // setProgressLoader("video");
        // let result = await UploadVideoAws(file, "video");
        // console.log('result',result)
        // if(result&&result.status===1){
        //   setProgressLoader(false);
        //   setVideo([result.data.Location]);
        //   postType = 3;
        // }else{
        //   setProgressLoader(false);
        //   setSnackbarState({
        //     open: true,
        //     message: "Some thing went wrong",
        //     severity: "error",
        //   });
        // }
        // let result = await fileUpload(file, "video");
        // setProgressLoader(false);
        // setVideo([result.location]);
        // // setPostType(3)
        // postType = 3;
      }
      // console.log(e.target.files[0])
      // fileUpload(e.target.files[0])
    };
    video.src = URL.createObjectURL(e.target.files[0]);
    // console.log("after",e.target.files[0])
  };
  const UploadVideoAws=async(file)=>{
    try{
        const formData=new FormData()
        formData.append('files',file)
        formData.append('name',`vurbs__${props.user._id}__${Date.now().toString()}__${file.name}`)
        return await axios
          .post(API_Path + "api/vurb/video/upload", formData, {
            cancelToken: source.token,
            headers: {
              Authorization: "Basic " + props.auth.accessToken,
            },
          })
          .then((response) => {
           console.log('response',response)
           return response.data
          })
          .catch((e) => console.log("err", e));
    }catch(e){
      console.log('error',e)
    }
  }
  const handlePostDoc = (e) => {
    if (!!e.target.files[0]) {
      setFile(e.target.files[0]);
      setOpenDialog(true);
    }
  };
  const handleDialogClose = () => {
    setFile({});
    setOpenDialog(false);
  };
  const uploadDoc = async () => {
    setProgressLoader("docment");
    setOpenDialog(false);
    let result = await fileUpload(file, "doc");
    setProgressLoader(false);
    setDocs([result.location]);
    // setPostType(5)
    postType = 5;
  };
  const handleSwitch = (e) => {
    setPostStatus(e.target.checked);
  };
  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };
  const handleInstant = async (event) => {
    setInstant(parseInt(event.target.value));
  };
  const DocmentImage = (docs) => {
    let docmentname = docs[0].split("__")[docs[0].split("__").length - 1];
    let type = docmentname.split(".")[docmentname.split(".").length - 1];
    let src =
      type === "pdf"
        ? PdfIcon
        : type === "docx"
        ? WordIcon
        : type === "pptx"
        ? PptIcon
        : ExcelIcon;
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div>
            {" "}
            {
              <img
                src={src}
                style={{ width: "50px", cursor: "pointer" }}
                alt="Doc"
              />
            }
          </div>
        </div>
      </Fragment>
    );
  };
  const closeModal = () => {
    setCameraModal(false);
  };
  const handleRecordVid = async (type) => {
    // config.bucketName = "vurbil-video-inputs";
    config.bucketName = videoBucket;
    setCameraModal(false);
    const videoUploaderOptions = {
      file: fieldValue,
      fileName: `vurb_${Date.now().toString()}_${fieldValue.name}`,
      token: props.auth.accessToken
    }

    let percentage = undefined
    setProgressLoader("record");
    const uploader = new Uploader(videoUploaderOptions)
    // setUploader(uploader)

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage
          // console.log(`${percentage}%`)
        }
      })
      .onError((error) => {
        setProgressLoader(false);
        console.error(error)
      })
    await uploader.start();
    uploader.onComplete((responseData) => {
      // console.log("oncomplete responseData",responseData);
      if (responseData && responseData.data) {
        setProgressLoader(false);
        let url = responseData.data.Location.split('/')
        setVideo([`${REACT_APP_VIDEO_BASEURL}${url[url.length - 1]}`, responseData.data.Location]);
        postType = 2;
      } else {
        setProgressLoader(false);
        setSnackbarState({
          open: true,
          message: "Some thing went wrong",
          severity: "error",
        });
      }
    })
    // let result = await s3FileUpload
    //   .uploadFile(fieldValue, config)
    //   .then((data) => data)
    //   .catch((e) => console.log("err", e));
    // setProgressLoader(false);
    // // const url=result.location.replace('vurbil-video-inputs','vurbs-videos');
    // let url = result.location.split('/')
    // setVideo([`https://videos.vurbil.com/${url[url.length - 1]}`]);
    // console.log('url',result.location)
    // setVideo([result.location]);
    // setPostType(2)
    // postType = 2;
  };

  const filterPassedTime = (dob) => {
    const currentDate = new Date();
    const selectedDate = new Date(dob);
    setSchedule(true);
    return currentDate.getTime() < selectedDate.getTime();
  };

  let disabledBtn = true;
  if (editerRef && editerRef.current) {
    let edit = editerRef.current.unprivilegedEditor.getContents().ops;
    if (
      editerRef.current.editor.getText().trim().length > 0 ||
      edit.length > 1 ||
      images.length > 0 ||
      video.length > 0 ||
      docs.length > 0 ||
      (location && Object.keys(location).length > 0)
    ) {
      disabledBtn = false;
    }
  }
  let timeZone = new Date().getTimezoneOffset() / 60;
  let temVal = timeZone.toString();
  temVal = temVal.replace("-5.5", "+5:30");
  timeZone = "UTC " + temVal;
  let showloader = Boolean(progressLoader);
  let { currentPackage = [] } = props || {};
  const quill = editerRef.current && editerRef.current.getEditor();
  let stringCount = quill? quill.getLength()>0?quill.getLength()-1:0:0
  return (
    <>
      <Paper
        className={classes.paperPostInput}
        style={
          props.vurbpost
            ? {
                width: "750px",
                position: "absolute",
                left: "24%",
                top: "40px",
                display: liveStreamModal ? "none" : "",
              }
            : {}
        }
      >
        <div>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <div style={{ position: "relative" }}>
              <CustomImage
                userImage={props.user.profile_image}
                size="52px"
                marginRight="0"
              />
              {!!props.user.badge_status && (
                <span
                  className={classes.activeStatus}
                  title="Verified User/Public Figure"
                >
                  <VerifiedUserRoundedIcon />
                </span>
              )}
            </div>
            <h4 style={location && Object.keys(location).length > 0 ? {display:"flex",flexDirection:"column",maxWidth:"352px"}:{}}><span>{props.user.name}</span>{location && Object.keys(location).length > 0 &&<span style={{fontWeight:'lighter',fontSize:"14px"}}> is in {location.name}</span>}</h4>
            <FormControlLabel
              control={
                <Switch
                  checked={postStatus}
                  onChange={handleSwitch}
                  name="Switch"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label={postStatus ? "Public" : "Private"}
              labelPlacement="start"
            />
            <div style={{ position: "absolute", right: "20px" }}>
              {props.vurbpost && (
                <Button style={{ color: "#8a847b" }} onClick={props.close}>
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                className={clsx(classes.circleBtn)}
                onClick={() => {
                  setpostBtn(false);
                  handlePost();
                }}
                disabled={disabledBtn}
              >
                {props.vurbpost ? "Update" : "Post"}
              </Button>
            </div>
          </Box>
          <div>
            <CommentBox
              theme="bubble"
              className={classes.quillEditor}
              placeholder="What do you want to vurb about?"
              newref={editerRef}
              onChange={handleEditor}
              user={props.user}
              auth={props.auth}
              id='vurb-add'
            />
            <div>
              <p style={{float:"right",fontSize:"12px",margin:'0'}}>{`${stringCount}/${limit}`}</p>
            </div>
            {location && Object.keys(location).length > 0 && !(images.length > 0 ||
                      video.length > 0 ||
                      docs.length > 0) &&  (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ position: "relative" }}>
                  <span title="Remove">
                    <Cancel
                      title="Remove"
                      className={classes.inputMap}
                      onClick={() => setLocation(null)}
                    />
                  </span>
                  <img
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${location.lat},${location.lng}&zoom=13&size=520x300&markers=color:red%7C${location.lat},${location.lng}&key=AIzaSyCsXeKdTbHEM-GC36fI5dqrBqVBAhiKpc4`}
                    alt="staticmap"
                  />
                </div>
              </div>
            )}

            <div
              style={{
                textAlign: "right",
                padding: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Box style={{ display: "flex" }}>
                {images.length > 0 &&
                  images.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", padding: "0 4px" }}
                    >
                      <span title="Remove">
                        {" "}
                        <Cancel
                          className={classes.inputImage}
                          style={{
                            backgroundColor: "white",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClear("image", index)}
                        />
                      </span>
                      <img
                        src={image}
                        style={{ width: "50px", height: "50px" }}
                        alt="cancel"
                      />
                    </div>
                  ))}
              </Box>
              {video.length > 0 && (
                <Box style={{ display: "flex" }}>
                  <div
                    style={{
                      position: "relative",
                      padding: "0 4px",
                      right: "136px",
                    }}
                  >
                    <span title="Remove">
                      <Cancel
                        title="Remove"
                        className={classes.inputVideo}
                        onClick={() => handleClear("video")}
                      />
                    </span>
                    <ReactPlayer
                      url={video[1]?video[1]:video}
                      controls={true}
                      width="123px"
                      height="113px"
                    />
                  </div>
                </Box>
              )}
              {docs.length > 0 && (
                <Box style={{ display: "flex" }}>
                  <div
                    style={{
                      position: "relative",
                      padding: "0 4px",
                      right: "150px",
                    }}
                  >
                    <span title="Remove">
                      <Cancel
                        title="Remove"
                        className={classes.inputImage}
                        style={{ backgroundColor: "white", cursor: "pointer" }}
                        onClick={() => handleClear("doc")}
                      />
                    </span>
                    {DocmentImage(docs)}
                  </div>
                </Box>
              )}
            </div>
          </div>
        </div>
        <div>
          <Box>
            <div className={classes.flexJCSB}>
              <div className={classes.createVurbfooter}>
                {progressLoader === "images" && showloader ? (
                  <IconButton>
                    {" "}
                    <CircularProgress
                      style={{ width: "20px", height: "20px" }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    disabled={
                      showloader || images.length >= 4 ||
                      video.length > 0 ||
                      docs.length > 0 
                        ? true
                        : false
                    }
                  >
                    <input
                      hidden
                      accept="image/jpeg,image/png,image/webp"
                      type="file"
                      multiple
                      className={classes.InputAttach}
                      onChange={handlePostImage}
                    />
                    <span title="Upload picture" style={{ display: "flex" }}>
                      <img src={Imageicon} style={{ width: "23px" }} alt="" />
                    </span>{" "}
                    <span className={classes.iconName} title="Upload picture">
                      Photo
                    </span>
                  </IconButton>
                )}
                <IconButton
                  component="label"
                  disabled={
                    showloader || images.length >= 4 ||
                    video.length > 0 ||
                    docs.length > 0 
                      ? true
                      : false
                  }
                  onClick={() => {
                    !(
                      images.length >= 4 ||
                      video.length > 0 ||
                      docs.length > 0
                    ) && setGifModal(true);
                  }}
                >
                  <span
                    title="Upload gif"
                    style={{ display: "flex" }}
                  >
                    <img src={Gificon} alt="Gif" />
                    {/* <Gif className={classes.gifBtn} style={images.length >= 4 || video.length > 0 || docs.length > 0 ? { opacity: '0.5' } : {}}  /> */}
                    <span className={classes.iconName}>GIF</span>
                  </span>
                </IconButton>
                {progressLoader === "record" && showloader ? (
                  <IconButton>
                    {" "}
                    <CircularProgress
                      style={{ width: "20px", height: "20px" }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    color="primary"
                    component="label"
                    disabled={
                      showloader || images.length > 0 ||
                      video.length > 0 ||
                      docs.length > 0 
                        ? true
                        : false
                    }
                    onClick={() => setCameraModal(true)}
                  >
                    <span
                      title="Record"
                      style={{ display: "flex" }}
                    >
                      <img style={{ width: "23px" }} src={Recordicon} alt="" />
                      <span className={classes.iconName} alt="">
                        Record
                      </span>
                    </span>
                  </IconButton>
                )}

                {progressLoader === "video" && showloader ? (
                  <IconButton>
                    {" "}
                    <CircularProgress
                      style={{ width: "20px", height: "20px" }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    color="primary"
                    aria-label="Upload Video"
                    component="label"
                    disabled={
                      showloader || images.length > 0 ||
                      video.length > 0 ||
                      docs.length > 0 
                        ? true
                        : false
                    }
                  >
                    <input
                      // ref={videoUploadRef}
                      hidden
                      accept="video/mp4,video/mkv,video/3gp"
                      type="file"
                      className={classes.InputAttach}
                      onChange={handlePostVideo}
                    />
                    <span title="Upload video" style={{ display: "flex" }}>
                      <img
                        src={Videoicon}
                        style={{ width: "23px" }}
                        alt="Video"
                      />
                    </span>
                    <span title="Upload video" className={classes.iconName}>
                      Video
                    </span>
                  </IconButton>
                )}
                {props.vurbpost ? (
                  ""
                ) : (
                  <IconButton
                    color="primary"
                    component="label"
                    disabled={
                      showloader || images.length > 0 ||
                      video.length > 0 ||
                      docs.length > 0 
                        ? true
                        : false
                    }
                    onClick={() => goliveStreamModal()}
                  >
                    <span
                      title="Live Video"
                      style={{ display: "flex" }}
                    >
                      <img
                        style={{ width: "24px" }}
                        src={Livevideoicon}
                        alt=""
                      />
                      <span className={classes.iconName} alt="">
                        Go Live
                      </span>
                    </span>
                  </IconButton>
                )}
                {progressLoader === "docment" && showloader ? (
                  <IconButton>
                    {" "}
                    <CircularProgress
                      style={{ width: "20px", height: "20px" }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    color="primary"
                    component="label"
                    disabled={
                      showloader || images.length > 0 ||
                      video.length > 0 ||
                      docs.length > 0 
                        ? true
                        : false
                    }
                  >
                    <input
                      hidden
                      accept=".pdf,.docx,.pptx,.xlsx"
                      type="file"
                      className={classes.InputAttach}
                      onChange={handlePostDoc}
                    />
                    <span title="Upload document" style={{ display: "flex" }}>
                      <img src={Docmenticon} alt="" />
                      <span className={classes.iconName} alt="">
                        Document
                      </span>
                      {/* <Description style={
                  images.length > 0 || video.length > 0 || docs.length > 0
                    ? { opacity: "0.5", fontSize: '22px' }
                    : { fontSize: '22px' }
                } /> */}
                    </span>
                  </IconButton>
                )}
                <IconButton
                  color="primary"
                  onClick={() => setMapOpen(true)}
                >
                  <span
                    title="Check In"
                    style={{ display: "flex" }}
                  >
                    <img style={{ width: "16px" }} src={Location} alt="" />
                    <span className={classes.iconName} alt="">
                      Check-In
                    </span>
                  </span>
                </IconButton>
              </div>
            </div>
          </Box>
        </div>
      </Paper>
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
      <ConfirmationModal
        openDialog={openDialog}
        modalHeading="Message"
        handleDialogClose={handleDialogClose}
        handleDelete={uploadDoc}
        modalMessage={`Please check that this PDF is your document or regarded as fair use and is not anything proprietary or would violate Vurbil's Terms of Service etc.`}
        btnCancelName="No"
        btnNoConfirmName="Yes"
        maxWidth="md"
      />
      <Modal open={cameraModal} className={classes.videoModal}>
        <div>
          <Paper
            className={classes.paperPostInput}
            style={{ transform: "translate(50%,12%)" }}
          >
            <UploadVideo
              setPreviewVideo={setPreviewVideo}
              setIsFileUploaded={setIsFileUploaded}
              setFieldValue={setFieldValue}
              previewVideo={previewVideo}
              isFileUploaded={isFileUploaded}
              onClose={closeModal}
              onSubmit={handleRecordVid}
              userId={props.user._id}
            />
          </Paper>
        </div>
      </Modal>
      <Modal open={gifModal}>
        <Box
          className={classes.gifModal}
          style={{ transform: "translate(36%,34%)" }}
        >
          <CommonGif
            onSelect={handleGif}
            height={"400px"}
            onClose={() => {
              setGifModal(false);
            }}
          />
        </Box>
      </Modal>
      <Modal open={liveStreamModal}>
        <div className={classes.leftCreateSection}>
          <Formik
            enableReinitialize
            initialValues={{ title: "" }}
            onSubmit={goLive}
            // validationSchema={validationSchema}
            validateOnBlur={true}
          >
            {(isSubmitting) => (
              <Form>
                <div className={classes.centerDiv}>
                  <span>Title</span>
                  <br></br>
                  <input
                    name="title"
                    type="text"
                    placeholder=""
                    className={classes.titleEditor}
                    onChange={handleLiveTitle}
                    value={titleValue}
                  />
                  <span> Description </span>
                  <CommentBox
                    theme="bubble"
                    className={classes.descriptionEditor}
                    placeholder=""
                    newref={addref}
                    onChange={handleDescription}
                    user={props.user}
                    auth={props.auth}
                    id='vurb-live'
                  />
                  <div className={classes.instantDebate}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Event Type</FormLabel>
                      <RadioGroup
                        row
                        aria-label="event_type"
                        name="event_type"
                        value={instantVal}
                        onChange={handleInstant}
                      >
                        <FormControlLabel
                          value={0}
                          control={<Radio color="secondary" />}
                          label="Live"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value={1}
                          control={<Radio color="secondary" />}
                          label="Schedule"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                    {!!instantVal && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Field
                                component={DatePicker}
                                format={UI_DateFormat}
                                name="dob"
                                label="Date"
                                placeholder=""
                                value={timeVal}
                                disabled={false}
                                onChange={(date) => setTimeVal(date)}
                                as={FormikInput}
                                className={clsx(
                                  classes.textField,
                                  classes.labelShrink
                                )}
                                autoOk
                                inputVariant="outlined"
                                variant="inline"
                                minDate={new Date()}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <div className={classes.timeField}>
                              <div className="timeLabel">Time</div>
                              <TimePicker
                                selected={timeVal}
                                onChange={(date) => setTimeVal(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                filterTime={filterPassedTime}
                                timeIntervals={5}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                showDisabledMonthNavigation
                                onKeyDown={(e) => e.preventDefault()}
                                disabled={false}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Field
                              name="time_zone"
                              label="Time Zone"
                              placeholder=""
                              disabled
                              value={timeZone}
                              type="text"
                              as={FormikInput}
                              className={clsx(classes.textField)}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </div>
                  <FormControlLabel
                    style={{ marginLeft: "0px" }}
                    control={
                      <Switch
                        checked={postStatus}
                        onChange={handleSwitch}
                        name="Switch"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label={postStatus ? "Public" : "Private"}
                    labelPlacement="start"
                  />
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    marginTop="20px"
                  >
                    <Button
                      onClick={() => {
                        setliveStreamModal(false);
                        props.vurbpost && props.close();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={clsx(classes.circleBtn)}
                      type="submit"
                    >
                      {props.vurbpost
                        ? "UPDATE LIVE"
                        : schedule
                        ? "SCHEDULE"
                        : "GO LIVE"}
                    </Button>
                  </Box>{" "}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <CommonMap isOpen={mapOpen} close={setMapOpen} result={setLocation}/>
    </>
  );
};
// export default CreateVurb
const mapStateToProps = ({ loginDetails: { user, auth, currentPackage, hashed } }) => {
  return {
    user,
    auth,
    currentPackage,
    hashed
  };
};
export default connect(mapStateToProps)(CreateVurb);
