import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useImperativeHandle,
  Fragment,
} from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import axios from "axios";
import { API_Path } from "../Const";
import Loader from "../Loader";
import CommonPostCard from "../CommonPostCard";

const limit = 5;
let totalCount = 0;
let searchEnable = false;
const CommonVurb = React.forwardRef((props, ref) => {
  useImperativeHandle(
    ref,
    () => {
      return {
        reload() {
          setLoader(true);
          getPostDetails(0, "", true);
        },
      };
      //eslint-disable-next-line
    },
    [ref]
  );
  // const CommonVurb = (props) => {
  const [postList, setPostList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [skip, setSkip] = useState(0);
  const [apiLoading, setApiLoading] = useState(false);
  const { UpdatePostLike, searchVal, vurb_like_data, tab, globalSearch,allBlockedUsers=[] } = props;
  const observer = useRef();
  const lastNotification = useCallback((node) => {
    if (apiLoading) return
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (totalCount > postList.length) {
          // console.log('Rames',skip + limit)
          setApiLoading(true);
          getPostDetails(skip + limit, searchVal||globalSearch);
        }
      }
    });
    if (node) observer.current.observe(node);
  });

  useEffect(() => {
    if(!globalSearch&&!searchVal){
      getPostDetails();
      setLoader(true);
    }
  }, []);
  useEffect(() => {
    if (searchVal) {
      searchEnable = true;
      getPostDetails(0, searchVal);
    } else {
      if (searchEnable) {
        setPostList([])
        searchEnable=false
        getPostDetails(0,'');
        setLoader(true);
      }
    }
  }, [searchVal]);
  // Socket Like data Update to previous Array Using Vurb Id
  useEffect(() => {
    if (Object.keys(vurb_like_data).length > 0) {
      let temparray = [...postList];
      let index = temparray.findIndex((val) => val._id === vurb_like_data._id);
      if (index > -1) {
        temparray.splice(index, 1, {
          ...temparray[index],
          dislike_users: vurb_like_data.dislike_users,
          dislike: vurb_like_data.dislike,
          like: vurb_like_data.like,
          like_users: vurb_like_data.like_users,
        });
        setPostList(temparray);
      }
    }
  }, [vurb_like_data]);
  // End

  const getPostDetails = async (skip = 0, val = "", reload = false) => {
   try{ 
    axios
      .post(
        API_Path +
          `api/vurb/get`,
          {
            user_id: props.user._id,
            limit:limit,
            skip:skip,
            tab:tab,
            searchText:val,
            allBlockedUserList:allBlockedUsers
          },
        {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        }
      )
    // axios
    //   .get(
    //     API_Path +
    //       `api/vurb/get?user_id=${props.user._id}&limit=${limit}&skip=${skip}&tab=${tab}&searchText=${val}`,
    //     {
    //       headers: {
    //         Authorization: "Basic " + props.auth.accessToken,
    //       },
    //     }
    //   )
      .then((response) => {
        // console.log(response.data.result)
        if (response && response.data) {
          if(reload){
            setPostList(response.data.result.data)
          }else{
            val && skip === 0
              ? setPostList(response.data.result.data)
              : setPostList((p) => [...p, ...response.data.result.data]);
          }
          totalCount = response.data.result.totalCount;
          setLoader(false);
          setSkip(skip);
          setApiLoading(false);
        }
      })
      .catch((e) => console.log("err", e));
    }catch(err){
      console.log('error',err)
    }
  };
  const onDelete = (index) => {
    // console.log(index)
    let tempPost = [...postList];
    tempPost.splice(index, 1);
    setPostList(tempPost);
    setSkip(skip - 1);
  };
  const refresh = () => {
    setLoader(true);
    getPostDetails(0, "", true);
  };
  return (
    <>
      {!loader ? (
        !!postList && postList.length > 0 ? (
          postList.map((data, index) => (
            <Fragment key={index}>
              <CommonPostCard
                index={index}
                post={data}
                userId={props.user._id}
                ref={
                  postList.length - 1 === index
                    ? {
                        ref: lastNotification,
                        UpdatePostLike,
                        onDelete,
                        refresh,
                      }
                    : { ref: null, UpdatePostLike, onDelete, refresh }
                }
                auth={props.auth}
                user={props.user}
                hashed={props.hashed}
              />
              {apiLoading && postList.length - 1 === index && (
                <div style={{display:"flex", justifyContent:"center"}}>
                  <CircularProgress />
                </div>
              )}
            </Fragment>
          ))
        ) : (
          <Box mt={3}>
            <Typography align="center" variant="h6">
              {`No ${tab === 'Promoted Vurbs'?"Current":''} ${tab === 'My Vurbers'?"Vurbers":tab} Available`}
            </Typography>
          </Box>
        )
      ) : (
        ""
      )}
      {loader && <Loader />}
    </>
  );
});

export default CommonVurb;
