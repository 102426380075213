import { Box, makeStyles, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Advertisement from "../../Common/Advertisement";
import ClassificationList from "../../Common/ClassificationList";
import CustomPagination from "../../Common/CommonPagination";
import { API_Path, clearStateLocation } from "../../Common/Const";
import CustomDebateVideoContent from "../../Common/CustomDebateVideoContent/index";
import CustomSearch from "../../Common/CustomSearch";
import FeaturedDebaters from "../../Common/FeaturedDebaters";
import Loader from "../../Common/Loader";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: "#f6f7f8",
    // padding: '30px',
    padding: "18px 0 40px 0",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      // padding: '30px 40px',
      padding: "18px 18px 40px 18px",
    },
  },
  contentRightSection: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "calc(100% - 260px)",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& > div": {
      [theme.breakpoints.up("md")]: {
        width: "calc(100% - 364px)",
      },
      "&:only-child": {
        [theme.breakpoints.up("md")]: {
          width: "100%",
        },
      },
    },
  },
  leftSection: {
    padding: "0 18px 18px 18px",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 260px",
      maxWidth: "260px",
    },
  },
  contentSection: {
    width: "100%",
    padding: "0 18px",
  },
  rightSection: {
    padding: "0 18px 18px 18px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 364px",
      maxWidth: "364px",
    },
  },
  searchWhiteBg: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
}));

const Broadcast = (props) => {
  const classes = useStyles();
  const [broadcastData, setBroadcastData] = useState([]);
  const [debateTotalCount, setDebateTotalCount] = useState(0);
  const [categoryId, setcategoryId] = useState(null);
  const [searchText, setsearchText] = useState("");
  const catId = useRef("");
  const myRef = useRef("");
  const [loader, setLoader] = useState(false);
  const [classificationLoaded, setClassificationLoaded] = useState(false);
  const [debateListLoaded, setDebateListLoaded] = useState(false);
  const [page, setPage] = React.useState(1);
  const pageTotalCount = Math.ceil(debateTotalCount / 10);
  const handleChange = (event, value) => {
    setPage(value);
    getBroadcastList(value);
  };

  const handleDebateDetails = (categoryId) => {
    if (categoryId === "All") {
      catId.current = null;
      setcategoryId(null);
      getBroadcastList(null);
      if (myRef.current) {
        myRef.current.getAdvertisementList("All");
      }
    } else {
      catId.current = categoryId;
      setcategoryId(catId.current);
      getBroadcastList(catId.current);
      if (myRef.current) {
        myRef.current.getAdvertisementList(catId.current);
      }
    }
    setPage(1);
  };
  const getBroadcastList = useCallback(
    async (currentPageVal) => {
      let URL = API_Path + "api/broadcastvideo/list";
      var currenturl = window.location.href;

      if (
        props.auth &&
        props.auth.accessToken &&
        (searchText.length > 3 || searchText === "")
      ) {
        let inputObj = {
          inputObject: {
            type:
              currenturl !== null && currenturl.indexOf("kpnews") > -1
                ? "KP News"
                : "KP Show",
            userId: props.user._id,
            // "categoryId": categoryId ? categoryId : null,
            searchText: searchText,
            pagination: {
              currentpage: currentPageVal ? currentPageVal : 1,
              recordsperpage: 10,
            },
          },
        };
        if (catId.current) {
          inputObj.inputObject["categoryId"] = catId.current;
        }
        axios
          .post(URL, inputObj, {
            headers: {
              Authorization: "Basic " + props.auth.accessToken,
            },
          })
          .then((response) => {
            setBroadcastData(response.data.result.data);
            setDebateTotalCount(response.data.result.totalCount);
            setDebateListLoaded(true);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    [props.auth, props.user._id, searchText]
  );

  useEffect(() => {
    clearStateLocation();
  }, []);

  useEffect(() => {
    getBroadcastList();
  }, [searchText]);
  useEffect(() => {
    setLoader(true);
    if (classificationLoaded === true && debateListLoaded === true) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [classificationLoaded, debateListLoaded]);
  const classifyLoaded = (val) => {
    setClassificationLoaded(val);
  };
  const handleSearch = (event) => {
    getBroadcastList(categoryId);
    setsearchText(event.target.value);
    setPage(1);
  };
  const AdvertisementSection = (
    <div className={classes.rightSection}>
      <Advertisement innerRef={myRef} />
    </div>
  );
  return (
    <div className={classes.pageContainer}>
      <div className={classes.leftSection}>
        <ClassificationList
          handleDebateDetails={handleDebateDetails}
          classifyLoaded={classifyLoaded}
        />
        <FeaturedDebaters />
      </div>
      <div className={classes.contentRightSection}>
        <div className={classes.contentSection}>
          <div className={classes.flexJCSB}>
            <CustomSearch
              className={classes.searchWhiteBg}
              handleSearch={handleSearch}
              value={searchText}
            />
          </div>
          {/* <Box mt={2}>
                        <CustomPagination page={page} count={pageTotalCount} handleChange={handleChange} JusCont="flex-end" />
                    </Box> */}
          {broadcastData && broadcastData.length > 0 ? (
            <>
              {broadcastData &&
                broadcastData.map((list) => {
                  return (
                    <CustomDebateVideoContent
                      Broadcast={true}
                      cardObj={list}
                      key={list._id}
                    />
                  );
                })}
            </>
          ) : (
            <Box mt={3}>
              <Typography align="center" variant="h6">
                No Broadcast Available
              </Typography>
            </Box>
          )}
          <CustomPagination
            page={page}
            count={pageTotalCount}
            handleChange={handleChange}
            JusCont="flex-end"
          />
        </div>
        {props.is_disable_ads !== 1 &&
        props.currentPackage &&
        props.currentPackage.length === 0 ? (
          <>{AdvertisementSection}</>
        ) : (
          <>
            {props.is_disable_ads === 1 ||
            (props.currentPackage &&
              props.currentPackage.length > 0 &&
              props.currentPackage.findIndex((e) => {
                return e.package_name === "Ad free payment";
              }) > -1) ? (
              ""
            ) : (
              <>{AdvertisementSection}</>
            )}
          </>
        )}
      </div>
      {loader && <Loader />}
    </div>
  );
};
const mapStateToProps = ({
  loginDetails: { user, auth, is_disable_ads, currentPackage },
}) => {
  return {
    user,
    auth,
    is_disable_ads,
    currentPackage,
  };
};
export default withRouter(connect(mapStateToProps)(Broadcast));
