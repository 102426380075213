
let initialState = "";

export default (state = initialState, action) => {
    switch (action.type) {
        case "GLOBAL_SEARCH_VAL":
            return {
                ...state,
                globalSearch:action.data
            };
        default:
            return state;
    }
};

export const getGlobalSearchVal = (state) => state.globalSearch.data;