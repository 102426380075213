import { Button, ButtonGroup, IconButton } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import robotImage from "../../../assets/images/AIJudge.png";
import view from "../../../assets/images/Icon-feather-eye.svg";
import Retweet from "../../../assets/images/retweet.svg";
import Thumbs from "../../../assets/images/thumbs.svg";
import loaderImage from "../../../assets/video/bee.gif";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
// import useChat from "../../Common/Chat/useChat";
import logoImage from "../../../assets/images/VurbilBlue.png";
import Adsinjection from "../../Common/AdsInjection";
import Advertisement from "../../Common/Advertisement";
import {
  API_Path,
  getDecryptedHashWithSecret,
  numberFormatter,
} from "../../Common/Const";
import CustomComments from "../../Common/CustomComments";
import CustomDebateHeaderImageContent from "../../Common/CustomDebateHeaderImageContent";
import CustomImage from "../../Common/CustomImage";
import Loader from "../../Common/Loader";
import ReportAbuse from "../../Common/ReportAbuse";
import useDebateSocket from "../../Common/Sockets/useDebateSocket";
import LikeUserCompoent from "../../Common/CommonPostCard/LikeDisLikeUsers";
const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: "#f6f7f8",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  leftSection: {
    width: "100%",
    padding: "24px",
    backgroundColor: "#fff",
    [theme.breakpoints.up("md")]: {
      padding: "36px",
    },
  },
  rightSection: {
    padding: "24px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 400px",
      maxWidth: "400px",
      padding: "36px",
    },
  },
  badgeTeam: {
    padding: "4px 14px",
    fontSize: "12px",
    color: "#fff",
    backgroundColor: "#ffc003",
    marginTop: "10px",
    borderRadius: "6px",
    display: "inline-block",
  },
  smColumnwise: {
    alignItems: "flex-start",
    display: "flex",
    flexWrap: "wrap",
    // [theme.breakpoints.down('sm')]: {
    //     flexFlow: 'column'
    // },
  },
  winnerLosserSection: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
    },
    "& .winner": {
      padding: "4px 14px",
      backgroundColor: "#61cbc9",
      color: "#fff",
      fontSize: "12px",
      display: "inline-block",
      borderRadius: "15px",
      marginBottom: "8px",
    },
    "& h6": {
      fontWeight: "400",
      color: "#758194",
    },
  },
  wlImageContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #acb4c1",
    padding: "10px",
    borderRadius: "42px",
    width: "205px",
    justifyContent: "space-around",
    fontSize: "30px",
    color: "#758194",
    fontWeight: "500",
    "& .winnerImage, & .losserImage": {
      position: "relative",
      "& > div": {
        margin: 0,
      },
    },
    "& .winnerImage": {
      "& > div": {
        border: "2px solid #ffc003",
      },
    },
  },
  flagView: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    fontSize: "12px",
    position: "absolute",
    top: "12px",
    left: "12px",
    right: "12px",
    // zIndex: '9',
    "& .flag, & .finish, & .view": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .flag": {
      width: 26,
      height: 26,
      backgroundColor: "rgb(49, 50, 54, .55)",
      borderRadius: 6,
    },
    "& .finish": {
      backgroundColor: "rgba(255, 255, 255, .3)",
      borderRadius: "13px",
      padding: "4px 12px 4px 6px",
    },
    "& .finish, & .view": {
      "& img": {
        marginRight: "6px",
      },
    },
  },
  completedVideoSection: {
    position: "relative",
    backgroundColor: "#333",
    borderRadius: "12px",
    display: "flex",
    flexFlow: "column",
    justifyContent: "flex-end",
    overflow: "hidden",
    color: "#fff",
    marginTop: "16px",
    [theme.breakpoints.up("lg")]: {
      minHeight: "400px",
    },
    "& .videoPlayer_player": {
      paddingTop: "75%",
      minWidth: "inherit",
      "& .videoPlayer_common_video": {
        [theme.breakpoints.up("lg")]: {
          top: "19.5%",
        },
      },
    },
    "&.novideo:before": {
      content: '"No video to play"',
      position: "absolute",
      top: "0",
      left: "0",
      color: "#fff",
      right: "0",
      bottom: "0",
      display: "flex",
      opacity: ".25",
      fontSize: "24px",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "415px",
    },
    "&.videoGeneration:before": {
      content: '"Please wait. Recording is in-progress..."',
      position: "absolute",
      top: "0",
      left: "0",
      color: "#fff",
      right: "0",
      bottom: "0",
      display: "flex",
      opacity: ".25",
      fontSize: "24px",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "415px",
    },
    "& .wmp-spinner": {
      backgroundImage: "url(" + loaderImage + ")",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      maxWidth: "420px",
      maxHeight: "117px",
      height: "100%",
      width: "100%",
      transform: "translate(-50%, -50%)",
      margin: "0",
      "& .wmp-spinner-container": {
        display: "none",
      },
    },
    "& .fullscreen, & video": {
      width: "100% !important",
      height: "100% !important",
      margin: "0",
    },
    "& .robotTextVideo": {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      bottom: "80px",
      left: "20px",
      right: "20px",
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  tabsNav: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: "1px solid #cdd1d8",
    "& button": {
      padding: "0",
      minWidth: "inherit",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#313236",
      fontWeight: "600",
      fontFamily: "Poppins",
      marginRight: "42px",
    },
  },
  videoLogo: {
    position: "absolute",
    right: "5%",
    bottom: "20%",
    width: "6em",
    opacity: ".75",
  },
  activeStatus: {
    position: "absolute",
    right: "0px",
    top: "-16px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "20px",
    },
  },
  imgContainer: {
    position: "relative",
    "& .activeProp": {
      top: "0px",
      right: "6px",
      "& svg": {
        fontSize: "16px",
      },
    },
  },
  btn:{
    border:'1px solid #acb4c1',
    display:'flex',
    borderRadius:'8px',
    padding:'8px 24px'
  },
}));

const options = ["Newest First", "Top Comments"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "24px 0" }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CompletedDebate = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const location_state = getDecryptedHashWithSecret(
    localStorage.getItem("location.state")
  );
  // const [debateHistoryURL, setDebateHistoryURL] = React.useState("http://techslides.com/demos/sample-videos/small.mp4");
  const [debateHistoryURL, setDebateHistoryURL] = React.useState(null);
  const [debateDtails, setDebateDtails] = React.useState();
  const [audianceAttended, setaudianceAttended] = useState(0);
  // const { upordownVoteClick, upordownvoteObj, likes_Click } = useChat(props);
  const { upordownVoteClick, upordownvoteObj, likes_Click } =
    useDebateSocket(props);
  const [voteObj, setUpordownvoteObj] = useState({});
  const debateObject = location_state.debateObj;
  const accessToken = props.auth.accessToken;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [completeDebateListLoaded, setCompleteDebateListLoaded] =
    useState(false);
  var historyDebateObj = location_state.debateObj;
  // const [addShowIn, setAddShowIn] = useState("");
  // const [addShowurl, setAddShowurl] = useState("");
  const [adsDetails, setadsDetails] = useState(null);
  useEffect(() => {
    attendeesView();
  }, []);
  useEffect(() => {
    getDebateList();
  }, [JSON.stringify(debateObject)]);
  useEffect(() => {
    setUpordownvoteObj(upordownvoteObj);
    getupordownvotes();
  }, [JSON.stringify(upordownvoteObj)]);
  useEffect(() => {
    setLoader(true);
    if (completeDebateListLoaded === true) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [completeDebateListLoaded]);

  const getDebateList = async () => {
    var debateId = historyDebateObj._id;
    axios
      .get(API_Path + `api/debate/get/${debateId}`, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          if (response.data !== null && response.data.result !== null) {
            setDebateDtails(response.data.result);
            // setDebateHistoryURL(response.data.result.video_history_url);
            setDebateHistoryURL(response.data.result);
            setaudianceAttended(response.data.result.audiance_attended.length);
            setCompleteDebateListLoaded(true);
            GetDebateVideoAdList();
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const calltoVote = (type, supportfor) => {
    var inputObject = {
      debateId: debateDtails._id,
      userId: props.user._id,
      voteSuppportFor: supportfor,
      type: type,
      isUpdateWonBy: true,
    };
    upordownVoteClick(inputObject);
  };

  const getupordownvotes = async () => {
    var DebatesDetails = API_Path + "getTotalVotes/" + debateObject._id;
    if (props.auth && props.auth.accessToken) {
      axios
        .get(DebatesDetails, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          if (
            response.data.result !== null &&
            response.data.result.length > 0
          ) {
            setUpordownvoteObj(response.data.result[0]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const onLikesClick = () => {
    likes_Click({
      debateId: debateDtails._id,
      userId: props.user._id,
      likeOrDisLike: "like",
    });
  };

  const onDisLikesClick = () => {
    likes_Click({
      debateId: debateDtails._id,
      userId: props.user._id,
      likeOrDisLike: "dislike",
    });
  };

  const viewOtherDebates = (email) => {
    sessionStorage.setItem("email", email);
    history.push(`/activeDebateList`);
  };

  const attendeesView = () => {
    let attendeesView = API_Path + "api/debate/attendees";
    let inputObj = {
      inputObject: {
        debateId: debateObject._id,
        userId: props.user._id,
        debateType: "recorded",
      },
    };
    axios
      .post(attendeesView, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        getupordownvotes();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetDebateVideoAdList = async () => {
    var DebateVideoAdList = API_Path + "api/debatevideoads/list";
    let inputObj = {
      inputObject: {
        debateId: historyDebateObj._id,
      },
    };

    if (
      props.is_disable_ads === 1 ||
      (props.currentPackage &&
        props.currentPackage.length > 0 &&
        props.currentPackage.findIndex((e) => {
          return e.package_name === "Ad free payment";
        }) > -1)
    ) {
      // alert("if");
      // no need to show ad
    } else {
      await axios
        .post(DebateVideoAdList, inputObj, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          if (
            response !== null &&
            response.data !== null &&
            response.data.result.length > 0
          ) {
            // setAddShowIn(response.data.result[0].ad_show_in)
            // setAddShowurl(response.data.result[0].url)
            setadsDetails(response.data.result[0]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const AdvertisementSection = (
    <div className={classes.rightSection}>
      <Advertisement />
    </div>
  );

  var won_by = "";
  var won_by_user = "";
  if (voteObj && voteObj.won_by !== null && voteObj.won_by === "Proposition") {
    won_by = "Proposition Team";
    won_by_user =
      debateDtails &&
      debateDtails.proposition_users.length > 0 &&
      debateDtails.proposition_users[0].user !== null
        ? debateDtails.proposition_users[0].user.full_name
        : "";
    // won_by_user
  } else if (
    voteObj &&
    voteObj.won_by !== null &&
    voteObj.won_by === "Opposition"
  ) {
    won_by = "Opposition Team";
    won_by_user =
      debateDtails &&
      debateDtails.opposition_users.length > 0 &&
      debateDtails.opposition_users[0].user !== null
        ? debateDtails.opposition_users[0].user.full_name
        : "";
  } else if (voteObj && voteObj.won_by !== null && voteObj.won_by === "Draw") {
    won_by = "Draw";
    won_by_user = "Both Members";
  } else {
    won_by = "";
    won_by_user = "";
  }
  // debateHistoryURL
  return (
    <div className={classes.pageContainer}>
      <div className={classes.leftSection}>
        <CustomDebateHeaderImageContent
          image={
            debateDtails && debateDtails.created_by
              ? debateDtails.created_by.profile_image
              : ""
          }
          date={
            debateDtails &&
            moment(debateDtails.debate_planned_start_datetime).format(
              "DD MMM YYYY dddd"
            )
          }
          debateDetail={debateDtails}
          status={debateDtails && debateDtails.status}
          shareLink={debateObject && debateObject.share_link}
          audianceAttended={
            debateDtails &&
            debateDtails.audiance_attended &&
            debateDtails.audiance_attended.length
          }
          category={false}
        />

        <div
          className={
            classes.completedVideoSection +
            " " +
            `${
              debateHistoryURL === null
                ? "novideo"
                : debateHistoryURL.video_history_url &&
                  debateHistoryURL.thumbnails
                ? ""
                : debateHistoryURL.video_history_url === null
                ? "novideo"
                : "videoGeneration"
            }`
          }
        >
          {/* <ReactWebMediaPlayer
                        title="My own video player"
                        video={debateHistoryURL}
                        thumbnail="https://any-link.com/video-thumbnail.jpg"
                    /> */}

          {debateHistoryURL &&
            debateHistoryURL.video_history_url !== null &&
            debateHistoryURL.thumbnails !== null && (
              <>
                {/* <VideoContainer debateId={debateDtails && debateDtails._id} videoURL={debateHistoryURL} addShowIn={addShowIn} addShowurl={addShowurl} playing={false} /> */}
                <Adsinjection
                  videoUrl={debateHistoryURL}
                  adsList={adsDetails}
                />
                <div className={classes.flagView}>
                  <div className="flag">
                    {/* <img alt="" src={flag} /> */}
                    {debateObject !== null && (
                      <ReportAbuse
                        listItem="imageItem"
                        debateDetail={debateObject}
                      />
                    )}
                  </div>
                  <div className="view">
                    <img alt="" src={view} />
                    {audianceAttended}
                  </div>
                </div>
              </>
            )}

          {debateHistoryURL && !debateHistoryURL.meta_info && (
            <img alt="Logo" src={logoImage} className={classes.videoLogo} />
          )}
          <div className={classes.robotText + " robotTextVideo"}>
            <div
              style={{ width: "45px", height: "45px", margin: "0 10px 0 0" }}
            >
              <img
                alt=""
                src={robotImage}
                style={{ objectFit: "contain", width: "100%", height: "100%" }}
              />
            </div>
            <div>
              <h6 className="font-weight-normal" style={{ margin: "0" }}>
                Judge
              </h6>
              <h5 style={{ margin: "0" }}>AI ROBOT</h5>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", margin: "20px 0" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "18px",
              cursor: "pointer",
            }}
          >
            <img alt="" src={Thumbs} onClick={onLikesClick} />{" "}
            <h5 style={{ margin: "0 0 0 10px" }}>
              <LikeUserCompoent
                count={
                  voteObj !== null && voteObj.likes !== null
                    ? numberFormatter(voteObj.likes)
                    : 0
                }
                active={0}
                debate={true}
                auth={props.auth}
                id={historyDebateObj ? historyDebateObj._id : ""}
              />
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "18px",
              cursor: "pointer",
            }}
          >
            <img
              alt=""
              src={Thumbs}
              onClick={onDisLikesClick}
              style={{ transform: "rotate(180deg)" }}
            />{" "}
            <h5 style={{ margin: "0 0 0 10px" }}>
              <LikeUserCompoent
                count={
                  voteObj !== null && voteObj.dislikes !== null
                    ? numberFormatter(voteObj.dislikes)
                    : 0
                }
                active={1}
                debate={true}
                auth={props.auth}
                id={historyDebateObj ? historyDebateObj._id : ""}
              />
            </h5>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" src={Retweet} />{" "}
            <h5 style={{ margin: "0 0 0 10px" }}>
              {debateDtails && numberFormatter(debateDtails.share_count)}
            </h5>
          </div>
        </div>
        <Box
          className={clsx(classes.flexJCSB, classes.smColumnwise)}
          flexWrap="wrap"
          m={-0.5}
        >
          <Box p={0.5} className={classes.smColumnwise}>
            <div style={{ marginRight: "20px" }}>
              {debateDtails && debateDtails.proposition_users.length > 0 ? (
                <>
                  <h5 style={{ margin: "0" }}>
                    {debateDtails &&
                      debateDtails.proposition_users.length > 0 &&
                      debateDtails.proposition_users[0].user &&
                      debateDtails.proposition_users[0].user.full_name}
                  </h5>
                  {debateDtails &&
                    debateDtails.proposition_users[0].user.user_tagging &&
                    debateDtails.proposition_users[0].user.user_tagging.length >
                      0 ? (
                      <p
                        style={{
                          margin: "0 0 5px 0",
                          color: "#758194",
                          fontWeight: "700",
                          fontSize: "12px",
                        }}
                      >
                        @{""}
                        {debateDtails.proposition_users[0].user.user_tagging}
                      </p>
                    ):(<p style={{ margin: "23px 0"}}></p>)}
                  <ButtonGroup
                    size="large"
                    aria-label="large outlined button group"
                  >
                    <div className={classes.btn}>
                      <IconButton
                        style={{padding:'0'}}
                        onClick={calltoVote.bind(
                          this,
                          "increment",
                          "proposition"
                        )}
                      >
                        <img alt="" src={Thumbs} style={{ height: "18px" }} />
                      </IconButton>{" "}
                      <h6 style={{ margin: "0 0 0 5px" }}>
                        {/* {voteObj && voteObj.proposition_upvote !== null
                          ? numberFormatter(voteObj.proposition_upvote)
                          : 0} */}
                        <LikeUserCompoent
                          count={
                            voteObj && voteObj.proposition_upvote !== null
                              ? numberFormatter(voteObj.proposition_upvote)
                              : 0
                          }
                          active={0}
                          debate={true}
                          vote={true}
                          auth={props.auth}
                          id={historyDebateObj ? historyDebateObj._id : ""}
                        />
                      </h6>
                    </div>
                    {/* <Button onClick={calltoVote.bind(this, 'decrement', 'proposition')}><img alt="" src={Thumbs} style={{ height: '18px', transform: 'rotate(180deg)' }} /> <h6 style={{ margin: '0 0 0 5px' }}>{voteObj && voteObj.proposition_downvote !== null ? voteObj.proposition_downvote : 0}</h6></Button> */}
                  </ButtonGroup>
                </>
              ) : (
                <>
                  <h5 style={{ margin: "0 0 5px 0" }}>No Proposition users</h5>
                  <ButtonGroup
                    size="large"
                    aria-label="large outlined button group"
                    disabled
                  >
                    <Button>
                      <img
                        alt=""
                        src={Thumbs}
                        style={{ height: "18px", opacity: ".5" }}
                      />{" "}
                      <h6 style={{ margin: "0 0 0 5px" }}>
                        {voteObj && voteObj.proposition_upvote !== null
                          ? numberFormatter(voteObj.proposition_upvote)
                          : 0}
                      </h6>
                    </Button>
                    {/* <Button><img alt="" src={Thumbs} style={{ height: '18px', opacity: '.5', transform: 'rotate(180deg)' }} /> <h6 style={{ margin: '0 0 0 5px' }}>{voteObj && voteObj.proposition_downvote !== null ? voteObj.proposition_downvote : 0}</h6></Button> */}
                  </ButtonGroup>
                </>
              )}
              <div>
                <div className={classes.badgeTeam}>Team 1</div>
              </div>
            </div>
            <div>
              {debateDtails && debateDtails.opposition_users.length > 0 ? (
                <>
                  <h5 style={{ margin: "0" }}>
                    {debateDtails &&
                      debateDtails.opposition_users.length > 0 &&
                      debateDtails.opposition_users[0].user &&
                      debateDtails.opposition_users[0].user.full_name}
                  </h5>
                  {debateDtails &&
                    debateDtails.opposition_users[0].user.user_tagging &&
                    debateDtails.opposition_users[0].user.user_tagging.length >
                      0 ? (
                      <p
                        style={{
                          margin: "0 0 5px 0",
                          color: "#758194",
                          fontWeight: "700",
                          fontSize: "12px",
                        }}
                      >
                        @{""}
                        {debateDtails.opposition_users[0].user.user_tagging}
                      </p>
                    ):(<p style={{ margin: "23px 0"}}></p>)}
                  <ButtonGroup
                    size="large"
                    aria-label="large outlined button group"
                  >
                    <div className={classes.btn}>
                      <IconButton
                        style={{padding:'0'}}
                        onClick={calltoVote.bind(this, "increment", "opposition")}
                      >
                        <img alt="" src={Thumbs} style={{ height: "18px" }} />
                      </IconButton>{" "}
                      <h6 style={{ margin: "0 0 0 5px" }}>
                        {/* {voteObj && voteObj.proposition_upvote !== null
                          ? numberFormatter(voteObj.proposition_upvote)
                          : 0} */}
                        <LikeUserCompoent
                          count={
                            voteObj && voteObj.opposition_upvote !== null
                              ? numberFormatter(voteObj.opposition_upvote)
                              : 0
                          }
                          active={1}
                          debate={true}
                          vote={true}
                          auth={props.auth}
                          id={historyDebateObj ? historyDebateObj._id : ""}
                        />
                      </h6>
                    </div>
                    {/* <Button onClick={calltoVote.bind(this, 'decrement', 'opposition')}><img alt="" src={Thumbs} style={{ height: '18px', transform: 'rotate(180deg)' }} /> <h6 style={{ margin: '0 0 0 5px' }}>{voteObj && voteObj.opposition_downvote !== null ? voteObj.opposition_downvote : 0}</h6></Button> */}
                  </ButtonGroup>
                </>
              ) : (
                <>
                  <h5 style={{ margin: "0 0 5px 0" }}>No Opposition users</h5>
                  <ButtonGroup
                    size="large"
                    aria-label="large outlined button group"
                    disabled
                  >
                    <Button>
                      <img
                        alt=""
                        src={Thumbs}
                        style={{ height: "18px", opacity: ".5" }}
                      />{" "}
                      <h6 style={{ margin: "0 0 0 5px" }}>
                        {voteObj && voteObj.opposition_upvote !== null
                          ? voteObj.opposition_upvote
                          : 0}
                      </h6>
                    </Button>
                    {/* <Button><img alt="" src={Thumbs} style={{ height: '18px', opacity: '.5', transform: 'rotate(180deg)' }} /> <h6 style={{ margin: '0 0 0 5px' }}>{voteObj && voteObj.opposition_downvote !== null ? voteObj.opposition_downvote : 0}</h6></Button> */}
                  </ButtonGroup>
                </>
              )}
              <div>
                <div
                  className={classes.badgeTeam}
                  style={{ backgroundColor: "#61cbc9" }}
                >
                  Team 2
                </div>
              </div>
            </div>
          </Box>
          <Box p={0.5} className={classes.winnerLosserSection}>
            <div className={classes.wlImageContainer}>
              <div className="winnerImage">
                <CustomImage
                  userImage={
                    debateDtails &&
                    debateDtails.opposition_users.length > 0 &&
                    debateDtails.opposition_users[0].user &&
                    debateDtails.opposition_users[0].user.profile_image
                  }
                  size="56px"
                />
                {debateDtails &&
                  debateDtails.opposition_users.length > 0 &&
                  debateDtails.opposition_users[0].user &&
                  !!debateDtails.opposition_users[0].user.badge_status && (
                    <span
                      className={classes.activeStatus}
                      title="Verified User/Public Figure"
                    >
                      <VerifiedUserRoundedIcon />
                    </span>
                  )}
              </div>
              VS
              <div className="losserImage">
                <CustomImage
                  userImage={
                    debateDtails &&
                    debateDtails.proposition_users.length > 0 &&
                    debateDtails.proposition_users[0].user &&
                    debateDtails.proposition_users[0].user.profile_image
                  }
                  size="56px"
                />
                {debateDtails &&
                  debateDtails.proposition_users.length > 0 &&
                  debateDtails.proposition_users[0].user &&
                  !!debateDtails.proposition_users[0].user.badge_status && (
                    <span
                      className={classes.activeStatus}
                      title="Verified User/Public Figure"
                    >
                      <VerifiedUserRoundedIcon />
                    </span>
                  )}
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "-15px" }}>
              <div className="winner">Winner</div>
              <h5 className="m-0">{won_by_user}</h5>
              <h6 className="m-0">{won_by}</h6>
            </div>
          </Box>
        </Box>

        <AppBar position="static" className={classes.tabsNav}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="inherit"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Proposition Details" {...a11yProps(0)} />
            <Tab label="Opposition Details" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div
            className={clsx(classes.flexJCSB, classes.smColumnwise)}
            style={{ marginBottom: "24px" }}
          >
            <Box display="flex" width="100%">
              <div className={classes.imgContainer}>
                <CustomImage
                  userImage={
                    debateDtails && debateDtails.proposition_users.length > 0
                      ? debateDtails.proposition_users[0].user &&
                        debateDtails.proposition_users[0].user.profile_image
                      : ""
                  }
                  size="40px"
                />
                {debateDtails &&
                  debateDtails.proposition_users.length > 0 &&
                  debateDtails.proposition_users[0].user &&
                  !!debateDtails.proposition_users[0].user.badge_status && (
                    <span
                      className={classes.activeStatus + " activeProp"}
                      title="Verified User/Public Figure"
                    >
                      <VerifiedUserRoundedIcon />
                    </span>
                  )}
              </div>
              <Box width="100%">
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                  width="100%"
                  mb={2}
                >
                  <Box>
                    <h5 className="m-0">
                      {debateDtails && debateDtails.proposition_users.length > 0
                        ? debateDtails.proposition_users[0].user &&
                          debateDtails.proposition_users[0].user.full_name
                        : "No Proposition users"}
                    </h5>
                    {debateDtails &&
                      debateDtails.proposition_users[0].user.user_tagging &&
                      debateDtails.proposition_users[0].user.user_tagging
                        .length > 0 && (
                        <span
                          style={{
                            margin: "0",
                            color: "#758194",
                            fontWeight: "700",
                          }}
                        >
                          @{""}
                          {debateDtails.proposition_users[0].user.user_tagging}
                        </span>
                      )}
                    <h6
                      className="m-0 font-weight-normal"
                      style={{ color: "#758194" }}
                    >
                      UTC : 16 30 PM
                    </h6>
                  </Box>
                  <Box
                    m={-0.5}
                    display="flex"
                    flexWrap="wrap"
                    justifyContent={{ sm: "flex-end" }}
                  >
                    {debateDtails &&
                    debateDtails.proposition_users.length > 0 &&
                    debateDtails.proposition_users[0].user &&
                    debateDtails.proposition_users[0].user.active_status !==
                      4 ? (
                      <>
                        <Box p={0.5}>
                          <Button
                            variant="outlined"
                            size="small"
                            component={Link}
                            target="_blank"
                            to={`/friendsProfilePage/${
                              debateDtails &&
                              debateDtails.proposition_users &&
                              debateDtails.proposition_users[0].user &&
                              debateDtails.proposition_users[0].user._id
                            }`}
                          >
                            View profile
                          </Button>
                        </Box>
                        <Box p={0.5}>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={viewOtherDebates.bind(
                              this,
                              debateDtails.proposition_users[0].email
                            )}
                            disabled={
                              debateDtails &&
                              debateDtails.proposition_users &&
                              debateDtails.proposition_users[0].user &&
                              debateDtails.proposition_users[0].user.blocked_users.includes(
                                props.user._id
                              )
                            }
                          >
                            View other Debates
                          </Button>
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                {debateDtails &&
                debateDtails.proposition_users.length > 0 &&
                debateDtails.proposition_users[0].user &&
                debateDtails.proposition_users[0].user.tags !== null &&
                debateDtails.proposition_users[0].user.tags.length > 0 ? (
                  <Box
                    m={-0.5}
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <span style={{ fontWeight: "bold" }}>My Interest :</span>
                    {debateDtails.proposition_users[0].user.tags.map(
                      (categoryList, index) => {
                        return (
                          <Box key={index} p={0.5}>
                            <Button key={index} variant="outlined" size="small">
                              {categoryList.name}
                            </Button>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </div>
          <h5 className="m-0">Description</h5>
          <p style={{ fontSize: "12px", color: "#313236" }}>
            {debateObject.proposition_details}
          </p>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div
            className={clsx(classes.flexJCSB, classes.smColumnwise)}
            style={{ marginBottom: "24px" }}
          >
            <Box display="flex" width="100%">
              <div className={classes.imgContainer}>
                <CustomImage
                  userImage={
                    debateDtails && debateDtails.opposition_users.length > 0
                      ? debateDtails.opposition_users[0].user &&
                        debateDtails.opposition_users[0].user.profile_image
                      : ""
                  }
                  size="40px"
                />
                {debateDtails &&
                  debateDtails.opposition_users.length > 0 &&
                  debateDtails.opposition_users[0].user &&
                  !!debateDtails.opposition_users[0].user.badge_status && (
                    <span
                      className={classes.activeStatus + " activeProp"}
                      title="Verified User/Public Figure"
                    >
                      <VerifiedUserRoundedIcon />
                    </span>
                  )}
              </div>
              <Box width="100%">
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                  width="100%"
                  mb={2}
                >
                  <Box>
                    <h5 className="m-0">
                      {debateDtails && debateDtails.opposition_users.length > 0
                        ? debateDtails.opposition_users[0].user &&
                          debateDtails.opposition_users[0].user.full_name
                        : "No Opposition users"}
                    </h5>
                    {debateDtails &&
                      debateDtails.opposition_users[0].user.user_tagging &&
                      debateDtails.opposition_users[0].user.user_tagging
                        .length > 0 && (
                        <span
                          style={{
                            margin: "0",
                            color: "#758194",
                            fontWeight: "700",
                          }}
                        >
                          @ {debateDtails.opposition_users[0].user.user_tagging}
                        </span>
                      )}
                    <h6
                      className="m-0 font-weight-normal"
                      style={{ color: "#758194" }}
                    >
                      UTC : 16 30 PM
                    </h6>
                  </Box>
                  <Box
                    m={-0.5}
                    display="flex"
                    flexWrap="wrap"
                    justifyContent={{ sm: "flex-end" }}
                  >
                    {debateDtails &&
                    debateDtails.opposition_users.length > 0 &&
                    debateDtails.opposition_users[0].user &&
                    debateDtails.opposition_users[0].user.active_status !==
                      4 ? (
                      <>
                        <Box p={0.5}>
                          <Button
                            variant="outlined"
                            size="small"
                            component={Link}
                            target="_blank"
                            to={`/friendsProfilePage/${
                              debateDtails &&
                              debateDtails.opposition_users &&
                              debateDtails.opposition_users[0].user &&
                              debateDtails.opposition_users[0].user._id
                            }`}
                          >
                            View profile
                          </Button>
                        </Box>
                        <Box p={0.5}>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={viewOtherDebates.bind(
                              this,
                              debateDtails.opposition_users[0].email
                            )}
                            disabled={
                              debateDtails &&
                              debateDtails.opposition_users &&
                              debateDtails.opposition_users[0].user &&
                              debateDtails.opposition_users[0].user.blocked_users.includes(
                                props.user._id
                              )
                            }
                          >
                            View other Debates
                          </Button>
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>

                {debateDtails &&
                debateDtails.opposition_users.length > 0 &&
                debateDtails.opposition_users[0].user &&
                debateDtails.opposition_users[0].user.tags !== null &&
                debateDtails.opposition_users[0].user.tags.length > 0 ? (
                  <Box
                    m={-0.5}
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <span style={{ fontWeight: "bold" }}>My Interest :</span>
                    {debateDtails.opposition_users[0].user.tags.map(
                      (categoryList, index) => {
                        return (
                          <Box key={index} p={0.5}>
                            <Button key={index} variant="outlined" size="small">
                              {categoryList.name}
                            </Button>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </div>
          <h5 className="m-0">Description</h5>
          <p style={{ fontSize: "12px", color: "#313236" }}>
            {debateObject.opposition_details}
          </p>
        </TabPanel>
        <CustomComments
          accessToken={accessToken}
          debateObject={debateObject}
          options={options}
        />
      </div>
      {props.is_disable_ads !== 1 &&
      props.currentPackage &&
      props.currentPackage.length === 0 ? (
        <>{AdvertisementSection}</>
      ) : (
        <>
          {props.is_disable_ads === 1 ||
          (props.currentPackage &&
            props.currentPackage.length > 0 &&
            props.currentPackage.findIndex((e) => {
              return e.package_name === "Ad free payment";
            }) > -1) ? (
            ""
          ) : (
            <>{AdvertisementSection}</>
          )}
        </>
      )}
      {loader && <Loader />}
    </div>
  );
};

// export default CompletedDebate
const mapStateToProps = ({
  loginDetails: { user, auth, is_disable_ads, currentPackage },
}) => {
  return {
    user,
    auth,
    is_disable_ads,
    currentPackage,
  };
};
export default withRouter(connect(mapStateToProps)(CompletedDebate));
