import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import axios from "axios";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import Advertisement from "../../Common/Advertisement/index";
import ClassificationList from "../../Common/ClassificationList";
import CustomPagination from "../../Common/CommonPagination";
import { API_Path } from "../../Common/Const/index";
// import MyDebateCard from './MyDebateCard'
import CustomDebateCard from "../../Common/CustomDebateCard";
import CustomDropdown from "../../Common/CustomDropdown";
import CustomSearch from "../../Common/CustomSearch";
import FeaturedDebaters from "../../Common/FeaturedDebaters";
import Loader from "../../Common/Loader";
import CreateDebate from "./CreateDebate";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: "#f6f7f8",
    // padding: '30px',
    padding: "18px 0 40px 0",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      // padding: '30px 40px',
      padding: "18px 18px 40px 18px",
    },
  },

  leftSection: {
    padding: "0 18px 18px 18px",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 260px",
      maxWidth: "260px",
    },
    "& + div": {
      [theme.breakpoints.up("lg")]: {
        width: "calc(100% - 260px)",
      },
    },
  },
  contentSection: {
    width: "100%",
    padding: "0 18px",
    // [theme.breakpoints.up('md')]: {
    //     width: 'calc(100% - 364px)',
    // }
  },
  rightSection: {
    padding: "0 18px 18px 18px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 364px",
      maxWidth: "364px",
    },
  },

  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column",
    },
  },
  // paperWhiteParent: {
  //     boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.13)',
  //     // padding: '20px 20px 30px 20px',
  //     borderRadius: '12px',
  //     backgroundColor: '#fff',
  //     margin: '20px 0',
  // },
  // seperator: {
  //     borderBottom: 'solid 1px #cdd1d8;',
  //     margin: '30px 0 25px 0',
  // },
  searchWhiteBg: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
  // completedVideoSection: {
  //     // height: '255px',
  //     position: 'relative',
  //     overflow: 'hidden',
  //     color: '#fff',
  //     "& .fullscreen, & video": {
  //         width: '100% !important',
  //         height: '100% !important',
  //         margin: '0',
  //     },
  // },

  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
  },
  contentRightSection: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& > div": {
      [theme.breakpoints.up("md")]: {
        width: "calc(100% - 364px)",
      },
      "&:only-child": {
        [theme.breakpoints.up("md")]: {
          width: "100%",
          "&.leftCreateSection": {
            width: "calc(100% + 36px)",
            margin: "-18px -18px -40px -18px",
          },
        },
      },
    },
  },
}));

const options = ["", "Relevant", "Popular", "Most Recent", "All"];

const MyDebates = (props) => {
  // const location_state=getDecryptedHashWithSecret(localStorage.getItem("location.state"));
  const classes = useStyles();
  // const [state, setState] = useState({});
  const [debateList, setDebateList] = useState([]);
  const [debateTotalCount, setDebateTotalCount] = useState(0);
  const [createDebate, setCreateDebate] = useState(false);
  const [classificationDetails, setclassificationDetails] = React.useState([]);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const myRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [searchVal, setSearchVal] = React.useState("");
  const catId = useRef("");
  const myRefAdvertisment = useRef("");
  const type = useRef("Relevant");
  const [loader, setLoader] = useState(false);
  const [classificationLoaded, setClassificationLoaded] = useState(false);
  const [debateListLoaded, setDebateListLoaded] = useState(false);
  const [page, setPage] = React.useState(1);
  const [BtnName, setBtnName] = React.useState("Create");
  const [debateObject, setDebateObject] = React.useState(null);
  const pageTotalCount = Math.ceil(debateTotalCount / 10);
  const source = axios.CancelToken.source();

  const handlePaginationChange = (event, value) => {
    setPage(value);
    getDebateDetails(value);
  };

  const handleDebateDetails = (categoryId) => {
    if (categoryId === "All") {
      catId.current = null;
      getDebateDetails(null);
      if (myRefAdvertisment.current) {
        myRefAdvertisment.current.getAdvertisementList("All");
      }
    } else {
      catId.current = categoryId;
      getDebateDetails(catId.current);
      if (myRefAdvertisment.current) {
        myRefAdvertisment.current.getAdvertisementList(catId.current);
      }
    }
    setPage(1);
  };

  const getDebateDetails = async (currentPageVal) => {
    var DebatesDetails = API_Path + "api/debate/list";
    if (props.auth && props.auth.accessToken) {
      let inputObj = {
        inputObject: {
          type: "myDebates",
          userId: props.user._id,
          // categoryId: catId.current,
          searchText: searchVal,
          sortBy: type.current,
          pagination: {
            currentpage: currentPageVal ? currentPageVal : 1,
            recordsperpage: 10,
          },
          allBlockedUsers:[]
        },
      };
      if (catId.current) {
        inputObj.inputObject["categoryId"] = catId.current;
      }
      await axios
        .post(DebatesDetails, inputObj, {
          cancelToken: source.token,
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          setDebateList(response.data.result.data);
          setDebateTotalCount(response.data.result.totalCount);
          setDebateListLoaded(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleMenuItemClick = (event, index, val) => {
    setSelectedIndex(index);
    type.current = val;
    getDebateDetails();
  };
  const handleSearch = (event) => {
    // getDebateDetails(catId.current);
    setSearchVal(event.target.value);
    setPage(1);
  };

  const handleCreateDebate = () => {
    setBtnName("Create");
    setDebateObject(null);
    setCreateDebate(true);
  };

  const handleUpdateDebate = (debateObject) => {
    // alert("test");
    setDebateObject(debateObject);
    // setDebateObject(null);
    setBtnName("Update");
    setCreateDebate(true);
  };

  useEffect(() => {
    // clearStateLocation();
    return () => {
      source.cancel();
    };
  },[]);

  useEffect(() => {
    setLoader(true);
    if (classificationLoaded === true && debateListLoaded === true) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [classificationLoaded, debateListLoaded]);

  useEffect(() => {
    getDebateDetails("");
    if (props.location.state !== null) {
      const {
        debateCreate = false,
        debateEdit,
        debateObj,
      } = props.location.state.mydebateObj || props.mydebateObj;
      if (debateEdit) {
        setDebateObject(debateObj);
        setBtnName("Update");
        setCreateDebate(true);
      } else if (debateCreate) {
        handleCreateDebate();
      }
    }
  }, [props.location.state, searchVal]);

  const handleClassificationList = (list) => {
    setclassificationDetails(list);
  };

  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };
  const classifyLoaded = (val) => {
    setClassificationLoaded(val);
  };
  const handleBack = () => {
    setCreateDebate(false);
    myRef.current.scrollIntoView();
  };
  const handleDeleteDebate = () => {
    getDebateDetails("");
  };
  const AdvertisementSection = (
    <div className={classes.rightSection}>
      <Advertisement innerRef={myRefAdvertisment} />
    </div>
  );
  return (
    <div className={classes.pageContainer} ref={myRef}>
      {createDebate === true ? (
        <CreateDebate
          BtnName={BtnName}
          handleBack={handleBack}
          classificationDetails={classificationDetails}
          setLoader={setLoader}
          setSnackbarState={setSnackbarState}
          toggle={setCreateDebate}
          reloadDebateDetails={getDebateDetails}
          myRef={myRef}
          debateObject={debateObject}
          closeModal={props && props.closeModal}
        />
      ) : (
        <>
          <div className={classes.leftSection}>
            <ClassificationList
              handleClassificationList={handleClassificationList}
              handleDebateDetails={handleDebateDetails}
              classifyLoaded={classifyLoaded}
            />
            <FeaturedDebaters />
          </div>
          <div className={classes.contentRightSection}>
            <div className={classes.contentSection}>
              <div style={{ textAlign: "right", marginBottom: "20px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={clsx(classes.circleBtn)}
                  onClick={handleCreateDebate}
                >
                  {"Create Debate"}
                </Button>
              </div>
              <div className={classes.flexJCSB}>
                <CustomSearch
                  className={classes.searchWhiteBg}
                  handleSearch={handleSearch}
                />
                <Box py={{ xs: 2, sm: 0 }} p={0.5}>
                  <CustomDropdown
                    options={options}
                    selectedIndex={selectedIndex}
                    handleMenuItemClick={handleMenuItemClick}
                  />
                </Box>
              </div>
              {/* <Box mt={2}>
                                <CustomPagination page={page} count={pageTotalCount} handleChange={handlePaginationChange} JusCont="flex-end" />
                            </Box> */}
              {debateList && debateList.length > 0 ? (
                debateList.map((list, index) => {
                  return (
                    <CustomDebateCard
                      key={index}
                      debateDetail={list}
                      userId={props && props.user._id}
                      getDebateDetails={getDebateDetails}
                      screen="MyDebates"
                      editDebate={handleUpdateDebate.bind(this, list)}
                      handleDeleteDebate={handleDeleteDebate}
                    />
                    // <MyDebateCard
                    // index={index}
                    // classes={classes}
                    // list={list}
                    // liveBtn={false}
                    // screen="MyDebates"
                    // getDebateDetails={getDebateDetails}
                    // listData={{ ...listData }}
                    // />
                    // <Paper key={index} className={classes.paperWhiteParent}>
                    //     <Box p={2.25}>
                    //         <CustomDebateHeader MyDebates={true} month={list.debate_planned_start_datetime} date={list.debate_planned_start_datetime} heading={list.title} debateType={list.debate_type}
                    //             liveBtn={false} statusAC={true} debateDetail={list}
                    //             screen="MyDebates" getDebateDetails={getDebateDetails} />
                    //     </Box>
                    //     {list && list.status && list.status === 2 ?
                    //         <div className={classes.completedVideoSection}>
                    //             <ReactWebMediaPlayer
                    //                 title=""
                    //                 video={list.video_history_url}
                    //             // thumbnail="https://any-link.com/video-thumbnail.jpg"
                    //             />
                    //         </div>
                    //         :
                    //         <div className={classes.seperator}></div>
                    //     }
                    //     <Box p={2.25}>
                    //         <CustomDebateContent listData={{ ...listData }} debateDetail={list} />
                    //     </Box>
                    // </Paper>
                  );
                })
              ) : (
                <Box mt={3}>
                  <Typography align="center" variant="h6">
                    No Debates Available
                  </Typography>
                </Box>
              )}
              <CustomPagination
                page={page}
                count={pageTotalCount}
                handleChange={handlePaginationChange}
                JusCont="flex-end"
              />
            </div>
            {props.is_disable_ads !== 1 &&
            props.currentPackage &&
            props.currentPackage.length === 0 ? (
              <>{AdvertisementSection}</>
            ) : (
              <>
                {props.is_disable_ads === 1 ||
                (props.currentPackage &&
                  props.currentPackage.length > 0 &&
                  props.currentPackage.findIndex((e) => {
                    return e.package_name === "Ad free payment";
                  }) > -1) ? (
                  ""
                ) : (
                  <>{AdvertisementSection}</>
                )}
              </>
            )}
          </div>
        </>
      )}
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
      {loader && <Loader />}
    </div>
  );
};

// export default ActiveDebateList
const mapStateToProps = ({
  loginDetails: { user, auth, is_disable_ads, currentPackage },
}) => {
  return {
    user,
    auth,
    is_disable_ads,
    currentPackage,
  };
};
export default withRouter(connect(mapStateToProps)(MyDebates));
