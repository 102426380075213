import { Box, Typography } from "@material-ui/core";
import axios from "axios";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { connect } from "react-redux";
import { API_Path } from "../../Common/Const";
import AddList from "./AddList";

const Advertisement = forwardRef((props, ref) => {
  const [advertisementData, setAdvertisementData] = useState([]);
  const [classificationKeyword, setClassificationKeyword] = useState("");
  const getAdvertisementList = async (val) => {
    var AdvertisementDetails = API_Path + "api/ads/list";
    let inputObj = {
      email: props.user.email,
      classification: val ? val : val === "All" ? "All" : null,
    };
    await axios
      .post(AdvertisementDetails, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (
          response !== null &&
          response.data !== null &&
          response.data.result !== null
        ) {
          setAdvertisementData(response.data.result);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useImperativeHandle(props.innerRef, () => ({
    getAdvertisementList: (val) => {
      setClassificationKeyword(val);
      getAdvertisementList(val);
    },
    // getcategoryList: (val) => {
    //     getcategoryList(val);
    // }
  }));

  // const getcategoryList = async (val) => {
  //     var categoryDetails = API_Path + "api/ads/filter";
  //     let inputObj = ''
  //     if (val === undefined) {
  //         inputObj = {
  //         }
  //     } else {
  //         inputObj = {
  //             categoryId: val,
  //         }
  //     }
  //     await axios
  //         .post(categoryDetails, inputObj, {
  //             headers: {
  //     'Authorization': "Basic " + props.auth.accessToken
  // }
  //         })
  //         .then((response) => {
  //             setAdvertisementData(response.data.result)
  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });
  // }

  useEffect(() => {
    getAdvertisementList();
  }, []);

  return (
    <div ref={ref}>
      <h6
        style={{
          margin: "10px 0 30px 0",
          color: "#758194",
          fontWeight: "normal",
        }}
      >
        Advertisement
      </h6>
      {advertisementData && advertisementData.length > 0 ? (
        <>
          {advertisementData.map((advData, index) => {
            return (
              <AddList
                key={index}
                advData={advData}
                url={advData.url}
                // link={advData.share_ad_url}
                link={advData.link}
                classificationKeyword={classificationKeyword}
              />
            );
          })}
        </>
      ) : (
        <Box mt={3}>
          <Typography align="center" variant="h6">
            No Ads Available
          </Typography>
        </Box>
      )}
    </div>
  );
});

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default connect(mapStateToProps)(Advertisement);
