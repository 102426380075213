import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import CommentImg from "../../../assets/images/comment.svg";
import Retweet from "../../../assets/images/retweet.svg";
import Thumbs from "../../../assets/images/thumbs.svg";
import InstantDebateJoinButton from "../../Users/ActiveDebate/InstantDebateJoinButton";
import CustomImage from "../CustomImage";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import { numberFormatter } from "../Const";
const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    border: "solid 1px #cdd1d8",
    borderRadius: "12px",
    padding: "20px 24px",
    marginTop: "12px",
    display: "flex",
    flexFlow: "column",
    height: "100%",
  },
  viewProfile: {
    fontSize: "12px",
    fontWeight: "400",
    padding: "0",
    textTransform: "capitalize",
  },
  activeStatus: {
    position: "absolute",
    right: "8px",
    top: "0px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "20px",
    },
  },
}));
const CustomDebateContent = (props) => {
  const classes = useStyles();
  //  let time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  //proposition
  let isproposition_join_button_disabled = false;
  //opposition
  let isopposition_join_button_disabled = false;

  // Proposition condition
  if (
    props.debateDetail !== null &&
    props.debateDetail.proposition_type === "Anyone" &&
    (props.debateDetail.proposition_users === null ||
      props.debateDetail.proposition_users.length === 0)
  ) {
    if (props.debateDetail.created_by._id === props.user._id) {
      if (
        props.debateDetail !== null &&
        props.debateDetail.opposition_users !== null &&
        props.debateDetail.opposition_users.length > 0 &&
        props.debateDetail.is_instant_debate === 1 &&
        props.debateDetail.opposition_users[0].user._id === props.user._id
      ) {
        isproposition_join_button_disabled = true;
      }
    } else if (
      props.debateDetail !== null &&
      props.debateDetail.opposition_users !== null &&
      props.debateDetail.opposition_users.length > 0 &&
      props.debateDetail.opposition_users[0].user._id === props.user._id
    ) {
      isproposition_join_button_disabled = true;
    }
  }

  // Opposition condition
  if (
    props.debateDetail !== null &&
    props.debateDetail.opposition_type === "Anyone" &&
    (props.debateDetail.opposition_users === null ||
      props.debateDetail.opposition_users.length === 0)
  ) {
    if (props.debateDetail.created_by._id === props.user._id) {
      if (
        props.debateDetail !== null &&
        props.debateDetail.proposition_users !== null &&
        props.debateDetail.proposition_users.length > 0 &&
        props.debateDetail.is_instant_debate === 1 &&
        props.debateDetail.proposition_users[0].user._id === props.user._id
      ) {
        isopposition_join_button_disabled = true;
      }
    } else if (
      props.debateDetail !== null &&
      props.debateDetail.proposition_users !== null &&
      props.debateDetail.proposition_users.length > 0 &&
      props.debateDetail.proposition_users[0].user._id === props.user._id
    ) {
      isopposition_join_button_disabled = true;
    }
  }
  return (
    <>
      <div style={{ display: "flex", marginBottom: "12px" }}>
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "18px" }}
        >
          <img alt="" src={Thumbs} />{" "}
          <h5 style={{ margin: "0 0 0 10px" }}>
            {props.debateDetail &&
              numberFormatter(props.debateDetail.likes_count)}
          </h5>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "18px" }}
        >
          <img alt="" src={CommentImg} />{" "}
          <h5 style={{ margin: "0 0 0 10px" }}>
            {props.debateDetail &&
              numberFormatter(props.debateDetail.comments_count)}
          </h5>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img alt="" src={Retweet} />{" "}
          <h5 style={{ margin: "0 0 0 10px" }}>
            {props.debateDetail &&
              numberFormatter(props.debateDetail.share_count)}
          </h5>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={6}
          container
          height="100%"
          style={{ flexFlow: "column" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <h6 style={{ margin: "0", fontWeight: "normal", color: "#758194" }}>
              {"Proposition Team"}
            </h6>
          </div>
          <Paper className={classes.paper}>
            {props.debateDetail &&
            props.debateDetail.is_instant_debate === 1 &&
            props.debateDetail.status === 0 &&
            props.debateDetail.proposition_users.length === 0 ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <InstantDebateJoinButton
                  debateDetail={props.debateDetail}
                  userType="proposition"
                  Join="Join"
                  disabled={isproposition_join_button_disabled}
                  refreshDebateCardObject={props.refreshDebateCardObject}
                />
              </Box>
            ) : (
              <>
                {props.debateDetail.proposition_users.map((team, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ position: "relative" }}>
                          <CustomImage
                            userImage={
                              team && team.user && team.user.profile_image
                            }
                            size="46px"
                          />
                          {team && team.user && !!team.user.badge_status && (
                            <span
                              className={classes.activeStatus}
                              title="Verified User/Public Figure"
                            >
                              <VerifiedUserRoundedIcon />
                            </span>
                          )}
                        </div>
                        <div>
                          <h5 style={{ margin: "0",display: "flex",
                              flexDirection: "column", }}>
                            {team &&
                              team.user &&
                              team.user.full_name &&
                              team.user.full_name}
                          {team &&
                            team.user &&
                            team.user.user_tagging &&
                            team.user.user_tagging.length > 0 && (
                              <span
                                style={{
                                  margin: "0",
                                  color: "#758194",
                                  fontWeight: "700",
                                  fontSize:'12px'
                                }}
                              >
                                @{""}
                                {team.user.user_tagging}
                              </span>
                            )}
                          </h5>
                          {/* <h6 style={{ margin: '0', fontWeight: 'normal', color: '#758194' }}>{"UTC " + time}</h6> */}
                          {team &&
                            team.user &&
                            team.user.active_status !== 4 && (
                              <Button
                                size="small"
                                component={Link}
                                to={`/friendsProfilePage/${
                                  team && team.user && team.user._id
                                }`}
                                target="_blank"
                                className={classes.viewProfile}
                              >
                                View profile
                              </Button>
                            )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          height="100%"
          style={{ flexFlow: "column" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <h6 style={{ margin: "0", fontWeight: "normal", color: "#758194" }}>
              {"Opposition Team"}
            </h6>
          </div>
          <Paper className={classes.paper}>
            {props.debateDetail &&
            props.debateDetail.is_instant_debate === 1 &&
            props.debateDetail.status === 0 &&
            props.debateDetail.opposition_users.length === 0 ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <InstantDebateJoinButton
                  debateDetail={props.debateDetail}
                  userType="opposition"
                  Join="Join"
                  disabled={isopposition_join_button_disabled}
                  refreshDebateCardObject={props.refreshDebateCardObject}
                />
              </Box>
            ) : (
              <>
                {props.debateDetail.opposition_users.map((team, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ position: "relative" }}>
                          <CustomImage
                            userImage={
                              team && team.user && team.user.profile_image
                            }
                            size="46px"
                          />
                          {team && team.user && !!team.user.badge_status && (
                            <span
                              className={classes.activeStatus}
                              title="Verified User/Public Figure"
                            >
                              <VerifiedUserRoundedIcon />
                            </span>
                          )}
                        </div>
                        <div>
                          <h5
                            style={{
                              margin: "0",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {team &&
                              team.user &&
                              team.user.full_name &&
                              team.user.full_name}
                            {team &&
                              team.user &&
                              team.user.user_tagging &&
                              team.user.user_tagging.length > 0 && (
                                <span
                                  style={{
                                    margin: "0",
                                    color: "#758194",
                                    fontWeight: "700",
                                    fontSize: "12px",
                                  }}
                                >
                                  @{""}
                                  {team.user.user_tagging}
                                </span>
                              )}
                          </h5>
                          {/* <h6 style={{ margin: '0', fontWeight: 'normal', color: '#758194' }}>{"UTC " + time}</h6> */}
                          {team &&
                            team.user &&
                            team.user.active_status !== 4 && (
                              <Button
                                size="small"
                                component={Link}
                                to={`/friendsProfilePage/${
                                  team && team.user && team.user._id
                                }`}
                                target="_blank"
                                className={classes.viewProfile}
                              >
                                View profile
                              </Button>
                            )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user,
    auth,
  };
};
export default withRouter(connect(mapStateToProps)(CustomDebateContent));
