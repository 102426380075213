import { Avatar, CircularProgress, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState,useRef,useCallback } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import UserMenu from '../../../assets/images/menu/userMenu.svg';
import SnackBar from "../../../components/SnackBar";
import Advertisement from '../../Common/Advertisement';
import { API_Path } from '../../Common/Const/index';

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        backgroundColor: '#f6f7f8',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    leftSection: {
        width: '100%',
        padding: '36px',
        backgroundColor: '#fff',
    },
    rightSection: {
        padding: '24px',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexFlow: 'column',
            flex: '0 0 400px',
            maxWidth: '400px',
            padding: '36px',
        },
    },
    headingWithBorderBottom: {
        margin: '0 0 20px 0',
        paddingBottom: '14px',
        borderBottom: 'solid 1px #cdd1d8',
    },
    centerDiv: {
        maxWidth: 425,
        margin: 'auto',
    },
    notificationList: {
        display: 'flex',
        flexDirection: 'column',
        // flexDirection: 'column-reverse',
        "& li": {
            cursor: 'pointer',
            borderBottom: 'solid 1px rgba(196, 201, 209, 0.3)',
            "&:hover": {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            }
        },
        "& h5": {
            fontSize: '14px',
            fontWeight: '500',
            color: '#313236',
        },
        "& h6": {
            fontSize: '12px',
            color: '#313236',
        },
        "& p": {
            fontSize: '11px',
            color: '#758194',
            margin: 0,
        }
    }
}))
const limit = 15;
let totalCount = 0;
const Notification = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [snackbarState, setSnackbarState] = useState({ open: false, })
    const [userNotificationQueueList, setUserNotificationQueueList] = useState([]);
    const [loading,setLoading]=useState(true);
    const [skip, setSkip] = useState(0);
    useEffect(() => {
        getUserNotificationQueueList(0)
    }, [])
    const observer = useRef()
    const lastNotificationElement = useCallback(node => {
      if (loading) return
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          if (totalCount > userNotificationQueueList.length) {
            setLoading(true)
            getUserNotificationQueueList(skip)
          }
        }
      })
      if (node) observer.current.observe(node)
    })
    const onClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarState({ open: false });
    };
    const getUserNotificationQueueList = async (skipCount) => {
        if (props.user !== null && props.user !== undefined && props.user._id !== null) {
            var notificationqueueAPI = API_Path + "api/auth/getNotificationQueueList";
            var inputPar = {
                inputObject: {
                    userId: props.user._id,
                    limit:limit,
                    skip:skipCount
                }
            };
            axios
                .post(notificationqueueAPI, inputPar, {
                    headers: {
                        // 'Authorization': Make_Base_Auth()
                        'Authorization': "Basic " + props.auth.accessToken
                    }
                })
                .then((response) => {
                    if (response !== null && response.data !== null && response.data.result !== null) {
                        (skipCount === 0) ? setUserNotificationQueueList(response.data.result) : setUserNotificationQueueList(p => [...p, ...response.data.result]);
                        totalCount = response.data.totalCount;
                        setSkip(skipCount + limit);
                        // setUserNotificationQueueList(response.data.result);
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                    console.error(error);
                });
        }

    }
    const handleNotifyMenuClose = (notificationQueueObj) => {
        updateNotificationReadStatus(notificationQueueObj);
    };
    const updateNotificationReadStatus = async (notificationQueueObj) => {
        if (notificationQueueObj !== null) {
            var updatereadstatusAPI = API_Path + "api/auth/updateNotificationReadStatus";
            var inputPar = {
                inputObject: {
                    notificationqueueId: notificationQueueObj._id
                }
            };
            axios
                .post(updatereadstatusAPI, inputPar, {
                    headers: {
                        // 'Authorization': Make_Base_Auth()
                        'Authorization': "Basic " + props.auth.accessToken
                    }
                })
                .then((response) => {
                    if (notificationQueueObj.click_action) {
                        if (notificationQueueObj.click_action.includes('chatView')) {
                          let URLSplit=notificationQueueObj.click_action.split('=')
                          history.replace({
                            search: '?chatId='+URLSplit[URLSplit.length-1],
                          })
                        } else {
                          window.location.href = notificationQueueObj.click_action;
                        }
                      }
                    // if (notificationQueueObj.click_action !== null) {
                    //     window.location.href = notificationQueueObj.click_action;
                    //     // window.open(notificationQueueObj.click_action, "_blank")
                    // }
                    // setAnchorNotify(null);
                })
                .catch((error) => {
                    console.error(error);
                });

        }

    }
    const AdvertisementSection = (
        <div className={classes.rightSection}>
            <Advertisement />
        </div>
    )
    return (
        <div className={classes.pageContainer}>
            <div className={classes.leftSection}>
                <h2 className={classes.headingWithBorderBottom}>Notifications</h2>
                <List className={classes.notificationList}>
                    {userNotificationQueueList && userNotificationQueueList.length > 0
                        && userNotificationQueueList.map((list, index) => {
                            return (
                                <div key={index} ref={(userNotificationQueueList.length - 1 )=== index ?lastNotificationElement : null }
                                >
                                <ListItem  onClick={handleNotifyMenuClose.bind(this, list)}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <img alt={list.title} src={list.icon ? list.icon : UserMenu} width='100%'/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    {/* <h5>{list.title}</h5> */}
                                    <ListItemText
                                        primary={<React.Fragment>
                                            <Typography component="h5" color="textPrimary">
                                                {list.title}
                                            </Typography>
                                            <Typography component="h6" color="textPrimary">
                                                {list.body}
                                            </Typography>
                                        </React.Fragment>
                                        } secondary={list.scheduledTime && moment(list.scheduledTime).format("MMM DD,YYYY [at] h:mm A")} />
                                </ListItem>
                                </div>
                            )
                        })}
                </List>
            {loading && <div style={{ display: "flex", justifyContent: "center" }}><CircularProgress /></div>}
            </div>
            {props.is_disable_ads !== 1 && props.currentPackage && props.currentPackage.length === 0 ?
                    <>{AdvertisementSection}</>
                    :
                    <>
                        {props.is_disable_ads === 1 || (props.currentPackage && props.currentPackage.length > 0 &&
                            props.currentPackage.findIndex((e) => { return e.package_name === "Ad free payment" }) > -1) ?
                            ""
                            :
                            <>{AdvertisementSection}</>
                        }
                    </>
                }
            <SnackBar open={snackbarState.open} message={snackbarState.message} severity={snackbarState.severity} onClose={onClose} />
        </div>
    )
}

const mapStateToProps = ({ loginDetails: { user, auth, is_disable_ads, currentPackage } }) => {
    return {
        user, auth, is_disable_ads, currentPackage
    };
};
export default withRouter(connect(mapStateToProps)(Notification));