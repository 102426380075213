import { Button, FormControl, FormHelperText, makeStyles, TextField } from '@material-ui/core';
import axios from "axios";
import clsx from "clsx";
import React, { useState } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SnackBar from '../../../components/SnackBar';
import ConfirmationModal from '../../Common/ConfirmationModal';
import { API_Path, redirectTo } from "../../Common/Const";
const useStyles = makeStyles((theme) => ({
    textField: {
        marginTop: theme.spacing(2),
        maxHeight: theme.spacing(5),
        minWidth: 'inherit',
        "& .MuiInputBase-formControl": {
            maxHeight: theme.spacing(5),
            minWidth: 'inherit',
            "& .MuiOutlinedInput-input": {
                height: theme.spacing(5),
                padding: '10.5px 14px',
                fontSize: '12px',
                color: '#758194',
                boxSizing: 'border-box'
            }
        },
        "& .MuiInputLabel-outlined": {
            fontSize: '14px',
            color: '#313236',
            fontWeight: '500',
            textTransform: 'capitalize',
            transform: 'translate(0px, -22px) scale(1)'
        },
        "& fieldset": {
            top: '0',
        },
        "& legend": {
            display: 'none',
        },
        "& .MuiFormHelperText-contained": {
            margin: '0',
            position: 'absolute',
            bottom: '-17px',
        },
        "&+.Mui-error": {
            textTransform: 'capitalize',
        }
    },
    textFieldArea: {
        maxHeight: 'inherit',
        "& .MuiInputBase-formControl": {
            maxHeight: 'inherit',
            height: 110,
            display: 'block',
        },
    },
    circleBtn: {
        borderRadius: 30,
        fontWeight: 'bold',
        "&.disabled": {
            pointerEvents: 'none',
            cursorPointer: 'none',
            opacity: '.5'
        }
    },
}))
const InstantDebateJoinButton = (props) => {
    const classes = useStyles();
    const [debateDescription, setDebateDescription] = useState('');
    const [debateDescriptionDialog, setDebateDescriptionDialog] = useState(false);
    const [helperTextDebateDescription, setHelperTextDebateDescription] = useState('');
    const [DDDTitle, setDDDTitle] = useState('');
    // const [setJoinMove] = useState('');
    const [snackbarState, setSnackbarState] = useState({ open: false, })
    const [redirectToActiveDebate, setRedirectToActiveDebate] = React.useState(false);
    const handleDDDialogClose = () => {
        setDebateDescriptionDialog(false);
    }
    const handleDebatePopup = (action, actionFiels) => {
        setHelperTextDebateDescription('');
        setDebateDescription('')
        // if(actionFiels === "Move") {
        //     if(action === 'proposition') {
        //         setDDDTitle('Opposition')
        //     } else {
        //         setDDDTitle('Proposition')
        //     }
        // } else {
        //     setDDDTitle(action)
        // }
        setDDDTitle(action)
        // setJoinMove(actionFiels)
        setDebateDescriptionDialog(true)
    }
    const handleDebateDescription = (event) => {
        setDebateDescription(event.target.value)
    }
    const handleCheckDDD = () => {
        setHelperTextDebateDescription('');
        if (debateDescription === '') {
            // if(joinMove === "Move") {
            //     if(DDDTitle === 'proposition') {
            //         setHelperTextDebateDescription('Opposition Details is Required');
            //     } else {
            //         setHelperTextDebateDescription('Proposition Details is Required');
            //     }
            // } else {
            //     setHelperTextDebateDescription(DDDTitle + ' Details is Required');
            // }
            setHelperTextDebateDescription(DDDTitle + ' Details is Required');
        } else {
            // if (joinMove === "Join") {
            //     handleJoinDebate(DDDTitle);
            // } else {
            //     handleMove(DDDTitle);
            // }
            handleJoinDebate(DDDTitle);
            setDebateDescriptionDialog(false)
        }
    }
    const handleJoinDebate = (action) => {
        let activeDebateRmoveAPI = API_Path + "api/debate/join";
        let inputObj = {
            "inputObject": {
                debateId: props.debateDetail && props.debateDetail._id,
                from: action,
                userId: props.user._id,
                email: props.user.email,
                description: debateDescription,
            }
        };
        axios
            .post(activeDebateRmoveAPI, inputObj, {
                headers: {
                    'Authorization': "Basic " + props.auth.accessToken
                }
            })
            .then((response) => {
                if (response.data.status === "SUCCESS") {
                    // getDebateList();
                    setRedirectToActiveDebate(true);
                } else {
                    if (response.data.status === "FAILURE" && response.data.message === "You are not available for this slot") {
                        setSnackbarState({
                            open: true,
                            message: response.data.message,
                            severity: "error",
                        });
                    } else if (response.data.status === "FAILURE" && response.data.message === "Already filled with some other member") {
                        setSnackbarState({
                            open: true,
                            message: response.data.message,
                            severity: "error",
                        });
                        setTimeout(() => {
                            if (props.refreshDebateCardObject !== null) {
                                props.refreshDebateCardObject(response.data.result);
                            }
                        }, 3000);
                    }
                }

            })
            .catch((error) => {
                console.error(error);
            });
    }
    const onClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarState({ open: false });
    };
    const debaterDescriptionDetails = (
        <>
            <FormControl error fullWidth>
                <TextField
                    id="debaterDescription"
                    label={DDDTitle + " Details"}
                    multiline
                    fullWidth
                    row={4} rowsMax={4}
                    variant="outlined"
                    onChange={handleDebateDescription}
                    className={clsx(classes.textField, classes.textFieldArea)}
                />
                <FormHelperText>{helperTextDebateDescription}</FormHelperText>
            </FormControl>
        </>
    )

    return (
        <>
            {redirectToActiveDebate ? redirectTo('/activeDebate', {
                debateObj: props.debateDetail,
                roomName: props.debateDetail._id
            }) : ""}
            <Button onClick={handleDebatePopup.bind(this, props.userType, props.Join)}
                disabled={props.disabled}
                variant="contained" color="primary"
                className={classes.circleBtn}>
                Join
            </Button>
            <ConfirmationModal openDialog={debateDescriptionDialog} modalHeading="" handleDialogClose={handleDDDialogClose} handleDelete={handleCheckDDD} modalContent={true} modalMessage={debaterDescriptionDetails}
                btnCancelName="Cancel" btnNoConfirmName="Submit" maxWidth="sm" />
            <SnackBar open={snackbarState.open} message={snackbarState.message} severity={snackbarState.severity} onClose={onClose} />
        </>
    )
}

const mapStateToProps = ({ loginDetails: { user, auth } }) => {
    return {
        user, auth
    };
};
export default withRouter(connect(mapStateToProps)(InstantDebateJoinButton));