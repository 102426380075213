import { Box, Button } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login";
// import { GoogleLogin } from "react-google-login";
import { LinkedIn } from "react-linkedin-login-oauth2";
import AppleSignin from "react-apple-signin-auth";
import { useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as yup from "yup";
import FacebookImg from "../../../assets/images/facebook.png";
import GmailImg from "../../../assets/images/google.png";
import Linkedin from "../../../assets/images/linkedin.png";
import Apple from "../../../assets/images/apple.png";
import loginBg from "../../../assets/images/loginBg.png";
// import logoImage from "../../../assets/images/logo.png";
import logoImage from "../../../assets/images/Vurbil - Final-02.png";
import mobileIllution from "../../../assets/images/mobileglow.png";
// import TwitterImg from "../../../assets/images/twitter.png";
import SnackBar from "../../../components/SnackBar";
import ConfirmationModal from "../../Common/ConfirmationModal";
import {
  Allow_Camera_Mic,
  API_Path,
  Make_Base_Auth,
  getKeys,
} from "../../Common/Const/index";
// import TwitterLogin from "../../Common/CustomTwitter/TwitterLogin";
import FormikInput from "../../Common/FormikComponents/FormikInput";
import Loader from "../../Common/Loader";
import FormikPassword from "../../Common/FormikComponents/FormikPassword";
import { useGoogleLogin } from "@react-oauth/google";
import PlayStore from "../../../assets/images/playStore.png";
import AppStore from "../../../assets/images/appStore.png";

// appleAuthHelpers.signIn({
//   authOptions: {
//     // same as above
//   },
//   onSuccess: (response) => console.log(response),
//   onError: (error) => console.error(error),
// });
const useStyles = makeStyles((theme) => ({
  loginBg: {
    padding: "30px 100px 30px 30px",
    background: "url(" + loginBg + ") fixed, #f6f7f9",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    height: "100%",
    // minHeight: '100vh',
    [theme.breakpoints.down("md")]: {
      padding: "15px",
      height: "auto",
    },
    // backgroundColor: '#f6f7f9',
    // [theme.breakpoints.up('md')]: {
    //   padding: '30px 100px 30px 30px',
    //   backgroundImage: "url(" + loginBg + ")",
    //   backgroundSize: 'cover',
    // },
    // [theme.breakpoints.down('md')]: {
    //   display: 'flex',
    //   flexFlow: 'column',
    //   justifyContent: 'center',
    // }
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
    },
  },
  mobileGlow: {
    position: "relative",
    "& .logo": {
      position: "absolute",
      top: "0",
      left: "30%",
      maxWidth: "10%",
    },
    "& .illusion": {
      position: "absolute",
      width: "50%",
      top: "64px",
      left: "10%",
    },
  },
  joinChat: {
    maxWidth: "352px",
    margin: "0 0 0 auto",
    boxSizing: "border-box",
    // [theme.breakpoints.up('md')]: {
    //   padding: '29px 45px 39px 42px',
    //   borderRadius: '20px',
    //   boxShadow: '1px 3px 12px 0 rgba(0, 0, 0, 0.06)',
    //   backgroundColor: 'rgba(255, 255, 255, .9)',
    //   margin: '0 0 0 auto',
    // },
    padding: "16px 45px 16px 42px",
    borderRadius: "20px",
    boxShadow: "1px 3px 12px 0 rgba(0, 0, 0, 0.06)",
    backgroundColor: "rgba(255, 255, 255, .5)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto 0",
    },
    "& h1, & h2": {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    "& .logo": {
      maxWidth: "50%",
    },
  },
  store: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "352px",
    margin: "0 0 0 auto",
    boxSizing: "border-box",
    padding: "20px 16px 20px 16px",
    [theme.breakpoints.down("sm")]: {
      margin: "auto 0",
    },
    "& img":{
      width:"100%"
    }
  },
  textField: {
    marginBottom: theme.spacing(2),
    maxHeight: theme.spacing(6),
  },
  loginButton: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0),
  },
  margin0: {
    margin: "0",
  },
  textCenter: {
    textAlign: "center",
  },
  fontWeightNormal: {
    fontWeight: "normal",
  },
  otherLoginSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& h6": {
      // color: "#758194",
      color: "black",
      marginBottom: theme.spacing(1.25),
    },
  },
  circleBtn: {
    fontSize: "14px",
    borderRadius: 30,
    fontWeight: "bold",
    color: "black",
    width: "100%",
  },
  signupButton: {
    color: "#313236",
    "& .normalTextBtn": {
      fontWeight: "normal",
      marginRight: "5px",
      color: "#758194",
    },
  },
  forgetPassword: {
    fontSize: "12px",
    padding: "0",
    textTransform: "none",
    fontWeight: "normal",
  },
  socialBtn: {
    width: 40,
    height: 40,
    position: "relative",
    overflow: "hidden",
    margin: "0 6px",
    "& img": {
      maxWidth: "100%",
    },
    "& button, & svg": {
      opacity: 0,
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      cursor: "pointer",
    },
  },
  appLogo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h4": {
      margin: "-12px 0 0 0",
      fontWeight: "500",
      letterSpacing: "1px",
      lineHeight: 1,
      textTransform: "uppercase",
    },
  },
}));

const SignIn = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [email, setemail] = useState("");
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [loginSuccess, dashboardRedirect] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);
  // const [applicationLogo, setApplicationLogo] = useState("");
  const enquery = (
    <Button
      variant="outlined"
      color="primary"
      className={clsx(classes.signupButton, classes.circleBtn)}
      component={Link}
      to="/adEnquiry"
    >
      Ad Inquiry
    </Button>
  );
  const logoSection = (
    <img
      // style={{ maxWidth: "10%" }}
      className="logo"
      alt="Logo"
      src={logoImage}
    />
  );
  const initState = {
    user_name: "",
    password: "",
  };
  const validationSchema = yup.object({
    user_name: yup.string().required("Email or phone is Required"),
    password: yup.string().required("Please Enter Password"),
  });

  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };

  const GetApplicationLogo = () => {
    axios
      .get(API_Path + "api/auth/get_application_logo", {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      })
      .then((response) => {
        if (
          response !== null &&
          response.data !== null &&
          response.data.result !== null
        ) {
          // setApplicationLogo(response.data.result);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitSignIn = async (state) => {
    setLoader(true);
    setemail(state.user_name);
    var SignupAPI = API_Path + "api/auth/login";
    var inputObj = {
      user_name: state.user_name.toLowerCase(),
      password: state.password,
    };
    axios
      .post(SignupAPI, inputObj, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setLoader(false);
          setSnackbarState({
            open: true,
            message: response.data.message,
            severity: "success",
          });
          //setLoginResponseDetails(response.data.result.jwt);
          let input = {
            email: response.data.result.user.email,
            _id: response.data.result.user._id,
            name: response.data.result.user.full_name,
            // response.data.result.user.first_name +
            // " " +
            // response.data.result.user.last_name,
            firstName: response.data.result.user.first_name,
            lastName: response.data.result.user.last_name,
            usertagging: response.data.result.user.user_tagging,
            mobile: response.data.result.user.mobile,
            profile_image: response.data.result.user.profile_image,
            badge_status: !!response.data.result.user.badge_status
              ? true
              : false,
            isSupport: response.data.result.isSupport ? true : false,
          };
          dispatch({
            type: "LOGIN_GLOBAL_DETAILS",
            data: {
              authenticated: true,
              auth: response.data.result.jwt,
              user: input,
              currentPackage: response.data.result.currentPaidPackage,
              is_disable_ads: response.data.result.is_disable_ads,
              is_disable_broadcast: response.data.result.is_disable_broadcast,
              logo: response.data.result.logo,
              reminder_content: response.data.result.reminder_content,
            },
          });
          getKeyDetails(response.data.result.jwt.accessToken);
          var redirectURl;
          if (sessionStorage.getItem("redirectDashboardURL") !== null) {
            Allow_Camera_Mic();
            redirectURl = sessionStorage.getItem("redirectDashboardURL");
            sessionStorage.removeItem("redirectDashboardURL");
            window.location.href = redirectURl;
          } else if (sessionStorage.getItem("redirectShareVurbURL") !== null) {
            Allow_Camera_Mic();
            redirectURl = sessionStorage.getItem("redirectShareVurbURL");
            sessionStorage.removeItem("redirectShareVurbURL");
            window.location.href = redirectURl;
          } else {
            Allow_Camera_Mic();
            dashboardRedirect(true);
          }
          // window.location.href = '/liveDebate';
        } else {
          setLoader(false);
          // alert(response.data.message);
          setSnackbarState({
            open: true,
            message: response.data.message,
            severity: "error",
          });
          if (response.data.message === "Your account is in Suspended Status") {
            setOpen(true);
          }
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };

  const getKeyDetails = async (token) => {
    const response = await getKeys(token);
    if (response.data.status === "SUCCESS") {
      dispatch({ type: "AWS_DETAILS", data: { hashed: response.data.result } });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUser = () => {
    var activate = API_Path + "api/auth/activateUser";
    var inputObj = { email: email.toLowerCase() };
    axios
      .post(activate, inputObj, {
        headers: {
          Authorization: Make_Base_Auth(),
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          let input = {
            email: response.data.result.user.email,
            _id: response.data.result.user._id,
            name:
              response.data.result.user.first_name +
              " " +
              response.data.result.user.last_name,
            mobile: response.data.result.user.mobile,
          };
          dispatch({
            type: "LOGIN_GLOBAL_DETAILS",
            data: {
              authenticated: true,
              auth: response.data.result.jwt,
              user: input,
              currentPackage: response.data.result.currentPaidPackage,
              is_disable_ads: response.data.result.is_disable_ads,
              is_disable_broadcast: response.data.result.is_disable_broadcast,
              logo: response.data.result.logo,
              reminder_content: response.data.result.reminder_content,
            },
          });
          dashboardRedirect(true);
          setSnackbarState({
            open: true,
            message: "Your Account Activated Successfully",
            severity: "success",
          });
        }
        setOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (props.location.state) {
      setSnackbarState({
        open: true,
        message:
          "User account created successfully. Please verify account via email",
        severity: "success",
      });
    }
  }, [props.location.state]);

  useEffect(() => {
    GetApplicationLogo();
  }, []);

  const responseGoogleLogin = async (response) => {
    if (
      response !== null &&
      response.data !== null &&
      response.data.email !== null
    ) {
      var googleResObj = response.data;
      var email = googleResObj.email;
      var first_name = googleResObj.given_name;
      var last_name = googleResObj.family_name;
      var full_name = googleResObj.name;
      // var image_Url = googleResObj.imageUrl;
      var sso_type = "google";

      var inputObj = {
        first_name: first_name,
        last_name: last_name,
        full_name: full_name,
        email: email,
        sso_type: sso_type,
      };
      submitSocialLogin(inputObj);
    }
  };

  const onFacebookLogin = async (data) => {
    if (data !== null && data.email !== null) {
      var fbResObj = data;
      var email = fbResObj.email;
      var first_name = fbResObj.name;
      var last_name = "";
      var full_name = fbResObj.name;
      // var image_Url = fbResObj.picture.data.url;
      var sso_type = "facebook";

      var inputObj = {
        first_name: first_name,
        last_name: last_name,
        full_name: full_name,
        email: email,
        sso_type: sso_type,
      };
      submitSocialLogin(inputObj);
    }
  };

  const onHandleAppleLogin = (data) => {
    console.log("appleResponse", data);
    setLoader(true);
    var url = window.location.origin + "/apple";
    var inputparam = {
      code: data.authorization.code,
      id_token: data.authorization.id_token,
      redirect_uri: url,
    };
    // Have to call the api
    var getUserByLinkedInCodeAPI =
      API_Path + "api/auth/getUserDetailsFromApple";
    axios
      .post(
        getUserByLinkedInCodeAPI,
        { inputObject: inputparam },
        {
          headers: {
            Authorization: Make_Base_Auth(),
          },
        }
      )
      .then((response) => {
        if (response.data !== null && response.data.result !== null) {
          var resultObj = response.data.result;
          var inputObj = {
            first_name: resultObj.name ? resultObj.name : resultObj.email,
            last_name: "",
            full_name: resultObj.name ? resultObj.name : resultObj.email,
            email: resultObj.email,
            sso_type: "apple",
          };
          submitSocialLogin(inputObj);
        } else {
          setSnackbarState({
            open: true,
            message: "Error while login",
            severity: "error",
          });
          setLoader(false);
        }
      });
  };

  const onHandleLinkedInLogin = (data) => {
    setLoader(true);
    var url = window.location.origin + "/linkedin";
    var inputparam = {
      code: data.code,
      redirect_uri: url,
    };
    // Have to call three api
    var getUserByLinkedInCodeAPI =
      API_Path + "api/auth/getUserDetailsFromLinkedIn";
    axios
      .post(
        getUserByLinkedInCodeAPI,
        { inputObject: inputparam },
        {
          headers: {
            Authorization: Make_Base_Auth(),
          },
        }
      )
      .then((response) => {
        if (response.data !== null && response.data.result !== null) {
          var resultObj = response.data.result;
          var inputObj = {
            first_name: resultObj.first_name,
            last_name: resultObj.last_name,
            full_name: resultObj.full_name,
            email: resultObj.email,
            sso_type: "linkedin",
          };
          submitSocialLogin(inputObj);
        } else {
          setSnackbarState({
            open: true,
            message: "Error while login",
            severity: "error",
          });
          setLoader(false);
        }
      });
  };

  const submitSocialLogin = (inputObj) => {
    setLoader(true);
    var ValidateSocialLoginAPI = API_Path + "api/auth/validateSociallogin";
    var redirectURl;
    axios
      .post(
        ValidateSocialLoginAPI,
        { inputObject: inputObj },
        {
          headers: {
            Authorization: Make_Base_Auth(),
          },
        }
      )
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setLoader(false);
          setSnackbarState({
            open: true,
            message: response.data.message,
            severity: "success",
          });
          //setLoginResponseDetails(response.data.result.jwt);
          let input = {
            email: response.data.result.user.email,
            _id: response.data.result.user._id,
            name: response.data.result.user.full_name,
            // response.data.result.user.first_name +
            // " " +
            // response.data.result.user.last_name,
            mobile: response.data.result.user.mobile,
            profile_image: response.data.result.user.profile_image,
            isSupport: response.data.result.isSupport ? true : false,
          };
          dispatch({
            type: "LOGIN_GLOBAL_DETAILS",
            data: {
              authenticated: true,
              auth: response.data.result.jwt,
              user: input,
              currentPackage: response.data.result.currentPaidPackage,
              is_disable_ads: response.data.result.is_disable_ads,
              is_disable_broadcast: response.data.result.is_disable_broadcast,
              logo: response.data.result.logo,
              reminder_content: response.data.result.reminder_content,
            },
          });
          if (sessionStorage.getItem("redirectDashboardURL") !== null) {
            redirectURl = sessionStorage.getItem("redirectDashboardURL");
            sessionStorage.removeItem("redirectDashboardURL");
            window.location.href = redirectURl;
          } else if (sessionStorage.getItem("redirectShareVurbURL") !== null) {
            Allow_Camera_Mic();
            redirectURl = sessionStorage.getItem("redirectShareVurbURL");
            sessionStorage.removeItem("redirectShareVurbURL");
            window.location.href = redirectURl;
          } else {
            dashboardRedirect(true);
          }
          // window.location.href = '/liveDebate';
        } else {
          setLoader(false);
          // alert(response.data.message);
          setSnackbarState({
            open: true,
            message: response.data.message,
            severity: "error",
          });
          if (response.data.message === "Your account is in Suspended Status") {
            setOpen(true);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const onTwitterLogin = (userdata) => {
  //   var inputObj = {
  //     first_name: userdata.name,
  //     last_name: "",
  //     full_name: userdata.name,
  //     email: userdata.email,
  //     sso_type: "twitter",
  //   };
  //   submitSocialLogin(inputObj);
  // };

  const onHandleLinkedInFailure = (error) => {
    console.error(error);
  };
  const googleloginclick = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: "Bearer " + tokenResponse.access_token } }
      );
      responseGoogleLogin(userInfo);
    },
    onError: () => {
      console.log("Login Failed");
    },
  });
  const customHeader = {};
  customHeader["Test"] = "test-header";
  // customHeader['Authorization'] = "Bearer "+"AAAAAAAAAAAAAAAAAAAAAOpUNAEAAAAA%2Bn3eYCFDYDMuOqd2%2FnNNifYHibY%3DNcUpxE1ObNUu579Vu0vTitqlNptcC0xX8i8WwLjADWxPDQz2Lq";

  return (
    <React.Fragment>
      {loginSuccess ? <Redirect to="/vurbs" /> : ""}
      <div className={classes.loginBg}>
        {/* <Hidden smDown> */}
        {/* <div className={classes.flexJCSB}> */}
        {/* <Box className={classes.appLogo}>
              {applicationLogo !== null && applicationLogo !== undefined ?
                <>

                  {applicationLogo.logo !== null && applicationLogo.logo !== undefined ?
                    <img alt="Logo" src={applicationLogo.logo && applicationLogo.logo.url} style={{height: '54px', objectFit: 'contain', marginLeft: '-14px'}} />
                    :
                    <img alt="Logo" src={beeLogo} style={{height: '40px', objectFit: 'contain', marginLeft: '-14px'}} />
                  }
                  <h4>
                    {applicationLogo.application_name !== null && applicationLogo.application_name !== undefined ?
                      applicationLogo.application_name
                      :
                      <img alt="Logo" src={textLogo} style={{width: '140px', height: '15px', objectFit: 'contain'}} />
                    }
                  </h4>
                </>
                :
                <img alt="Logo" src={logoImage} />
              }
            </Box> */}

        {/* <div>{enquery}</div>
          </div> */}
        {/* </Hidden> */}
        <Hidden smDown>
          <div className={classes.mobileGlow}>
            {logoSection}
            <img
              className="illusion"
              src={mobileIllution}
              alt="mobile-illution"
            />
          </div>
        </Hidden>
        <div className={classes.joinChat}>
          <Hidden mdUp>
            <Box textAlign="center">{logoSection}</Box>
          </Hidden>
          <div
            className="m-0"
            style={{ display: "flex", flexFlow: "column", textAlign: "center" }}
          >
            <Hidden smDown>
              <h1
                className="m-0"
                style={{ fontWeight: 300, fontFamily: "revert" }}
              >
                VURBIL
              </h1>
            </Hidden>
            <h6
              className="m-0"
              style={{
                fontWeight: 500,
                paddingBottom: "4px",
              }}
            >
              {/* Make your point in the Townsquare. */}
              The Global Town Square
            </h6>
            {/* <h2 className="m-0">Sign in</h2> */}
            <h2
              className="font-weight-normal m-0"
              // style={{ marginBottom: "16px" }}
            >
              Welcome Back!
            </h2>
          </div>

          <div className={clsx(classes.otherLoginSpacing, classes.textCenter)}>
            {/* <h6
              className={clsx(classes.margin0)}
              style={{ fontWeight: "bold" }}
            >
              Sign In
            </h6> */}
            <Box display="flex" justifyContent="center">
              <Box className={classes.socialBtn}>
                <img
                  alt=""
                  style={{ cursor: "pointer" }}
                  src={GmailImg}
                  onClick={() => googleloginclick()}
                />
                {/* <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                  // buttonText="Login"
                  render={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className="google-btn"
                      size="large"
                      block="true"
                    >
                      <span>
                        <img
                          src={GmailImg}
                          className="img-fluid"
                          alt="Google-login"
                        />
                      </span>
                      <p className="m-0"> Login with Google</p>
                    </Button>
                  )}
                  onSuccess={responseGoogleLogin}
                  onFailure={(e) => {}}
                /> */}
              </Box>
              <Box className={classes.socialBtn}>
                <img alt="" src={FacebookImg} />
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APPID}
                  // autoLoad={false}
                  fields="name,email,picture"
                  callback={onFacebookLogin}
                  disableMobileRedirect={true}
                  onFailure={() => {
                    // alert("failed");
                  }}
                  render={(renderProps) => (
                    <Button
                      className="facebook-btn mb-2"
                      disabled={renderProps.disabled}
                      size="large"
                      block
                      onClick={renderProps.onClick}
                    >
                      <span>
                        <img
                          src={FacebookImg}
                          className="img-fluid"
                          alt="Facebook-login"
                        />
                      </span>
                      <p className="m-0">Login with Facebook</p>
                    </Button>
                  )}
                />
              </Box> 
              {/* <Box className={classes.socialBtn}>
                <img alt="" src={TwitterImg} />
                <TwitterLogin authCallback={onTwitterLogin}></TwitterLogin>
              </Box> */}
              <Box className={classes.socialBtn}>
                <img
                  alt=""
                  src={Linkedin}
                  // style={{ width: "30px", height: "30px" }}
                />
                <LinkedIn
                  clientId={process.env.REACT_APP_LINKEDIN_CLIENTID}
                  onFailure={onHandleLinkedInFailure}
                  supportIE
                  onSuccess={onHandleLinkedInLogin}
                  scope="r_liteprofile r_emailaddress"
                  redirectUri={`${window.location.origin}/linkedin`}
                  redirectPath="/linkedin"
                >
                  {/* <img src={linkedin} alt="Log in with Linked In" style={{ maxWidth: '180px' }} /> */}
                </LinkedIn>
              </Box>
              <Box className={classes.socialBtn}>
                <img
                  alt=""
                  src={Apple}
                  // style={{ width: "30px", height: "30px" }}
                />
                <AppleSignin
                  /** Auth options passed to AppleID.auth.init() */
                  authOptions={{
                    clientId: process.env.REACT_APP_APPLE_CLIENTID,
                    scope: "email name",
                    redirectURI: `${window.location.origin}/apple`,
                    // redirectURI:
                    //   "https://production.killerpolitics.com/login/callback",
                    state: "",
                    nonce: "nonce",
                    usePopup: true,
                  }}
                  /** General props */
                  uiType="dark"
                  /** className */
                  className="apple-auth-btn"
                  /** Skips loading the apple script if true */
                  skipScript={false}
                  onSuccess={onHandleAppleLogin}
                  onError={(error) => console.error(error)}
                  /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                  // render={(props) => <button {...props}>My Custom Button</button>}
                />
              </Box>
            </Box>
          </div>
          <h6
            className={clsx(classes.margin0)}
            style={{
              textAlign: "center",
              marginTop: "10px",
              paddingBottom: "8px",
            }}
          >
            {/* Or you can Login with */}
            Or you can sign in with
          </h6>
          <Formik
            initialValues={{ ...initState }}
            onSubmit={submitSignIn}
            validationSchema={validationSchema}
            validateOnBlur={true}
          >
            {({ isSubmitting }) => {
              return (
                <Form autoComplete="off">
                  <Field
                    name="user_name"
                    label="Email or Phone"
                    placeholder=""
                    type="text"
                    as={FormikInput}
                    className={clsx(classes.textField)}
                  />
                  {/* <Field
                    name="password"
                    label="Password"
                    placeholder=""
                    type="password"
                    as={FormikInput}
                    className={clsx(classes.textField)}
                  /> */}
                  <Field
                    name="password"
                    label="Password"
                    placeholder=""
                    type="password"
                    as={FormikPassword}
                    className={clsx(classes.textField)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={clsx(classes.loginButton, classes.circleBtn)}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Sign In
                  </Button>
                </Form>
              );
            }}
          </Formik>
          <Box textAlign="center" margin={"8px 0"}>
            <Button
              component={Link}
              to="/forgetpassword"
              className={classes.forgetPassword}
            >
              Forgot Password?
            </Button>
          </Box>

          <Button
            variant="outlined"
            fullWidth
            className={clsx(classes.signupButton, classes.circleBtn)}
            component={Link}
            to="/userSignup"
          >
            <span className="normalTextBtn">New User </span>Sign Up
          </Button>
          {/* <Hidden mdUp> */}
          <div style={{ marginTop: "18px", textAlign: "center" }}>
            {enquery}
          </div>
          {/* </Hidden> */}
          <Box textAlign="center" className="m-0">
            <h6
              className="fontWeightNormal"
              style={{ margin: "5px", fontWeight: "400" }}
            >
              Patent Pending
            </h6>
          </Box>
        </div>
        <div className={classes.store}>
          <a href="https://apps.apple.com/us/app/vurbil/id1568104115" target="_blank">
            <img src={AppStore} alt="appStore"/>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.vinc.vurbil"
            target="_blank"
          >
            <img src={PlayStore} alt="playStore"/>
          </a>
        </div>
      </div>
      <SnackBar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={onClose}
      />
      <ConfirmationModal
        openDialog={open}
        modalHeading="Message"
        handleDialogClose={handleClose}
        handleDelete={handleUser}
        modalMessage="Your account is deactivated, Would you like to activate ?"
        btnCancelName="Cancel"
        btnNoConfirmName="Confirm"
        maxWidth="xs"
      />
      {loader && <Loader />}
    </React.Fragment>
  );
};

export default SignIn;
// export default connect(null, mapDispatchToProps)(SignIn);
