import { InputBase } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    padding: theme.spacing(0, 1.5),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    right: "0",
    color: "#778192",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1.5, 0, 1.5, 2.5),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      backgroundColor: "#f6f7f8",
      borderRadius: "30px",
      fontSize: "12px",
      color: "#758194",
    },
  },
  searchWhiteBg: {
    inputInput: {
      backgroundColor: "#fff",
    },
  },
}));
let ispageReload = true;
const CustomSearch = ({ className, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    if (ispageReload) {
      ispageReload = false;
      dispatch({
        type: "GLOBAL_SEARCH_VAL",
        data: "",
      });
    }
    return () => {};
  }, []);

  return (
    <div className={clsx(classes.search, className)}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>

      <InputBase
        placeholder={props.placeholder || "Search…"}
        onChange={props.handleSearch}
        value={props.value}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
};

export default CustomSearch;
