import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import axios from "axios";
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import { API_Path } from '../../Common/Const/index';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f6f7f8',
    height: 'calc(100vh - 133px)',
    display: 'flex',
  },
  paymentMessage: {
    width: '100%',
    maxWidth: '480px',
    margin: 'auto',
    textAlign: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.13)',
    padding: '20px 20px 30px 20px',
    borderRadius: '12px',
    backgroundColor: '#fff',
    "& svg": {
      width: '4em',
      height: '4em',
      fill: 'red'
    },
    "& h2": {
      margin: '0'
    },
    "& p": {
      margin: '0',
      fontSize: '14px'
    }
  }
}))

const PaymentFailure = (props) => {
  const classes = useStyles();
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const sessionId = params.get("session_id");


  useEffect(() => {

    async function fetchSession() {
    const planId = params.get("planId");
    const packageId = params.get("packageId");
    const debateId = params.get("debateId");
    
    var userId=props.user._id;
    var updatePaymentTransactionAPI=API_Path+'api/auth/updatePaymentTransaction';
    await axios.post(updatePaymentTransactionAPI, {transactionFrom:"Web",transactionId:sessionId,planId:planId,packageId:packageId,userId:userId,debateId:debateId}, 
      {
        headers: {
    'Content-Type': 'application/json',
    'Authorization':"Basic " + props.auth.accessToken
    }
    })
    .then((res) =>{
        if(res.data!==null){
            setTimeout(()=>{window.location.href="/payment";},3000);            
        }

    });
    }
    fetchSession();
  }, [sessionId]);
  return (
    <Box className={classes.root}>
      <Paper className={classes.paymentMessage}>
        <ErrorOutlineIcon />
        <h2>Your payment was failed</h2>
        <p>
          Please try again. <br /> Please wait while redirecting...
      </p>
      </Paper>
    </Box>
  );
};

// export default PaymentFailure;
const mapStateToProps = ({ loginDetails: { user, auth } }) => {
  return {
    user, auth
  };
};

export default connect(mapStateToProps)(PaymentFailure);
