import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { API_Path } from "../../Common/Const";
import { makeStyles,Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  contentMargin: {
    margin: "4px 0",
  },
  main: {
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "13px",
  },
  card: {
    // backgroundColor: 'rgba(0, 0, 0, 0.03)',
    cursor: "pointer",
    padding: "7px",
    fontSize: "medium",
    "& .tagcount": {
      // color: "black",
      fontWeight: 600,
      fontSize: "11px",
      paddingLeft: "11px",
      color: "#ffc003",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
    },
  },
}));
let searchEnable = false;
export default function TrendingVurbs(props) {
  const {searchVal,globalSearch} = props
  const classes = useStyles();
  const [list, SetList] = useState([]);
  const limit = props.tranding ? 20 : 5;
  // useEffect(() => {
  //   (async () => {
  //     axios
  //       .get(API_Path + `api/vurb/trending?limit=${limit}&user_id=${props.user._id}`, {
  //         headers: {
  //           Authorization: "Basic " + props.auth.accessToken,
  //         },
  //       })
  //       .then((response) => {
  //         if (response.data && response.data.result) {
  //           SetList(response.data.result);
  //         }
  //       })
  //       .catch((e) => console.log("err", e));
  //   })();
  // }, []);
  useEffect(() => {
    if(!globalSearch&&!searchVal){
      getTrending();
    }
  }, []);
  useEffect(() => {
    if (searchVal) {
      searchEnable = true;
      getTrending(searchVal);
    } else {
      if (searchEnable) {
        SetList([])
        searchEnable=false
        getTrending();
      }
    }
  }, [searchVal]);
  const getTrending = async(search = '') =>{
    axios
        .get(API_Path + `api/vurb/trending?limit=${limit}&user_id=${props.user._id}&searchText=${search}`, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          if (response.data && response.data.result) {
            SetList(response.data.result);
          }
        })
        .catch((e) => console.log("err", e));
    }
  const OnHashSearch = (val) => {
    props.searchHash && props.searchHash(val.substring(1));
  };
  return (
    <Fragment>
      {list.length > 0 ? (
        <Fragment>
          <div className={classes.main}>
            {list.map((val, i) => {
              let { tag = "", count = "" } = val;
              return (
                <div
                  key={i}
                  className={classes.card}
                  onClick={() => OnHashSearch(tag)}
                >
                  {props.tranding ? (
                    <a
                      style={{ color: "black", textDecoration: "none" }}
                      href={`${
                        process.env.REACT_APP_API_PATH
                      }deeplink?type=hashtag&token=${tag.substring(1)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {tag}
                    </a>
                  ) : (
                    <div className="tagname">{tag}</div>
                  )}
                  <div className="tagcount">{count} Vurbs</div>
                </div>
              );
            })}
          </div>
        </Fragment>
      ): <Typography align="center"> No Trending Vurbs</Typography>}
    </Fragment>
  );
}
