import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    adsContainer: {
        position: 'relative',
        paddingTop: '56.25%',
        // height: '100%',
        // width: '100%',
        "& .react-player": {
            position: 'absolute',
            top: 0,
            left: 0,
        },
        "& .adsSection": {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: '#000',
            zIndex: 1,
            display: 'none',
            "&.d-block": {
                display: 'block',
            },
            "& button": {
                position: 'absolute',
                bottom: "15%",
                right: 0,
                zIndex: 2,
                padding: '7px 25px',
                border: 0,
                backgroundColor: '#ccc'
            }
        }
    }
}))
const baseUrl = process.env.REACT_APP_VIDEO_BASEURL;
const Adsinjection = (props) => {
    const classes = useStyles();
    const [playing, SetPlaying] = useState(true);
    const [totalSeconds, SetTotalSeconds] = useState(false);
    const [isAdPlayed, SetIsAdPlayed] = useState(false);
    const [showAdSkip, SetShowAdSkip] = useState(false);
    const [videoURL, SetVideoURL] = useState(null);
    const [adsVideoEnable, setAdsVideoEnable] = useState(false);
    const [timer, setTimer] = useState(5);

    useEffect(() => {
        SetVideoURL(props.videoUrl.url ? props.videoUrl.url :!!props.videoUrl.meta_info? baseUrl+props.videoUrl.meta_info.key : props.videoUrl.video_history_url);
    }, [props.videoUrl])

    const handlePlay = () => {
        SetPlaying(true);
    }
    const handleDuration = (duration) => {
        SetTotalSeconds(duration);
    }
    const onAdProgress = (duration) => {
        if (duration.playedSeconds >= 5) {
            SetShowAdSkip(true);
        }
        if(duration.playedSeconds <= 5){
            setTimer(5 - Math.floor(duration.playedSeconds))
        }
    }
    const handleEnded = () => {
    }

    const handleProgress = (state) => {
        if (props.adsList) {
            var halfinterval = '';
            if (props.adsList.ad_show_in === 'Start') {
                halfinterval = parseInt(0);
            } else if (props.adsList.ad_show_in === 'Middle') {
                halfinterval = parseInt(totalSeconds / 2);
            } else if (props.adsList.ad_show_in === 'End') {
                halfinterval = parseInt(totalSeconds - 1);
            }
            if (halfinterval <= state.playedSeconds && !isAdPlayed) {
                SetPlaying(false);
                setAdsVideoEnable(true);
            }
        }
    }
    const advertisementEnded = () => {
        setAdsVideoEnable(false);
        SetIsAdPlayed(true);
        SetPlaying(true);
    }
    const skipAdvertiseMent = () => {
        setAdsVideoEnable(false);
        SetIsAdPlayed(true);
        SetPlaying(true);
    }
    return (
        <div className={classes.adsContainer}>
            <ReactPlayer className='react-player'
                url={videoURL ? videoURL : ""}
                playing={playing}
                width='100%'
                height='100%'
                controls
                light={props.videoUrl.thumbnailImage ? props.videoUrl.thumbnailImage : props.videoUrl.thumbnails}
                config={{
                    file: {
                        forceHLS: false,
                    },
                }}
                onStart={handlePlay}
                onPlay={handlePlay}
                onDuration={handleDuration}
                onEnded={handleEnded}
                onProgress={handleProgress}
            />
            {adsVideoEnable && <div className="d-block adsSection">
                <button onClick={showAdSkip&&skipAdvertiseMent} style={{ display: (videoURL ? 'block' : 'none') }}>{showAdSkip? "Skip" : timer}</button>
                <ReactPlayer className='react-player'
                    url={props.adsList && props.adsList.url ? props.adsList.url : ""}
                    playing
                    width='100%'
                    height='100%'
                    controls={false}
                    config={{
                        file: {
                            forceHLS: false,
                        },
                    }}
                    onEnded={advertisementEnded}
                    onProgress={onAdProgress}
                />
            </div>
            }
        </div>
    )
}

export default Adsinjection