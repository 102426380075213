import { Box, makeStyles, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Advertisement from "../../Common/Advertisement/index";
import ClassificationList from "../../Common/ClassificationList";
import CustomPagination from "../../Common/CommonPagination";
import { API_Path, clearStateLocation } from "../../Common/Const";
import CustomDebateCard from "../../Common/CustomDebateCard";
import CustomDropdown from "../../Common/CustomDropdown";
import CustomSearch from "../../Common/CustomSearch";
import FeaturedDebaters from "../../Common/FeaturedDebaters";
import Loader from "../../Common/Loader";
const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: "#f6f7f8",
    // padding: '30px',
    padding: "18px 0 40px 0",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      // padding: '30px 40px',
      padding: "18px 18px 40px 18px",
    },
  },
  contentRightSection: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "calc(100% - 260px)",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& > div": {
      [theme.breakpoints.up("md")]: {
        width: "calc(100% - 364px)",
      },
      "&:only-child": {
        [theme.breakpoints.up("md")]: {
          width: "100%",
        },
      },
    },
  },
  leftSection: {
    padding: "0 18px 18px 18px",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 260px",
      maxWidth: "260px",
    },
  },
  contentSection: {
    width: "100%",
    padding: "0 18px",
  },
  rightSection: {
    padding: "0 18px 18px 18px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 364px",
      maxWidth: "364px",
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column",
    },
  },
  // paperWhiteParent: {
  //     boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.13)',
  //     // padding: '20px 20px 30px 20px',
  //     borderRadius: '12px',
  //     backgroundColor: '#fff',
  //     margin: '20px 0',
  //     "& .videoPlayer": {
  //         "&.noVideo": {
  //             height: 220,
  //         }
  //     }
  // },
  // seperator: {
  //     borderBottom: 'solid 1px #cdd1d8;',
  //     margin: '30px 0 25px 0',
  // },
  searchWhiteBg: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
  // completedVideoSection: {
  //     height: '255px',
  //     position: 'relative',
  //     overflow: 'hidden',
  //     color: '#fff',
  //     "& .fullscreen, & video": {
  //         width: '100% !important',
  //         height: '100% !important',
  //         margin: '0',
  //     },
  // },
}));

const options = ["", "Relevant", "Most Recent", "Popular"];

const Recorded = (props) => {
  const classes = useStyles();
  const [debateList, setDebateList] = useState([]);
  const [debateTotalCount, setDebateTotalCount] = useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [searchVal, setSearchVal] = React.useState("");
  const catId = useRef("");
  const myRef = useRef("");
  const type = useRef("Relevant");
  const [loader, setLoader] = useState(false);
  const [classificationLoaded, setClassificationLoaded] = useState(false);
  const [debateListLoaded, setDebateListLoaded] = useState(false);
  const [page, setPage] = React.useState(1);
  const pageTotalCount = Math.ceil(debateTotalCount / 10);
  const handleChange = (event, value) => {
    setPage(value);
    getDebateDetails(value);
  };
  const handleDebateDetails = (categoryId) => {
    if (categoryId === "All") {
      catId.current = null;
      getDebateDetails(null);
      if (myRef.current) {
        myRef.current.getAdvertisementList("All");
      }
    } else {
      catId.current = categoryId;
      getDebateDetails(catId.current);
      if (myRef.current) {
        myRef.current.getAdvertisementList(catId.current);
      }
    }
    setPage(1);
  };

  const getDebateDetails = async (currentPageVal) => {
    var DebatesDetails = API_Path + "api/debate/list";
    if (
      props.auth &&
      props.auth.accessToken
      // &&( searchVal && searchVal.length > 1 || searchVal === "")
    ) {
      let inputObj = {
        inputObject: {
          type: "recorded",
          userId: props.user._id,
          // categoryId: catId.current,
          searchText: searchVal,
          sortBy: type.current,
          pagination: {
            currentpage: currentPageVal ? currentPageVal : 1,
            recordsperpage: 10,
          },
          allBlockedUsers:props.allBlockedUsers?props.allBlockedUsers:[]
        },
      };
      if (catId.current) {
        inputObj.inputObject["categoryId"] = catId.current;
      }
      await axios
        .post(DebatesDetails, inputObj, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          setDebateList(response.data.result.data);
          setDebateTotalCount(response.data.result.totalCount);
          setDebateListLoaded(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    clearStateLocation();
  }, []);

  useEffect(() => {
    getDebateDetails(catId.current);
    // getDebateDetails("");
  }, [searchVal]);

  useEffect(() => {
    setLoader(true);
    if (classificationLoaded === true && debateListLoaded === true) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [classificationLoaded, debateListLoaded]);

  const handleMenuItemClick = (event, index, val) => {
    setSelectedIndex(index);
    type.current = val;
    getDebateDetails();
  };
  const handleSearch = (event) => {
    setSearchVal(event.target.value);
    // getDebateDetails(catId.current);
    setPage(1);
  };
  const classifyLoaded = (val) => {
    setClassificationLoaded(val);
  };
  const handleDeleteDebate = () => {
    getDebateDetails(1);
  };
  const AdvertisementSection = (
    <div className={classes.rightSection}>
      <Advertisement innerRef={myRef} />
    </div>
  );
  return (
    <div className={classes.pageContainer}>
      <div className={classes.leftSection}>
        <ClassificationList
          handleDebateDetails={handleDebateDetails}
          classifyLoaded={classifyLoaded}
        />
        <FeaturedDebaters />
      </div>
      <div className={classes.contentRightSection}>
        <div className={classes.contentSection}>
          <div className={classes.flexJCSB}>
            <CustomSearch
              className={classes.searchWhiteBg}
              handleSearch={handleSearch}
            />
            <Box py={{ xs: 2, sm: 0 }} p={0.5}>
              <CustomDropdown
                options={options}
                selectedIndex={selectedIndex}
                handleMenuItemClick={handleMenuItemClick}
              />
            </Box>
          </div>
          {/* <Box mt={2}>
                        <CustomPagination page={page} count={pageTotalCount} handleChange={handleChange} JusCont="flex-end" />
                    </Box> */}
          {debateList && debateList.length > 0 ? (
            debateList.map((list, index) => {
              return (
                // <Paper key={index} className={classes.paperWhiteParent}>
                //     <Box p={2.25}>
                //         <CustomDebateHeader month={list.debate_planned_start_datetime} date={list.debate_planned_start_datetime} heading={list.title} debateType={list.debate_type}
                //             liveBtn={false} debateDetail={list} screen="Recorded" />
                //     </Box>
                //     {/*// <div className={classes.seperator}></div> */}
                //     <VideoPlayer videoURL={list && list.video_history_url} playing={false} />
                //     {/* <div className={classes.completedVideoSection}>
                //         <ReactWebMediaPlayer
                //             title=""
                //             video={list.video_history_url}
                //         // thumbnail="https://any-link.com/video-thumbnail.jpg"
                //         />
                //     </div>
                //     */}
                //     <Box p={2.25}>
                //         <CustomDebateContent key={index} debateDetail={list} />
                //     </Box>
                // </Paper>
                <CustomDebateCard
                  key={index}
                  debateDetail={list}
                  userId={props && props.user._id}
                  getDebateDetails={getDebateDetails}
                  screen="Recorded"
                  handleDeleteDebate={handleDeleteDebate}
                />
              );
            })
          ) : (
            <Box mt={3}>
              <Typography align="center" variant="h6">
                No Debates Available
              </Typography>
            </Box>
          )}
          <CustomPagination
            page={page}
            count={pageTotalCount}
            handleChange={handleChange}
            JusCont="flex-end"
          />
        </div>
        {props.is_disable_ads !== 1 &&
        props.currentPackage &&
        props.currentPackage.length === 0 ? (
          <>{AdvertisementSection}</>
        ) : (
          <>
            {props.is_disable_ads === 1 ||
            (props.currentPackage &&
              props.currentPackage.length > 0 &&
              props.currentPackage.findIndex((e) => {
                return e.package_name === "Ad free payment";
              }) > -1) ? (
              ""
            ) : (
              <>{AdvertisementSection}</>
            )}
          </>
        )}
      </div>
      {loader && <Loader />}
    </div>
  );
};

// export default ActiveDebateList
const mapStateToProps = ({
  loginDetails: { user, auth, is_disable_ads, currentPackage,allBlockedUsers },
}) => {
  return {
    user,
    auth,
    is_disable_ads,
    currentPackage,
    allBlockedUsers
  };
};
export default connect(mapStateToProps)(Recorded);
