import { combineReducers } from "redux";
import loginDetailsReducer from "./loginDetails";
import globalSearch from './GlobalSearch';
import ReportDetails from './reportDetails';
import { withReduxStateSync } from "redux-state-sync";
// import SelectedResourceReducer from "./resourceData";

const allReducers = {
  loginDetails: loginDetailsReducer,
  GlobalSearch: globalSearch,
  reportdetails:ReportDetails
};

export default withReduxStateSync(combineReducers(allReducers));
