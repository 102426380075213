import { Box, makeStyles, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import SnackBar from "../../../components/SnackBar";
import Advertisement from "../../Common/Advertisement/index";
import ClassificationList from "../../Common/ClassificationList";
import CustomPagination from "../../Common/CommonPagination";
import { API_Path, clearStateLocation } from "../../Common/Const";
// import CustomDebateVideoContent from "../../Common/CustomDebateVideoContent";
// import ActiveDebateCard from './ActiveDebateCard';
import CustomDebateCard from "../../Common/CustomDebateCard";
import CustomDropdown from "../../Common/CustomDropdown";
import CustomSearch from "../../Common/CustomSearch";
import FeaturedDebaters from "../../Common/FeaturedDebaters";
import Loader from "../../Common/Loader";
import CreateDebate from "../MyDebates/CreateDebate";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: "#f6f7f8",
    // padding: '30px',
    padding: "18px 0 40px 0",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      // padding: '30px 40px',
      padding: "18px 18px 40px 18px",
    },
  },
  contentRightSection: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "calc(100% - 260px)",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& > div": {
      [theme.breakpoints.up("md")]: {
        width: "calc(100% - 364px)",
      },
      "&:only-child": {
        [theme.breakpoints.up("md")]: {
          width: "100%",
        },
      },
    },
  },
  leftSection: {
    padding: "0 18px 18px 18px",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 260px",
      maxWidth: "260px",
    },
  },
  contentSection: {
    width: "100%",
    padding: "0 18px",
  },
  rightSection: {
    padding: "0 18px 18px 18px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 364px",
      maxWidth: "364px",
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column",
    },
  },
  // paperWhiteParent: {
  //     boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.13)',
  //     padding: '20px 20px 30px 20px',
  //     borderRadius: '12px',
  //     backgroundColor: '#fff',
  //     margin: '20px 0',
  // },
  // seperator: {
  //     borderBottom: 'solid 1px #cdd1d8;',
  //     margin: '30px -20px 25px -20px',
  // },
  searchWhiteBg: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
}));

const options = ["", "Relevant", "Most Recent", "Popular"];

const debateStatusOptions = ["Scheduled Debate", "Instant Debate", "All"];

const ActiveDebateList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [debateList, setDebateList] = useState([]);
  const [debateTotalCount, setDebateTotalCount] = useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [searchVal, setSearchVal] = React.useState("");
  const catId = useRef("");
  const myRef = useRef("");
  const type = useRef("Relevant");
  const debateStatusVal = useRef(null);
  const [loader, setLoader] = useState(false);
  const [classificationLoaded, setClassificationLoaded] = useState(false);
  const [debateListLoaded, setDebateListLoaded] = useState(false);
  const [page, setPage] = React.useState(1);
  const [debateStatus, setDebateStatus] = React.useState(2);
  const pageTotalCount = Math.ceil(debateTotalCount / 10);
  const [createDebate, setCreateDebate] = useState(false);
  const [BtnName, setBtnName] = React.useState("Create");
  const [debateObject, setDebateObject] = React.useState(null);
  const [classificationDetails, setclassificationDetails] = React.useState([]);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const myRefAdvertisment = useRef("");

  const handleChange = (event, value) => {
    setPage(value);
    getDebateDetails(value, searchVal);
  };
  const handleDebateDetails = (categoryId) => {
    if (categoryId === "All") {
      catId.current = null;
      getDebateDetails(null, searchVal);
      if (myRefAdvertisment.current) {
        myRefAdvertisment.current.getAdvertisementList("All");
      }
    } else {
      catId.current = categoryId;
      getDebateDetails(catId.current, searchVal);
      if (myRefAdvertisment.current) {
        myRefAdvertisment.current.getAdvertisementList(catId.current);
      }
    }
    setPage(1);
  };

  const getDebateDetails = async (currentPageVal, val) => {
    var DebatesDetails = API_Path + "api/debate/list";
    if (
      props.auth &&
      props.auth.accessToken &&
      ((val && val.length > 0) || val === "")
    ) {
      let inputObj = {
        inputObject: {
          type: "upcoming",
          userId: props.user._id,
          // categoryId: catId.current,
          searchText: val,
          sortBy: type.current,
          isInstantDebate: debateStatusVal.current,
          pagination: {
            currentpage: currentPageVal ? currentPageVal : 1,
            recordsperpage: 10,
          },
          allBlockedUsers:props.allBlockedUsers?props.allBlockedUsers:[]
        },
      };
      if (catId.current) {
        inputObj.inputObject["categoryId"] = catId.current;
      }
      axios
        .post(DebatesDetails, inputObj, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          setDebateList(response.data.result.data);
          setDebateTotalCount(response.data.result.totalCount);
          setDebateListLoaded(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    clearStateLocation();
  }, []);

  useEffect(() => {
    if (props.globalSearch && props.globalSearch.globalSearch) {
      setSearchVal(props.globalSearch && props.globalSearch.globalSearch);
      if (props.globalSearch) {
        getDebateDetails(1, props.globalSearch.globalSearch);
      }
    } else if (props.globalSearch.globalSearch === "") {
      if (sessionStorage["email"]) {
        var sessionVal = sessionStorage.getItem("email");
        setSearchVal(sessionVal);
        sessionStorage.removeItem("email");
        getDebateDetails(1, sessionVal);
      } else {
        setSearchVal("");
        getDebateDetails(1, "");
      }
    } else {
      if (sessionStorage["email"]) {
        var sessionVal = sessionStorage.getItem("email");
        setSearchVal(sessionVal);
        sessionStorage.removeItem("email");
        getDebateDetails(1, sessionVal);
      } else {
        getDebateDetails(1, "");
      }
    }
  }, [props.globalSearch]);

  useEffect(() => {
    setLoader(true);
    if (classificationLoaded === true && debateListLoaded === true) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [classificationLoaded, debateListLoaded]);
  const handleMenuItemClick = (event, index, val) => {
    setSelectedIndex(index);
    type.current = val;
    getDebateDetails(0, "");
  };
  const handleDebateStatusClick = (event, index, val) => {
    if (val === "All") {
      debateStatusVal.current = null;
    } else {
      debateStatusVal.current = index;
    }
    setDebateStatus(index);
    getDebateDetails(0, "");
  };
  const handleSearch = (event) => {
    if (event.target.value) {
      setPage(1);
      setSearchVal(event.target.value);
      getDebateDetails(catId.current, event.target.value);
      dispatch({
        type: "GLOBAL_SEARCH_VAL",
        data: event.target.value,
      });
    } else {
      dispatch({
        type: "GLOBAL_SEARCH_VAL",
        data: "",
      });
    }
  };
  const classifyLoaded = (val) => {
    setClassificationLoaded(val);
  };
  // const handleCreateDebate = () => {
  //     setBtnName("Create");
  //     setDebateObject(null);
  //     setCreateDebate(true);
  // }

  const handleUpdateDebate = (debateObject) => {
    setDebateObject(debateObject);
    setBtnName("Update");
    setCreateDebate(true);
  };
  const handleBack = () => {
    setCreateDebate(false);
    myRef.current.scrollIntoView();
  };
  const handleClassificationList = (list) => {
    setclassificationDetails(list);
  };
  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ open: false });
  };
  const handleDeleteDebate = () => {
    getDebateDetails(0, "");
  };
  const AdvertisementSection = (
    <div className={classes.rightSection}>
      <Advertisement innerRef={myRefAdvertisment} />
    </div>
  );
  return (
    <div className={classes.pageContainer} ref={myRef}>
      {createDebate === true ? (
        <CreateDebate
          BtnName={BtnName}
          handleBack={handleBack}
          classificationDetails={classificationDetails}
          setLoader={setLoader}
          setSnackbarState={setSnackbarState}
          toggle={setCreateDebate}
          reloadDebateDetails={getDebateDetails}
          myRef={myRef}
          debateObject={debateObject}
        />
      ) : (
        <>
          <div className={classes.leftSection}>
            <ClassificationList
              handleClassificationList={handleClassificationList}
              handleDebateDetails={handleDebateDetails}
              classifyLoaded={classifyLoaded}
            />
            <FeaturedDebaters />
          </div>
          <div className={classes.contentRightSection}>
            <div className={classes.contentSection}>
              <div className={classes.flexJCSB}>
                <CustomSearch
                  className={classes.searchWhiteBg}
                  handleSearch={handleSearch}
                  value={searchVal}
                />
                <Box
                  display="flex"
                  m={-0.5}
                  pt={{ xs: 2, sm: 0 }}
                  maxWidth="295px"
                >
                  <Box p={0.5}>
                    <CustomDropdown
                      options={debateStatusOptions}
                      selectedIndex={debateStatus}
                      handleMenuItemClick={handleDebateStatusClick}
                    />
                  </Box>
                  <Box p={0.5}>
                    <CustomDropdown
                      options={options}
                      selectedIndex={selectedIndex}
                      handleMenuItemClick={handleMenuItemClick}
                    />
                  </Box>
                </Box>
              </div>
              {/* <Box mt={2}>
                        <CustomPagination page={page} count={pageTotalCount} handleChange={handleChange} JusCont="flex-end" />
                    </Box> */}
              {debateList && debateList.length > 0 ? (
                debateList.map((list, index) => {
                  return (
                    <CustomDebateCard
                      key={index}
                      debateDetail={list}
                      userId={props && props.user._id}
                      getDebateDetails={getDebateDetails}
                      screen="ActiveDebateList"
                      editDebate={handleUpdateDebate.bind(this, list)}
                      handleDeleteDebate={handleDeleteDebate}
                    />
                    // <ActiveDebateCard
                    // index={index}
                    // classes={classes}
                    // list={list}
                    // liveBtn={false}
                    // getDebateDetails={getDebateDetails}
                    // screen="ActiveDebateList"
                    // userId={props && props.user._id}
                    // />
                    // <Paper key={index} className={classes.paperWhiteParent}>
                    //     <CustomDebateHeader
                    //         debateDetail={list}
                    //         liveBtn={false}
                    //         getDebateDetails={getDebateDetails}
                    //         screen="ActiveDebateList"
                    //         userId={props && props.user._id}
                    //     />
                    //     <div className={classes.seperator}></div>
                    //     <CustomDebateContent key={index} debateDetail={list} />
                    // </Paper>
                  );
                })
              ) : (
                <Box mt={3}>
                  <Typography align="center" variant="h6">
                    No Current Live Debates
                  </Typography>
                </Box>
              )}
              <CustomPagination
                page={page}
                count={pageTotalCount}
                handleChange={handleChange}
                JusCont="flex-end"
              />
            </div>
            {props.is_disable_ads !== 1 &&
            props.currentPackage &&
            props.currentPackage.length === 0 ? (
              <>{AdvertisementSection}</>
            ) : (
              <>
                {props.is_disable_ads === 1 ||
                (props.currentPackage &&
                  props.currentPackage.length > 0 &&
                  props.currentPackage.findIndex((e) => {
                    return e.package_name === "Ad free payment";
                  }) > -1) ? (
                  ""
                ) : (
                  <>{AdvertisementSection}</>
                )}
              </>
            )}
          </div>
          <SnackBar
            open={snackbarState.open}
            message={snackbarState.message}
            severity={snackbarState.severity}
            onClose={onClose}
          />
          {loader && <Loader />}
        </>
      )}
    </div>
  );
};

// export default ActiveDebateList
const mapStateToProps = ({
  loginDetails: { user, auth, is_disable_ads, currentPackage,allBlockedUsers },
  GlobalSearch: globalSearch,
}) => {
  return {
    user,
    auth,
    is_disable_ads,
    currentPackage,
    globalSearch,
    allBlockedUsers
  };
};
export default connect(mapStateToProps)(ActiveDebateList);
