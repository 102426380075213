import {
  Modal,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import axios, { CancelToken, isCancel } from "axios";
import clsx from "clsx";
import moment from "moment";
import Report from "../../Common/ReportAbuse/Report";
import ReactPlayer from "react-player";
import Lockicon from "../../../assets/images/lock.png";
// import Unlockicon from "../../../assets/images/unlock.png";
import publicIcon from "../../../assets/images/public.svg";
import React, { useEffect, useState, useRef, useMemo, Fragment } from "react";
import { connect } from "react-redux";
import {
  useHistory,
  withRouter,
  useLocation,
  Redirect,
} from "react-router-dom";
import Retweet from "../../../assets/images/retweet.svg";
import Thumbs from "../../../assets/images/thumbs.svg";
import loaderImage from "../../../assets/video/bee.gif";
import Share from "../../../assets/images/shareBoxed.svg";
import Facebook from "../../../assets/images/socialIcons/facebook.svg";
import Linkedin from "../../../assets/images/socialIcons/linkedin.svg";
import Twitter from "../../../assets/images/socialIcons/twitter.svg";
import Whatsapp from "../../../assets/images/socialIcons/whatsapp.svg";
import ThumbsYellow from "../../../assets/images/thumbs-yellow.svg";
import DebateDMY from "../../Common/CustomDebateHeaderComponents/DebateDMY";
import CustomSocialFab from "../../Common/CustomSocialFab";
import VurbComment from "../../Common/VurbComment";
import Advertisement from "../../Common/Advertisement";
import { API_Path, numberFormatter } from "../../Common/Const";
import Loader from "../../Common/Loader";
import CreateDebate from "../MyDebates/CreateDebate";
import SnackBar from "../../../components/SnackBar";
import useVurbsSocket from "./useVurbsSocket";
import PdfIcon from "../../../assets/images/pdf.png";
import WordIcon from "../../../assets/images/word.png";
import ExcelIcon from "../../../assets/images/excel.png";
import PptIcon from "../../../assets/images/ppt.png";
import VDotsMenu from "../../../assets/images/VDotsMenu.svg";
import ConfirmationModal from "../../Common/ConfirmationModal";
import CommonMeta from "../../Common/CommonMeta";
import _ from "lodash";
import LiveScheduleBanner from "./LiveScheduleBanner";
// import logoImage from "../../../assets/images/videoLogo.png";
import OpenTokLiveStreamVideo from "../../Common/openTok/OpenTokLiveStreamVideo";
import AudienceAdInjection from "../../Users/LiveDebate/AudienceAdInjection";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import LikeUserCompoent from "../../Common/CommonPostCard/LikeDisLikeUsers";
import CreateVurb from "../Vurbs/CreateVurb";
import CommentImg from "../../../assets/images/comment.svg";
import LiveComment from "../../Common/VurbComment/LiveComment";
import CustomImageSlider from "../../Common/CommonPostCard/CustomImageSlider";
import { AWSfileDelete } from "../../../lib/uriUtils";
const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: "#f6f7f8",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  leftSection: {
    width: "100%",
    padding: "24px",
    backgroundColor: "#fff",
    [theme.breakpoints.up("md")]: {
      padding: "36px",
    },
  },
  rightSection: {
    padding: "24px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexFlow: "column",
      flex: "0 0 400px",
      maxWidth: "400px",
      padding: "36px",
    },
  },
  badgeTeam: {
    padding: "4px 14px",
    fontSize: "12px",
    color: "#fff",
    backgroundColor: "#ffc003",
    marginTop: "10px",
    borderRadius: "6px",
    display: "inline-block",
  },
  smColumnwise: {
    alignItems: "flex-start",
    display: "flex",
    flexWrap: "wrap",
    // [theme.breakpoints.down('sm')]: {
    //     flexFlow: 'column'
    // },
  },
  winnerLosserSection: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
    },
    "& .winner": {
      padding: "4px 14px",
      backgroundColor: "#61cbc9",
      color: "#fff",
      fontSize: "12px",
      display: "inline-block",
      borderRadius: "15px",
      marginBottom: "8px",
    },
    "& h6": {
      fontWeight: "400",
      color: "#758194",
    },
  },
  wlImageContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #acb4c1",
    padding: "10px",
    borderRadius: "42px",
    width: "205px",
    justifyContent: "space-around",
    fontSize: "30px",
    color: "#758194",
    fontWeight: "500",
    "& .winnerImage, & .losserImage": {
      "& > div": {
        margin: 0,
      },
    },
    "& .winnerImage": {
      "& > div": {
        border: "2px solid #ffc003",
      },
    },
  },
  flagView: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    fontSize: "12px",
    position: "absolute",
    top: "12px",
    left: "12px",
    right: "12px",
    // zIndex: '9',
    "& .flag, & .finish, & .view": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .flag": {
      width: 26,
      height: 26,
      backgroundColor: "rgb(49, 50, 54, .55)",
      borderRadius: 6,
    },
    "& .finish": {
      backgroundColor: "rgba(255, 255, 255, .3)",
      borderRadius: "13px",
      padding: "4px 12px 4px 6px",
    },
    "& .finish, & .view": {
      "& img": {
        marginRight: "6px",
      },
    },
  },
  downloadSection: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    background: "rgba(255, 255, 255, .95)",
    zIndex: "1",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
  },
  circleBtn: {
    borderRadius: 30,
    fontWeight: "bold",
  },
  completedVideoSection: {
    position: "relative",
    backgroundColor: "#333",
    borderRadius: "12px",
    display: "flex",
    flexFlow: "column",
    justifyContent: "flex-end",
    overflow: "hidden",
    color: "#fff",
    marginTop: "16px",
    [theme.breakpoints.up("lg")]: {
      minHeight: "400px",
    },
    "& .videoPlayer_player": {
      paddingTop: "75%",
      minWidth: "inherit",
      "& .videoPlayer_common_video": {
        [theme.breakpoints.up("lg")]: {
          top: "19.5%",
        },
      },
    },
    "&.novideo:before": {
      content: '"No video to play"',
      position: "absolute",
      top: "0",
      left: "0",
      color: "#fff",
      right: "0",
      bottom: "0",
      display: "flex",
      opacity: ".25",
      fontSize: "24px",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "415px",
    },
    "&.videoGeneration:before": {
      content: '"Please wait. Recording is in-progress..."',
      position: "absolute",
      top: "0",
      left: "0",
      color: "#fff",
      right: "0",
      bottom: "0",
      display: "flex",
      opacity: ".25",
      fontSize: "24px",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "415px",
    },
    "& .wmp-spinner": {
      backgroundImage: "url(" + loaderImage + ")",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      maxWidth: "420px",
      maxHeight: "117px",
      height: "100%",
      width: "100%",
      transform: "translate(-50%, -50%)",
      margin: "0",
      "& .wmp-spinner-container": {
        display: "none",
      },
    },
    "& .fullscreen, & video": {
      width: "100% !important",
      height: "100% !important",
      margin: "0",
    },
    "& .robotTextVideo": {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      bottom: "80px",
      left: "20px",
      right: "20px",
    },
  },
  flexJCSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  tabsNav: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: "1px solid #cdd1d8",
    "& button": {
      padding: "0",
      minWidth: "inherit",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#313236",
      fontWeight: "600",
      fontFamily: "Poppins",
      marginRight: "42px",
    },
  },
  liveStatus: {
    padding: "4px 10px 4px 10px",
    borderRadius: "13px",
    backgroundColor: "#e8eaed",
    color: "#313236",
    fontSize: "12px",
    lineHeight: "1.5",
    position: "relative",
    display: "flex",
    alignItems: "center",
    maxWidth: "110px",
    "& span": {
      width: "6px",
      height: "6px",
      boxShadow: "0 0 0 3px #fff",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "10px",
      padding: "0",
      "&.Live": {
        backgroundColor: "#f52f2f",
      },
    },
  },
  activeStatus: {
    position: "absolute",
    right: "22px",
    top: "0px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "26px",
    },
  },
  stickySection: {
    backgroundColor: "#f6f7f8",
    // padding: "36px 36px 0",
    zIndex: 10,
    [theme.breakpoints.up("md")]: {
      position: "sticky",
      top: "0",
      height: "calc(100vh - 133px)",
      display: "flex",
      flexFlow: "column",
      overflow: "auto",
    },
    "& .chatContainer": {
      width: "100%",
    },
    "& h4": {
      marginTop: "0",
      marginBottom: "10px",
    },
  },
  flexRow: {
    display: "flex",
    flexFlow: "row",
  },
  headingContainer: {
    position: "relative",
    borderBottom: "1px solid #cdd1d8",
    [theme.breakpoints.down("md")]: {
      flexFlow: "column",
    },
  },
  maxShare: {
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      top: 0,
      right: 0,
    },
  },
  debateSubHeader: {
    margin: "8px -7px",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#758194",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > span": {
      margin: "2px 7px",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("md")]: {
        marginBottom: "6px",
      },
      "&:first-child": {
        paddingRight: "14px",
        // borderRight: '1px solid #cdd1d8'
      },
      "& svg": {
        verticalAlign: "text-top",
        width: "15px",
        height: "15px",
        marginRight: "5px",
      },
    },
  },
  paper: {
    width: "30%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    "& img": {
      width: "100%",
      maxHeight: "700px",
    },
  },
  videoLogo: {
    position: "absolute",
    right: "5%",
    bottom: "20%",
    width: "4em",
    opacity: ".75",
  },

  closeButton: {
    position: "absolute",
    color: "#ffc003",
    top: "4px",
    borderRadius: "14px",
    fontSize: "27px",
    right: "160px",
  },
  ModelBackground: {
    width: "60%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    "& img": {
      width: "100%",
      maxHeight: "700px",
    },
  },
  slider: {
    " & .slick-prev:before": {
      fontSize: "50px",
      position: "absolute",
      right: "0%",
    },

    " & .slick-next:before": {
      fontSize: "50px",
    },
    " & .slick-track": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

const VurbsDetail = (props) => {
  const classes = useStyles();
  const [vurbDetails, setVurbDetails] = React.useState();
  const path =
    vurbDetails && vurbDetails.share_link ? vurbDetails.share_link : null;
  const actions = useMemo(() => {
    return [
      {
        icon: <img alt="" src={Facebook} />,
        name: "Facebook",
        href: !!path
          ? "http://www.facebook.com/sharer/sharer.php?s=100&p[url]=" +
            encodeURIComponent(path)
          : null,
      },
      {
        icon: <img alt="" src={Twitter} />,
        name: "Twitter",
        href: !!path
          ? "https://twitter.com/intent/tweet?url=" + encodeURIComponent(path)
          : null,
      },
      {
        icon: <img alt="" src={Linkedin} />,
        name: "Linkedin",
        href: !!path
          ? "https://www.linkedin.com/share?url=" + encodeURIComponent(path)
          : null,
      },
      {
        icon: <img alt="" src={Whatsapp} />,
        name: "Whatsapp",
        href: !!path ? "whatsapp://send?text=" + path : null,
      },
    ];
  }, [vurbDetails && vurbDetails.share_link ? vurbDetails.share_link : null]);
  // const [startTime, setStartTime] = React.useState("");
  const [createDebate, setCreateDebate] = React.useState(false);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const myRef = useRef(null);
  const [likeArray, setArray] = React.useState({});
  const [playing, SetPlaying] = useState(false);
  const [liveRecordedVideoPlaying, SetLiveRecordedVideoPlaying] =
    useState(false);
  const [classificationDetails, setclassificationDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [vurbDeleted, setVurbDeleted] = useState(false);
  const [img, setImage] = useState("");
  //   const accessToken = props.auth.accessToken;
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [loader, setLoader] = useState(false);
  const [vurbListLoaded, setVurbListLoaded] = useState(false);
  const [edit, setEdit] = useState(false);
  var historyVurbObj = !!location.state
    ? location.state.postObj && location.state.postObj.post
    : { _id: params.get("id") };
  const timerRef = useRef(null);
  const sliderref = useRef();
  const [sliderindex, setSliderIndex] = useState(null);
  const [enableDownload, setEnableDownload] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [timer, setTimer] = useState("00:00:00");
  const cancelFileUpload = useRef(null);
  useEffect(() => {
    getVurbDetail();
  }, [JSON.stringify(historyVurbObj)]);
  const {
    UpdateLike,
    vurb_socket_data,
    UpdateComment,
    commentDataList,
    reload,
  } = useVurbsSocket({ roomName: historyVurbObj && historyVurbObj._id });
  useEffect(() => {
    setArray(vurb_socket_data);
  }, [JSON.stringify(vurb_socket_data)]);
  useEffect(() => {
    handleClassificationCount();
  }, []);
  useEffect(() => {
    setLoader(true);
    if (vurbListLoaded === true) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [vurbListLoaded]);
  useEffect(() => {
    // if (vurbDetails && vurbDetails.created_at) {
    //   let d = new Date(vurbDetails.created_at);
    //   let dt = d.toString();
    //   let startTime = dt.split(" ");
    //   // setStartTime(startTime[4]);
    // }
    if (
      vurbDetails &&
      vurbDetails.post_type === 4 &&
      vurbDetails.live
      // && new Date(vurbDetails.live.start_time) <= new Date()
    ) {
      clearTimer(getDeadTime(vurbDetails));
    }
  }, [vurbDetails]);
  const getDeadTime = (vurbDetails) => {
    let deadline = new Date(vurbDetails.live.end_time);
    // deadline.setSeconds(deadline.getSeconds() + 10);
    return deadline;
  };
  const clearTimer = (e) => {
    // // If you adjust it you should also need to
    // // adjust the Endtime formula we are about
    // // to code next
    // setTimer("00:00:10");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (timerRef.current) clearInterval(timerRef.current);
    if (
      vurbDetails &&
      vurbDetails.post_type === 4 &&
      vurbDetails.live
      // && new Date(vurbDetails.live.end_time) >= new Date()
    ) {
      const intervalid = setInterval(() => {
        startTimer(e);
      }, 1000);
      timerRef.current = intervalid;
    }
  };
  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const getVurbDetail = async () => {
    if (historyVurbObj && historyVurbObj._id) {
      var vurbId = historyVurbObj._id;
      axios
        .get(API_Path + `api/vurb/get/vurb_id/${vurbId}`, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            if (response.data !== null && response.data.result !== null) {
              if (response.data.result.length > 0) {
                setVurbDetails(response.data.result[0]);
                setVurbListLoaded(true);
              } else {
                history.push({
                  pathname: "/vurbs",
                  state: {
                    open: true,
                    message: "The Selected Vurb Not Exists",
                    severity: "error",
                  },
                });
              }
              // GetDebateVideoAdList();
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      history.push({
        pathname: "/vurbs",
        state: {
          open: true,
          message: "The Selected Vurb Not Exists",
          severity: "error",
        },
      });
    }
  };
  const handleDelete = () => {
    let id = historyVurbObj._id;
    var deletePost = API_Path + "api/vurb/delete?id=" + id;
    axios
      .delete(deletePost, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          if (historyVurbObj && Object.keys(historyVurbObj).length > 0) {
            // Vurbs Image Delete
            if (
              historyVurbObj.vurb_imgs &&
              historyVurbObj.vurb_imgs.length > 0
            ) {
              for (const data of historyVurbObj.vurb_imgs) {
                AWSfileDelete(data.url, "images",props.hashed);
              }
            }
            // Vurbs Video Delete
            if (historyVurbObj.video && historyVurbObj.video.length > 0) {
              for (const video_url of historyVurbObj.video) {
                console.log("video_url", video_url);
                AWSfileDelete(video_url, "video",props.hashed);
              }
            }
          }
          setOpenDialog(false);
          setSnackbarState({
            open: true,
            message: "Deleted Successfully",
            severity: "success",
          });
          setVurbDeleted(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleTurn = async () => {
    let id = historyVurbObj._id;
    await axios
      .post(
        API_Path + "api/vurb/comment/show_or_hide",
        {
          id: id,
        },
        {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        }
      )
      .then((res) => {
        if (res && res.data && res.data.status === "SUCCESS") {
          if (vurbDetails.comment_show) {
            setOpenDialog(false);
            setSnackbarState({
              open: true,
              message: " Turned Off Comments Successfully",
              severity: "success",
            });
          } else {
            setOpenDialog(false);
            setSnackbarState({
              open: true,
              message: " Turned On Comments Successfully",
              severity: "success",
            });
          }
          getVurbDetail();
        }
        setAnchorEl(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handlePlay = () => {
    SetPlaying(true);
  };
  const handleReportOption = () => {
    setAnchorEl(null);
  };
  const handleSnackbarReport = () => {
    setSnackbarState({
      open: true,
      message: "Reported Successfully",
      severity: "success",
    });
  };
  const handleLiveRecordedVideoPlay = () => {
    SetLiveRecordedVideoPlaying(true);
  };
  const handleBack = () => {
    setCreateDebate(false);
    myRef.current.scrollIntoView();
  };
  const onClose = (event, reason) => {
    setSnackbarState({ open: false });
  };
  const onSuccess = (msg) => {
    setSnackbarState({
      open: true,
      message: msg,
      severity: "success",
    });
  };
  const vurbEdit = (data) => {
    setSnackbarState({
      open: true,
      message: data.message,
      severity: "success",
    });
    setEdit(false);
    setVurbDetails({
      ...vurbDetails,
      content: data.result.content,
      type: data.result.type,
      post_type: data.result.post_type,
      docs: data.result.docs,
      post_img: data.result.post_img,
      origin_content: data.result.origin_content,
      vurb_imgs: data.result.vurb_imgs,
      video: data.result.video,
      location: data.result.location,
      view_count: data.result.view_count,
      dislike: data.result.dislike,
      like: data.result.like,
      hashtags: data.result.hashtags,
      comment_show: data.result.comment_show,
      comments_count: data.result.comments_count,
      mentionIds: data.result.mentionIds,
      share_url_meta_info: data.result.share_url_meta_info,
      device: "web",
      live: {
        description: data.result.live.description,
        start_time: data.result.live.start_time,
        title: data.result.live.title,
        status: data.result.live.status,
        event_type: data.result.live.event_type,
      },
    });
  };
  const modalClose = () => {
    setEdit(false);
  };
  const handleOpen = (src) => {
    let index = vurbDetails.post_img.findIndex((x) => x === src);
    setSliderIndex(index);
    setTimeout(() => {
      setOpen(true);
      sliderref && sliderref.current.slickGoTo(index);
    }, 1);
    setImage(vurbDetails.post_img);
  };
  const handleClose = () => {
    setOpen(false);
    setImage("");
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleClickOpen = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleClassificationCount = () => {
    let URL = API_Path + "api/debateClassification/getbypage";
    let inputObj = {
      inputObject: {
        screen: "myDebates",
        userId: props.user._id,
      },
    };
    axios
      .post(URL, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        setclassificationDetails(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const Vurb_like = (type) => {
    UpdateLike({
      _id: historyVurbObj._id,
      type,
      user_id: props.user._id,
    });
  };
  const handleShareCount = (type) => {
    try {
      let inputObj = {
        inputObject: {
          id: historyVurbObj._id,
          user_id: props.user._id,
        },
      };
      axios
        .post(API_Path + "api/vurb/share", inputObj, {
          headers: {
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((res) => {
          if (res && res.data && res.data.status === "SUCCESS") {
            let { share, view_count = 0 } = res.data.result,
              Obj = { ...vurbDetails, share: share, view_count: view_count };
            setVurbDetails(Obj);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (e) {
      console.log("error", e);
    }
  };
  const handleButtonCancel = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current("Download Canceled");
      setProgressValue(0);
      setEnableDownload(false);
    }
  };
  const handleSaveVideo = async () => {
    handleCloseMenu();
    setEnableDownload(true);
    let { post = {} } = props;
    var savePost = process.env.REACT_APP_SAVE_VIDEO_BASEURL + "/getVideo";
    let path =
      vurbDetails.vurb_video.outputObj !== null
        ? `${process.env.REACT_APP_VIDEO_BASEURL}${vurbDetails.vurb_video.outputObj.key}`
        : vurbDetails.vurb_video.sourceURL;
    await axios({
      method: "post",
      url: savePost,
      data: {
        inputPath: path,
      },
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        // console.log(percentCompleted)
        setProgressValue(percentCompleted);
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    })
      .then((response) => {
        if (response !== null && response.data !== null) {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "video/mp4" })
          );
          let title =
            vurbDetails.vurb_video.outputObj !== null
              ? vurbDetails.vurb_video.outputObj.key
              : vurbDetails.vurb_video.sourceURL.split("/")[
                  vurbDetails.vurb_video.sourceURL.split("/").length - 1
                ];
          const link = document.createElement("a");
          link.style = "display: none";
          document.body.appendChild(link);
          link.href = url;
          link.download = title;
          link.click();
          setSnackbarState({
            open: true,
            message: "File downloaded",
            severity: "Success",
          });
          setEnableDownload(false);
        } else {
          setSnackbarState({
            open: true,
            message: "Error in file download",
            severity: "error",
          });
          setEnableDownload(false);
        }
      })
      .catch((error) => {
        if (isCancel(error)) {
          setSnackbarState({
            open: true,
            message: error.message,
            severity: "error",
          });
        } else {
          setSnackbarState({
            open: true,
            message: "Error in file download",
            severity: "error",
          });
        }
        console.error(error);
      });
  };
  const AdvertisementSection = (
    <div className={classes.rightSection}>
      <Advertisement />
    </div>
  );
  const actionSectionHeader = (
    <Box display="flex">
      <IconButton
        style={{ margin: "0px 12px", padding: "0px", height: "36px" }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img alt="" src={VDotsMenu} />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {vurbDetails &&
          props.user._id === (vurbDetails && vurbDetails.user_id._id) &&
          vurbDetails.post_type !== 4 &&
          (!!vurbDetails.isPromoted
            ? vurbDetails.promotionExpiryDate &&
              new Date(vurbDetails.promotionExpiryDate) < new Date()
            : true) && (
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: "/payment",
                  state: { type: "Promote Vurb", id: vurbDetails._id },
                });
              }}
              style={{ color: "#FFC003" }}
            >
              {" "}
              Promote Vurb{" "}
            </MenuItem>
          )}
        {vurbDetails &&
        // props.user._id === (vurbDetails && vurbDetails.user_id._id) &&
        new Date(vurbDetails.live.start_time) <= new Date() &&
        new Date(vurbDetails.live.end_time) >= new Date() ? (
          ""
        ) : vurbDetails && vurbDetails.live.status === 2 ? (
          ""
        ) : props.user._id !== (vurbDetails && vurbDetails.user_id._id)?"": (
          <MenuItem
            onClick={() => {
              setEdit(true);
              handleCloseMenu();
            }}
          >
            {" "}
            Edit
          </MenuItem>
        )}
        {vurbDetails && vurbDetails.post_type === 3 && (
          <MenuItem onClick={handleSaveVideo}>Save Video</MenuItem>
        )}
        {props.user._id === (vurbDetails && vurbDetails.user_id._id) && (
          <MenuItem onClick={handleTurn}>
            {" "}
            {vurbDetails &&
              (vurbDetails.comment_show
                ? "Turn off commenting"
                : "Turn on commenting")}
          </MenuItem>
        )}

        {vurbDetails &&
        props.user._id === (vurbDetails && vurbDetails.user_id._id) &&
        (new Date(vurbDetails.live.start_time) <= new Date() &&
        new Date(vurbDetails.live.end_time) >= new Date() ? (
          " "
        ) : (
          <MenuItem onClick={handleClickOpen}> Delete</MenuItem>
        ))}
        {vurbDetails && props.user._id !== vurbDetails.user_id._id && (
          <Report
            showType="Menu"
            type="Reporting Vurb"
            primaryKey={vurbDetails && vurbDetails._id}
            handleReportOption={handleReportOption}
            handleSnackbarReport={handleSnackbarReport}
          />
        )}
      </Menu>
    </Box>
  );
  const DocmentImageRender = (docs) => {
    let docmentname = docs[0].split("__")[docs[0].split("__").length - 1];
    let type = docmentname.split(".")[docmentname.split(".").length - 1];
    let src =
      type === "pdf"
        ? PdfIcon
        : type === "docx"
        ? WordIcon
        : type === "pptx"
        ? PptIcon
        : ExcelIcon;
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            {" "}
            {
              <img
                alt=""
                src={src}
                style={{ width: "80px", cursor: "pointer" }}
                onClick={() => window.open(docs)}
              />
            }
          </div>
          <div>
            <span>{docmentname}</span>
          </div>
        </div>
      </Fragment>
    );
  };
  const onRedirect = (id) => {
    let link = document.createElement("a");
    link.href = `/friendsProfilePage/${_.get(
      vurbDetails,
      "user_id._id",
      null
    )}`;
    link.target = "_blank";
    link.click();
  };
  const UrlRedirect = (url) => {
    let link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.click();
  };
  return (
    <>
      {vurbDeleted ? <Redirect to="/vurbs" /> : ""}
      <div className={classes.pageContainer} ref={myRef}>
        {createDebate ? (
          <CreateDebate
            BtnName={"Create"}
            handleBack={handleBack}
            classificationDetails={classificationDetails}
            setLoader={setLoader}
            setSnackbarState={setSnackbarState}
            toggle={setCreateDebate}
            style={{ overflow: "hidden" }}
            // reloadDebateDetails={getDebateDetails}
            myRef={myRef}
            debateObject={null}
            closeModal={props && props.closeModal}
          />
        ) : (
          <>
            <div className={classes.leftSection}>
              <div className={clsx(classes.flexRow, classes.headingContainer)}>
                <div style={{ position: "relative" }}>
                  <DebateDMY
                    listDMY={{
                      image:
                        vurbDetails &&
                        vurbDetails.user_id &&
                        vurbDetails.user_id.profile_image
                          ? vurbDetails.user_id.profile_image
                          : "",
                      // date:vurbDetails?`${moment(vurbDetails.created_at).format("MMM D, YYYY")} at ${moment(vurbDetails.created_at).format(" h:mm a")}`:'-',
                    }}
                    normalDMY={false}
                  />
                  {vurbDetails &&
                    vurbDetails.user_id &&
                    !!vurbDetails.user_id.badge_status && (
                      <span
                        className={classes.activeStatus}
                        title="Verified User/Public Figure"
                      >
                        <VerifiedUserRoundedIcon />
                      </span>
                    )}
                </div>
                <Box width="100%" style={{ display: "flex" }}>
                  <div
                    className={classes.flexJCSB}
                    style={{
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div>
                      {vurbDetails &&
                        (vurbDetails.post_type !== 4 ? (
                          <>
                            <h2
                              style={{ marginBottom: "0px", marginTop: "6px" }}
                            >
                              {vurbDetails && vurbDetails.user_id && (
                                <span
                                  className="pointer"
                                  onClick={() => onRedirect()}
                                >
                                  {vurbDetails.user_id.full_name}
                                </span>
                              )}{" "}
                              {vurbDetails && vurbDetails.location && (
                                <span
                                  style={{
                                    fontWeight: "lighter",
                                    fontSize: "18px",
                                  }}
                                >
                                  {" "}
                                  is in {vurbDetails.location.name}
                                </span>
                              )}
                            </h2>
                          </>
                        ) : (
                          <h2 style={{ marginBottom: "0px", marginTop: "6px" }}>
                            {vurbDetails.live.title}{" "}
                          </h2>
                        ))}
                      {vurbDetails &&
                        vurbDetails.post_type !== 4 &&
                        vurbDetails.user_id &&
                        vurbDetails.user_id.user_tagging && (
                          <span>
                            {" "}
                            <p
                              style={{
                                margin: "0px",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "gray",
                              }}
                            >
                              @{_.get(vurbDetails, "user_id.user_tagging", "")}
                            </p>
                          </span>
                        )}
                    </div>
                    <div
                      className={classes.debateSubHeader}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        margin: "4px 0px 0px 0px",
                      }}
                    >
                      <div
                        style={{
                          paddingRight: "14px",
                          borderRight: " 1px solid #cdd1d8",
                        }}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {vurbDetails && !!vurbDetails.type ? (
                            <img
                              alt=""
                              src={Lockicon}
                              style={{ width: "15px", marginRight: "5px" }}
                            />
                          ) : (
                            <img
                              alt=""
                              src={publicIcon}
                              style={{ width: "15px", marginRight: "5px" }}
                            />
                          )}
                          {vurbDetails && !!vurbDetails.type
                            ? "Private"
                            : "Public"}
                        </span>
                      </div>
                      {vurbDetails && vurbDetails.post_type === 4 && (
                        <span>
                          Hosted by{" "}
                          <span
                            style={{
                              color: "#1d6cba",
                              margin: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => onRedirect()}
                          >
                            {vurbDetails.user_id.full_name}
                          </span>
                          {vurbDetails && vurbDetails.location && (
                            <span> is in {vurbDetails.location.name}</span>
                          )}
                        </span>
                      )}
                      <div
                        style={{
                          padding: " 14px",
                        }}
                      >
                        {vurbDetails
                          ? `${moment(vurbDetails.created_at).format(
                              "MMM D, YYYY"
                            )} at ${moment(vurbDetails.created_at).format(
                              " h:mm a"
                            )}`
                          : "-"}
                      </div>

                      {/* <span><Schedule />
                      {startTime}
                    </span> */}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    {vurbDetails &&
                      vurbDetails.post_type === 4 &&
                      (vurbDetails.live.status === 1 ||
                        (new Date(vurbDetails.live.start_time) <= new Date() &&
                          new Date(vurbDetails.live.end_time) >= new Date() &&
                          timer !== "00:00:00")) &&
                      vurbDetails.live.status !== 2 && (
                        <div
                          style={{ display: "flex", alignItems: "flex-start" }}
                        >
                          <div className={classes.liveStatus}>
                            <>
                              <span className="Live"></span>LIVE
                            </>
                          </div>
                        </div>
                      )}
                    {(props.user._id ===
                      (vurbDetails && vurbDetails.user_id._id) ||
                      (vurbDetails && vurbDetails.post_type === 3)) &&
                      actionSectionHeader}
                    {vurbDetails && !vurbDetails.type && (
                      <div className={classes.maxShare}>
                        <CustomSocialFab
                          actions={actions}
                          direction="down"
                          icon={Share}
                          vurbId={_.get(vurbDetails, "_id", false)}
                          vurbs={true}
                          handleShareCount={handleShareCount}
                        />
                      </div>
                    )}
                  </div>
                </Box>
              </div>
              <div className="position-relative">
                {enableDownload === true ? (
                  <Box className={classes.downloadSection}>
                    <Box position="relative" display="inline-flex">
                      <CircularProgress
                        variant="determinate"
                        size={80}
                        value={progressValue}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <h3>{progressValue}</h3>
                      </Box>
                    </Box>
                    <p>Video Downloading...</p>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.circleBtn}
                      onClick={handleButtonCancel}
                    >
                      Cancel Download
                    </Button>
                  </Box>
                ) : (
                  ""
                )}
                <div
                  style={
                    vurbDetails && vurbDetails.live.description
                      ? { margin: "14px 0px" }
                      : {}
                  }
                  dangerouslySetInnerHTML={{
                    __html:
                      (vurbDetails && vurbDetails.content) ||
                      (vurbDetails && vurbDetails.live.description),
                  }}
                ></div>
                {/* {vurbDetails && vurbDetails.location &&
                  Object.keys(vurbDetails.location).length > 0 && (
                    <div style={{ display: "flex", alignItems: "center", marginTop:'6px' }}>
                      <img style={{ width: "24px",marginRight:"4px" }} src={Location} alt="" />
                      <span>{vurbDetails.location.name}</span>
                    </div>
                  )} */}
                {/* Live Stream User view */}
                {vurbDetails &&
                  vurbDetails.post_type === 4 &&
                  vurbDetails.user_id._id === props.user._id &&
                  new Date(vurbDetails.live.start_time) <= new Date() &&
                  new Date(vurbDetails.live.end_time) >= new Date() &&
                  vurbDetails.live.status === 0 &&
                  timer !== "00:00:00" && (
                    <div style={{ postion: "relative" }}>
                      {timer}
                      <OpenTokLiveStreamVideo vurbObj={vurbDetails} />
                    </div>
                  )}
                {vurbDetails &&
                  vurbDetails.post_type === 4 &&
                  new Date(vurbDetails.live.start_time) > new Date() && (
                    <LiveScheduleBanner
                      startTime={vurbDetails.live.start_time}
                    />
                  )}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                    rowGap: "24px",
                  }}
                >
                  {/* Live Stream Audience view */}
                  {vurbDetails &&
                    vurbDetails.post_type === 4 &&
                    vurbDetails.live.status === 1 &&
                    // vurbDetails.user_id._id !== props.user._id &&
                    new Date(vurbDetails.live.start_time) <= new Date() &&
                    new Date(vurbDetails.live.end_time) >= new Date() &&
                    timer !== "00:00:00" &&
                    vurbDetails.live.broadcast_channel_url != null && (
                      <AudienceAdInjection
                        endedaudienceview={() => {
                          window.location.href = "/vurbs";
                        }}
                        videoUrl={vurbDetails.live.broadcast_channel_url}
                        adsList={[]}
                        // adsList={audience_ads}
                      />
                    )}
                  {/* After Live Stream recorded video have to play */}
                  {vurbDetails &&
                    vurbDetails.post_type === 4 &&
                    new Date(vurbDetails.live.start_time) <= new Date() &&
                    // new Date(vurbDetails.live.end_time) < new Date() &&
                    (timer === "00:00:00" || vurbDetails.live.status === 2) && (
                      <div style={{ position: "relative" }}>
                        <ReactPlayer
                          className="react-player"
                          url={
                            !!vurbDetails.live.meta_info
                              ? `${process.env.REACT_APP_VIDEO_BASEURL}${vurbDetails.live.meta_info.key}`
                              : vurbDetails.live.recorded_url
                          }
                          playing={liveRecordedVideoPlaying}
                          width="100%"
                          // height='100%'
                          controls
                          light={false}
                          config={{
                            file: {
                              forceHLS: false,
                            },
                          }}
                          onStart={handleLiveRecordedVideoPlay}
                          onPlay={handleLiveRecordedVideoPlay}
                        />
                      </div>
                    )}

                  {vurbDetails &&
                    !!vurbDetails.vurb_imgs.length &&
                    vurbDetails.vurb_imgs.map((img, index) => {
                      let imgUrl =
                        img.url.split("/").length > 4 &&
                        img.url.includes("tenor")
                          ? img.url
                          : `${process.env.REACT_APP_VIDEO_BASEURL}${
                              img.url.split("/")[img.url.split("/").length - 1]
                            }`;
                      return (
                        <img
                          alt=""
                          key={index}
                          src={imgUrl}
                          style={{
                            width: "300px",
                            maxHeight: "300px",
                            cursor: "pointer",
                            objectFit: "cover",
                          }}
                          onClick={() => handleOpen(imgUrl.url)}
                        />
                      );
                    })}
                </div>
                {vurbDetails &&
                  vurbDetails.vurb_video &&
                  vurbDetails.vurb_video.sourceURL && (
                    // {vurbDetails && !!vurbDetails.video.length && (
                    <div style={{ position: "relative" }}>
                      <ReactPlayer
                        className="react-player"
                        // url={vurbDetails.video[0]}
                        url={
                          vurbDetails.vurb_video.outputObj !== null
                            ? `${process.env.REACT_APP_VIDEO_BASEURL}${vurbDetails.vurb_video.outputObj.key}`
                            : vurbDetails.vurb_video.sourceURL
                        }
                        playing={playing}
                        width="100%"
                        // height='100%'
                        controls
                        light={false}
                        config={{
                          file: {
                            forceHLS: false,
                          },
                        }}
                        onStart={handlePlay}
                        onPlay={handlePlay}
                      />
                      {/* <img
                      alt="Logo"
                      src={logoImage}
                      className={classes.videoLogo}
                    /> */}
                    </div>
                  )}
                {vurbDetails &&
                  vurbDetails.docs &&
                  vurbDetails.docs.length > 0 &&
                  DocmentImageRender(vurbDetails.docs)}
                {vurbDetails &&
                  vurbDetails.location &&
                  Object.keys(vurbDetails.location).length > 0 &&
                  !(
                    (vurbDetails.vurb_imgs &&
                      vurbDetails.vurb_imgs.length > 0) ||
                    !!vurbDetails.video.length ||
                    (vurbDetails.docs && !!vurbDetails.docs.length) ||
                    vurbDetails.post_type === 4
                  ) && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <img
                        src={`https://maps.googleapis.com/maps/api/staticmap?center=${vurbDetails.location.lat},${vurbDetails.location.lng}&zoom=13&size=520x300&markers=color:red%7C${vurbDetails.location.lat},${vurbDetails.location.lng}&key=AIzaSyCsXeKdTbHEM-GC36fI5dqrBqVBAhiKpc4`}
                        alt="staticmap"
                      />
                    </div>
                  )}
                {vurbDetails &&
                  vurbDetails.share_url_meta_info &&
                  vurbDetails.share_url_meta_info !== null && (
                    <CommonMeta
                      data={vurbDetails.share_url_meta_info}
                      redirect={UrlRedirect}
                    />
                  )}
              </div>
              <Box className={classes.footer}>
                <div style={{ display: "flex", margin: "12px 12px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "18px",
                    }}
                  >
                    <img
                      alt=""
                      src={
                        _.get(
                          likeArray,
                          "like_users",
                          _.get(vurbDetails, "like_users", [])
                        ).includes(props.user._id)
                          ? ThumbsYellow
                          : Thumbs
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => Vurb_like("like")}
                    />{" "}
                    <h5 style={{ margin: "0 0 0 10px" }}>
                      <LikeUserCompoent
                        count={numberFormatter(
                          _.get(
                            likeArray,
                            "like",
                            _.get(vurbDetails, "like", 0)
                          )
                        )}
                        active={0}
                        auth={props.auth}
                        id={historyVurbObj && historyVurbObj._id}
                      />
                    </h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "18px",
                    }}
                  >
                    <img
                      alt=""
                      // src={Thumbs}
                      src={
                        _.get(
                          likeArray,
                          "dislike_users",
                          _.get(vurbDetails, "dislike_users", [])
                        ).includes(props.user._id)
                          ? ThumbsYellow
                          : Thumbs
                      }
                      //   onClick={onDisLikesClick}
                      style={{ cursor: "pointer", transform: "rotate(180deg)" }}
                      onClick={() => Vurb_like("dislike")}
                    />{" "}
                    <h5 style={{ margin: "0 0 0 10px" }}>
                      <LikeUserCompoent
                        count={numberFormatter(
                          _.get(
                            likeArray,
                            "dislike",
                            _.get(vurbDetails, "dislike", 0)
                          )
                        )}
                        active={1}
                        auth={props.auth}
                        id={historyVurbObj && historyVurbObj._id}
                      />
                    </h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "18px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "18px",
                      }}
                    >
                      <img
                        alt=""
                        src={CommentImg}
                        style={{ width: "36px", height: "20px" }}
                      />{" "}
                      <h5 style={{ margin: "0 0 0 10px" }}>
                        {numberFormatter(
                          _.get(
                            likeArray,
                            "comments_count",
                            _.get(vurbDetails, "comment_count", 0)
                          )
                        )}
                      </h5>
                    </div>
                    <img alt="" src={Retweet} />{" "}
                    <h5 style={{ margin: "0 0 0 10px" }}>
                      {numberFormatter(
                        _.get(
                          likeArray,
                          "share",
                          _.get(vurbDetails, "share", 0)
                        )
                      )}
                    </h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "18px",
                    }}
                  >
                    <VisibilityOutlinedIcon />{" "}
                    <h5 style={{ margin: "0 0 0 10px" }}>
                      {numberFormatter(
                        _.get(
                          likeArray,
                          "view_count",
                          _.get(vurbDetails, "view_count", 0)
                        )
                      )}
                    </h5>
                  </div>
                </div>
              </Box>
              {vurbDetails &&
                !(
                  new Date(vurbDetails.live.start_time) <= new Date() &&
                  new Date(vurbDetails.live.end_time) >= new Date() &&
                  (vurbDetails.live.status === 0 ||
                    vurbDetails.live.status === 1)
                ) && (
                  <div>
                    <VurbComment
                      vurbObject={vurbDetails}
                      vurbId={historyVurbObj && historyVurbObj._id}
                      UpdateComment={UpdateComment}
                      commentDataList={commentDataList}
                      reload={reload}
                      createDebate={setCreateDebate}
                      onSuccess={onSuccess}
                      creator={
                        vurbDetails &&
                        vurbDetails.user_id._id === props.user._id
                      }
                      setLoader={setLoader}
                    />
                  </div>
                )}
            </div>
            {vurbDetails &&
            vurbDetails.post_type === 4 &&
            (vurbDetails.live.status === 0 || vurbDetails.live.status === 1) &&
            new Date(vurbDetails.live.start_time) <= new Date() &&
            new Date(vurbDetails.live.end_time) >= new Date() &&
            timer !== "00:00:00" ? (
              <div className={classes.rightSection}>
                <div className={classes.stickySection}>
                  <h4>Live Comments</h4>
                  {vurbDetails ? (
                    <LiveComment
                      vurbId={historyVurbObj._id}
                      UpdateComment={UpdateComment}
                      onSuccess={onSuccess}
                      commentDataList={commentDataList}
                      reload={reload}
                      host={props.user._id === vurbDetails.user_id._id}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : props.is_disable_ads !== 1 &&
              props.currentPackage &&
              props.currentPackage.length === 0 ? (
              <>{AdvertisementSection}</>
            ) : (
              <>
                {props.is_disable_ads === 1 ||
                (props.currentPackage &&
                  props.currentPackage.length > 0 &&
                  props.currentPackage.findIndex((e) => {
                    return e.package_name === "Ad free payment";
                  }) > -1) ? (
                  ""
                ) : (
                  <>{AdvertisementSection}</>
                )}
              </>
            )}
          </>
        )}
        <SnackBar
          open={snackbarState.open}
          message={snackbarState.message}
          severity={snackbarState.severity}
          onClose={onClose}
        />
        {loader && <Loader />}
        <ConfirmationModal
          openDialog={openDialog}
          modalHeading="Message"
          handleDialogClose={handleDialogClose}
          handleDelete={handleDelete}
          modalMessage="Are you sure to delete the vurb?"
          btnCancelName="No"
          btnNoConfirmName="Yes"
          maxWidth="xs"
        />
        {open && (
          <CustomImageSlider
            user={props.user}
            auth={props.auth}
            open={open}
            img={img}
            handleClose={handleClose}
            sliderref={sliderref}
            sliderindex={sliderindex}
            post={vurbDetails}
            setLoader={setLoader}
          />
        )}
        <Modal open={edit} onClose={() => setEdit(false)}>
          <CreateVurb
            onSuccess={vurbEdit}
            vurbpost={true}
            close={modalClose}
            post={vurbDetails}
          />
        </Modal>
      </div>
    </>
  );
};

// export default VurbsDetailsPage
const mapStateToProps = ({
  loginDetails: { user, auth, is_disable_ads, currentPackage, hashed },
}) => {
  return {
    user,
    auth,
    is_disable_ads,
    currentPackage,
    hashed
  };
};
export default withRouter(connect(mapStateToProps)(VurbsDetail));
