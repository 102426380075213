import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import clsx from 'clsx';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import * as yup from 'yup';
import SnackBar from "../../../components/SnackBar";
import Advertisement from '../../Common/Advertisement';
import { API_Path } from '../../Common/Const/index';
import FormikInput from '../../Common/FormikComponents/FormikInput';
import Loader from "../../Common/Loader";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        backgroundColor: '#f6f7f8',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    leftSection: {
        width: '100%',
        padding: '36px',
        backgroundColor: '#fff',
    },
    rightSection: {
        padding: '24px',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexFlow: 'column',
            flex: '0 0 400px',
            maxWidth: '400px',
            padding: '36px',
        },
    },
    headingWithBorderBottom: {
        margin: '0 0 20px 0',
        paddingBottom: '14px',
        borderBottom: 'solid 1px #cdd1d8',
    },
    centerDiv: {
        maxWidth: 425,
        margin: 'auto',
    },
    textField: {
        marginTop: theme.spacing(5),    
        maxHeight: 'inherit',
        minWidth: 'inherit',
        "& .MuiInputBase-formControl": {
            maxHeight: theme.spacing(5),
            minWidth: 'inherit',
            "& .MuiOutlinedInput-input": {
                height: theme.spacing(5),
                padding: '10.5px 14px',
                fontSize: '12px',
                color: '#758194',
                boxSizing: 'border-box'
            }
        },
        "& .MuiInputLabel-outlined": {
            fontSize: '14px',
            color: '#313236',
            fontWeight: '500',
            transform: 'translate(0px, -22px) scale(1)'
        },
        "& fieldset": {
            top: '0',
        },
        "& legend": {
            display: 'none',
        },
        "& .MuiFormHelperText-contained": {
            margin: '0',
        }
    },
    circleBtn: {
        borderRadius: 30,
        fontWeight: 'bold',
    },
}))

const ChangePassword = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [snackbarState, setSnackbarState] = useState({ open: false, })
    const [loader, setLoader] = useState(false)
    // const [passwordLoaded, setPasswordLoaded] = useState(false)
    const initState = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    }
    const validationSchema = yup.object({
        oldPassword: yup.string().required('Old Password is Required'),
        newPassword: yup.string().required('New Password is Required').matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
            "Must contain 6 character, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special character"
        ).when("oldPassword", {
            is: val => (val && val.length > 0 ? true : false),
            then: yup.string().notOneOf(
                [yup.ref("oldPassword")],
                "Your New and Old passwords are same"
            )
        }).min(6, 'Password is too short').max(25, 'Password is too long'),
        confirmPassword: yup.string().required('Confirm Password is Required').when("newPassword", {
            is: val => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
                [yup.ref("newPassword")],
                "Both password need to be the same"
            )
        }),
    })

    const onClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarState({ open: false });
    };

    const submitSignIn = async (state) => {
        setLoader(true)
        var SignupAPI = API_Path + "user/changePassword";
        var inputObj = {
            email: props.user.email,
            password: state.newPassword,
            oldPassword: state.oldPassword
        };
        axios
            .post(SignupAPI, inputObj, {
                headers: {
                    // 'Authorization': Make_Base_Auth()
                    'Authorization': "Basic " + props.auth.accessToken
                }
            })
            .then((response) => {
                if (response.data.status === "SUCCESS") {
                    setLoader(false)
                    setSnackbarState({
                        open: true,
                        message: response.data.message,
                        severity: "success",
                    });
                    setTimeout(() => {
                        dispatch({
                            type: "LOGOUT",
                            data: {}
                        });
                        history.push("/signin");
                    }, 3000);

                } else {
                    setSnackbarState({
                        open: true,
                        message: "Old password doesn't match with the given one",
                        severity: "error",
                    });
                    setLoader(false)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.error(error);
            });
    }

    useEffect(() => {
        if (props.location.state) {
            setSnackbarState({
                open: true,
                message: "Password Updated Successfully",
                severity: "success",
            });
        }
    }, [props.location.state]);
    const AdvertisementSection = (
        <div className={classes.rightSection}>
            <Advertisement />
        </div>
    )
    return (
        <div className={classes.pageContainer}>
            <div className={classes.leftSection}>
                <Formik
                    initialValues={{ ...initState }}
                    onSubmit={submitSignIn}
                    validationSchema={validationSchema}
                    validateOnBlur={true}>
                    {({ isSubmitting }) => {
                        return <Form autoComplete="off">
                            <h2 className={classes.headingWithBorderBottom}>Change Password</h2>
                            <div className={classes.centerDiv}>
                                <Field name="oldPassword" label="Old Password" placeholder="" type="password" as={FormikInput} className={clsx(classes.textField)} variant="outlined" />
                                <Field name="newPassword" label="New Password" placeholder="" type="password" as={FormikInput} className={clsx(classes.textField)} variant="outlined" />
                                <Field name="confirmPassword" label="Confirm Password" placeholder="" type="password" as={FormikInput} className={clsx(classes.textField)} variant="outlined" />
                                <Box mt={2} textAlign="right">
                                    <Button variant="contained" color="primary" className={clsx(classes.loginButton, classes.circleBtn)} type="submit" disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                </Box>
                            </div>
                        </Form>
                    }}
                </Formik>
            </div>
            {props.is_disable_ads !== 1 && props.currentPackage && props.currentPackage.length === 0 ?
                    <>{AdvertisementSection}</>
                    :
                    <>
                        {props.is_disable_ads === 1 || (props.currentPackage && props.currentPackage.length > 0 &&
                            props.currentPackage.findIndex((e) => { return e.package_name === "Ad free payment" }) > -1) ?
                            ""
                            :
                            <>{AdvertisementSection}</>
                        }
                    </>
                }
            <SnackBar open={snackbarState.open} message={snackbarState.message} severity={snackbarState.severity} onClose={onClose} />
            {loader && <Loader />}
        </div>
    )
}

const mapStateToProps = ({ loginDetails: { user, auth, is_disable_ads, currentPackage } }) => {
    return {
        user, auth, is_disable_ads, currentPackage
    };
};
export default withRouter(connect(mapStateToProps)(ChangePassword));