import { Box, Button, Hidden } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import HeadsetMicOutlinedIcon from "@material-ui/icons/HeadsetMicOutlined";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import MoreIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, NavLink, Redirect, useHistory } from "react-router-dom";
import { API_Path } from "../../../src/screens/Common/Const/index";
import ChatBubble from "../../screens/Common/Chat/ChatBubble";
// import logoImage from "../../assets/images/logo.png";
import Tooltip from "@material-ui/core/Tooltip";
import createDebateNewIcon from "../../assets/images/createdebat.svg";
import FAQImage from "../../assets/images/faq-img.jpg";
import DebateProfileMenu from "../../assets/images/menu/debateProfileMenu.svg";
import FriendsAndGroupsMenu from "../../assets/images/menu/friendsAndGroupsMenu.svg";
import LogoutMenu from "../../assets/images/menu/logoutMenu.svg";
import PasswordMenu from "../../assets/images/menu/passwordMenu.svg";
import UserMenu from "../../assets/images/menu/userMenu.svg";
import NotificationIcon from "../../assets/images/notification.svg";
import RulesIcon from "../../assets/images/rules_icon.svg"
import logoImage from "../../assets/images/Vurbil - Final-02.png";
import { askForPermissioToReceiveNotifications } from "../../push-notification";
import ConfirmationModal from "../../screens/Common/ConfirmationModal";
import CustomImage from "../../screens/Common/CustomImage";
import useUserTrackingSocket from "../../screens/Common/Sockets/useUserTrackingSocket";
import adEnquiry from "../../assets/images/adEnquireicon.svg"

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    position: "relative",
    zIndex: "1000",
    "& header": {
      backgroundColor: "transparent",
      boxShadow: "none",
      borderBottom: "1px solid rgba(117, 129, 148, .36)",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    "& a": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1.5),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    color: "#778192",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(2.25, 2.25, 2.25, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "270px",
      backgroundColor: "#f6f7f8",
      borderRadius: "30px",
      fontSize: "12px",
      color: "#758194",
      boxSizing: "border-box",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      "& button": {
        padding: "0 20px",
        borderRadius: "0",
        "&:not(:last-child)": {
          borderRight: "1px solid #cdd1d8",
        },
      },
    },
  },
  sectionMobile: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  menu: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
    },
    "& a, & button": {
      position: "relative",
      padding: "19px 20px",
      textDecoration: "none",
      fontSize: "14px",
      color: "#313236",
      fontWeight: "600",
      borderBottom: "5px solid transparent",
      textTransform: "capitalize",
      lineHeight: 1,
      // [theme.breakpoints.between('sm', 'md')]: {
      //   padding: '8px',
      //   fontSize: '12px',
      // },
      "&.active": {
        borderColor: "#ffc003",
      },
    },
    "& button": {
      borderLeft: "0!important",
      borderRight: "0!important",
    },
  },
  subMenu: {
    transform: "translate3d(0px, 60px, 0px)!important",
    minWidth: "180px",
    [theme.breakpoints.down("sm")]: {
      position: "relative!important",
      transform: "translate3d(0px, 0px, 0px)!important",
      "& .MuiPaper-root": {
        boxShadow: "none",
      },
    },
    "& > div": {
      "& > ul": {
        display: "flex",
        flexDirection: "column",
        padding: 0,
      },
    },
  },
  "#primary-search-account-menu": {
    backgroundColor: "#fff",
  },
  profileImage: {
    width: 25,
    height: 25,
    borderRadius: 25,
    objectFit: "cover",
    border: "1px solid #cdd1d8",
  },
  menuContainer: {
    "& .notifyanimate": {
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        width: "10px",
        height: "10px",
        background: "#ff1919",
        border: "1px solid #000",
        borderRadius: "50%",
        margin: "auto",
        animation: `$myEffect 2000ms infinite`,
        // transition: '.5s'
      },
    },
  },
  "@keyframes myEffect": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      // background: '#ffc003',
      transform: "scale(1.5)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  appLogo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h4": {
      margin: "-12px 0 0 0",
      fontWeight: "500",
      letterSpacing: "1px",
      lineHeight: 1,
      textTransform: "uppercase",
    },
  },
  webLogo: {
    maxWidth: "68px",
    paddingTop: "6px"
    // maxWidth: "141px",
    // maxHeight: "65px",
  },
  mobileLogo: {
    maxWidth: "50px",
    maxHeight: "65px",
  },
  createDebateIcon: {
    // color: '#FF0303',
    "& svg": {
      width: "28px",
      height: "28px",
    },
  },
  activeStatus: {
    position: "absolute",
    right: "8px",
    top: "-12px",
    "& svg": {
      boxShadow: "-1px 1px 10px -3px rgba(0, 0, 0, .5)",
      borderRadius: "50%",
      color: "#06c",
      backgroundColor: "#fff",
      fontSize: "14px",
    },
  },
}));
const AppHeader = (props) => {
  const classes = useStyles();
  const { updatedNotificationQueueList } = useUserTrackingSocket(props);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorNotify, setAnchorNotify] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [userData, setUserData] = useState({});
  const [loginPage] = useState(false);
  const [debatePage, setActiveDebatePage] = useState(false);
  const [userNotificationQueueList, setUserNotificationQueueList] = useState(
    []
  );
  const [userNotificationCount, setUserNotificationCount] = useState(0);
  const [active, setActive] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isNotificationOpen = Boolean(anchorNotify);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const history = useHistory();

  useEffect(() => {
    if (props.user !== null && props.user !== undefined) {
      props.user && setUserData(props.user);
      setUserNotificationToken();
      //  getUserNotificationQueueList();
      GetUserAdminSettings();
    }
  }, []);
  useEffect(() => {
    if (
      JSON.stringify(updatedNotificationQueueList) !==
        JSON.stringify(userNotificationQueueList) &&
      updatedNotificationQueueList !== null &&
      Object.values(updatedNotificationQueueList).length > 0
    ) {
      //Have to set classname for blinking that light
      setActive(true);
    }
    if (updatedNotificationQueueList !== null) {
      const { data = [], count = 0 } = updatedNotificationQueueList;
      setUserNotificationCount(count)
      setUserNotificationQueueList(data);
    } else {
      setUserNotificationCount(0)
      setUserNotificationQueueList([]);
    }
  }, [updatedNotificationQueueList]);

  const handleClose = () => {
    setOpen(false);
  };

  const setUserNotificationToken = async () => {
    if (
      props.user !== null &&
      props.user !== undefined &&
      props.user._id !== null
    ) {
      var token = await askForPermissioToReceiveNotifications();
      if (token !== null && token !== undefined) {
        var SignupAPI = API_Path + "api/auth/updateUserNotificationToken";
        var inputPar = {
          inputObject: {
            userId: props.user._id,
            tokenFrom: "web",
            token: token,
          },
        };
        axios
          .post(SignupAPI, inputPar, {
            headers: {
              Authorization: "Basic " + props.auth.accessToken,
            },
          })
          .then((response) => {})
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  // const getUserNotificationQueueList = async () => {
  //   if (props.user !== null && props.user !== undefined && props.user._id !== null) {
  //     var notificationqueueAPI = API_Path + "api/auth/getNotificationQueueList";
  //     var inputPar = {
  //       inputObject: {
  //         userId: props.user._id
  //       }
  //     };
  //     axios
  //       .post(notificationqueueAPI, inputPar, {
  //         headers: {
  //           // 'Authorization': Make_Base_Auth()
  //           'Authorization': "Basic " + props.auth.accessToken
  //         }
  //       })
  //       .then((response) => {
  //         if (response !== null && response.data !== null && response.data.result !== null) {
  //           setUserNotificationQueueList(response.data.result);
  //         }

  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }

  // }

  const updateNotificationReadStatus = async (notificationQueueObj) => {
    if (notificationQueueObj !== null) {
      var updatereadstatusAPI =
        API_Path + "api/auth/updateNotificationReadStatus";
      var inputPar = {
        inputObject: {
          notificationqueueId: notificationQueueObj._id,
        },
      };
      axios
        .post(updatereadstatusAPI, inputPar, {
          headers: {
            // 'Authorization': Make_Base_Auth()
            Authorization: "Basic " + props.auth.accessToken,
          },
        })
        .then((response) => {
          if (notificationQueueObj.click_action) {
            if (notificationQueueObj.click_action.includes("chatView")) {
              let URLSplit = notificationQueueObj.click_action.split("=");
              history.replace({
                search: "?chatId=" + URLSplit[URLSplit.length - 1],
              });
            } else {
              window.location.href = notificationQueueObj.click_action;
            }
          }
          setAnchorNotify(null);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorNotify(null);
    handleMobileMenuClose();
  };

  const handleDashboard = (val) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    localStorage.setItem("handleDashboard", val);
  };

  const handleClearNotification = () => {
    var ClearNotification = API_Path + "api/auth/clearallnotification";

    let inputObj = {
      inputObject: {
        userId: props.user._id,
      },
    };
    axios
      .post(ClearNotification, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleNotifyMenuOpen = (event) => {
    setActive(false);
    if (userNotificationQueueList !== null) {
      setAnchorNotify(event.currentTarget);
    }
  };

  const handleCreateDebate = () => {
    const mydebateObj = {
      debateCreate: true,
    };
    history.push("/myDebates", { mydebateObj: mydebateObj });
  };

  const handleNotifyMenuClose = (notificationQueueObj) => {
    setAnchorNotify(null);
    updateNotificationReadStatus(notificationQueueObj);
  };
  const handleLogoutDialog = () => {
    setOpen(true);
  };
  const handleLogout = async () => {
    // await updateLogoutStatus();
    dispatch({
      type: "LOGOUT",
      data: {},
    });
    dispatch({
      type: "GLOBAL_SEARCH_VAL",
      data: "",
    });
    dispatch({type: "ClearReportList",data: { reportList:[] }});
    // window.location.href = "/signin";
  };
  // const updateLogoutStatus = () => {
  //   var SignupAPI = API_Path + "api/auth/updateLoginStatus";
  //   var userId = props.user._id;
  //   var inputObj = { userId: userId, type: "Logout" };
  //   axios
  //     .post(SignupAPI, inputObj, {
  //       headers: {
  //         'Authorization': "Basic " + props.auth.accessToken
  //       }
  //     })
  //     .then((response) => {
  //       setloginPage(true);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const menuNotify = "menuNotification";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.topMenu}
    >
      <MenuItem className="userNameEmail" style={{ cursor: "default" }}>
        <h6 className="m-0">{userData.name ? userData.name : ""}</h6>
        <p>{userData.email ? userData.email : ""}</p>
      </MenuItem>
      <MenuItem component={Link} to="/accountProfile" onClick={handleMenuClose}>
        <img alt="" src={UserMenu} /> Account Profile
      </MenuItem>
      <MenuItem
        component={Link}
        to={{ pathname: "/userDashboard" }}
        onClick={handleDashboard.bind(this, "debateprofile")}
      >
        <img alt="" src={DebateProfileMenu} /> My Stats
      </MenuItem>
      <MenuItem
        component={Link}
        to={{ pathname: "/userDashboard" }}
        onClick={handleDashboard.bind(this, "friendgroupprofile")}
      >
        <img alt="" src={FriendsAndGroupsMenu} /> My Vurbers{" "}
      </MenuItem>
      <MenuItem component={Link} to="/changePassword" onClick={handleMenuClose}>
        <img alt="" src={PasswordMenu} /> Change Password
      </MenuItem>
      <MenuItem component={Link} to="/adEnquiryPage" onClick={handleMenuClose}>
        {/* <HeadsetMicOutlinedIcon
          width="26px"
          style={{ marginRight: "6px", color: "#758194" }}
        />{" "} */}
        <img style={{marginLeft:"2px"}} alt="" src={adEnquiry} />
        Ad Inquiry
      </MenuItem>
      <MenuItem component={Link} to="/vurbilRules" target="_blank" onClick={handleMenuClose}>
        <img style={{marginLeft:"2px"}} alt="" src={RulesIcon} />
        Vurbil Rules
      </MenuItem>
      <MenuItem
        component={Link}
        to="/faq/list"
        target="_blank"
        onClick={handleMenuClose}
      >
        <img alt="" src={FAQImage} width="26px" /> FAQ
      </MenuItem>

      <MenuItem component={Link} to="/contactus" onClick={handleMenuClose}>
        <img alt="" src={UserMenu} width="26px" /> Contact Us
      </MenuItem>
      {/*       
      <MenuItem onClick={handleLogout}><img alt="" src={AccountSuspendMenu} /> Suspend Account</MenuItem>
    */}

      {/*       <MenuItem component={Link} to="/debateProfile" onClick={handleMenuClose}><img alt="" src={DebateProfileMenu} /> Debate Profile</MenuItem>
       */}
      {/* <MenuItem component={Link} to={{ pathname: '/userDashboard', state: { name: 'debateprofile' } }} onClick={handleMenuClose} ><img alt="" src={DebateProfileMenu} /> Debate Profile</MenuItem>
      <MenuItem component={Link} to={{ pathname: '/userDashboard', state: { name: 'friendgroupprofile' } }} onClick={handleMenuClose}><img alt="" src={FriendsAndGroupsMenu} /> Friends &amp; Group</MenuItem> */}

      <MenuItem onClick={handleLogoutDialog}>
        <img alt="" src={LogoutMenu} /> Log out
      </MenuItem>
    </Menu>
  );
  const renderNotification = (
    <Menu
      anchorEl={anchorNotify}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuNotify}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isNotificationOpen}
      onClose={handleNotifyMenuClose}
      className={classes.topMenu}
    >
      {userNotificationQueueList.length !== 0 ? (
        <Box justifyContent="space-between" className="viewClearButton flex-order-remove">
          <h4
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "0px",
            }}
          >
            Notifications
          </h4>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "22px",
            }}
          >
            {userNotificationQueueList &&
            userNotificationQueueList.length > 0 ? (
              <Button
                size="small"
                color="primary"
                onClick={handleClearNotification}
              >
                Clear All
              </Button>
            ) : (
              ""
            )}
            <Button
              component={Link}
              to="/notification"
              size="small"
              color="primary"
              onClick={handleMenuClose}
            >
              View All
            </Button>
          </div>
        </Box>
      ) : (
        <span
          style={{ textAlign: "center", fontSize: "14px", padding: "10px" }}
        >
          No notification
        </span>
      )}
      {userNotificationQueueList &&
        userNotificationQueueList.length > 0 &&
        userNotificationQueueList
          // .slice(userNotificationQueueList.length - 8)
          .map((notificationQueueObj, index) => {
            return (
              <MenuItem
                key={index}
                onClick={handleNotifyMenuClose.bind(this, notificationQueueObj)}
              >
                <img
                  alt={notificationQueueObj.title}
                  src={
                    notificationQueueObj.icon
                      ? notificationQueueObj.icon
                      : UserMenu
                  }
                />
                <div>
                  <p>{notificationQueueObj.body}</p>
                  <span>
                    {notificationQueueObj.scheduledTime &&
                      moment(notificationQueueObj.scheduledTime).format(
                        "MMM DD,YYYY [at] h:mm A"
                      )}
                  </span>
                </div>
              </MenuItem>
            );
          })}
      {/* <MenuItem onClick={handleNotifyMenuClose}>
        <img alt="" src={UserMenu} />
        <div>
          <p>Congrats!! you are now premium member</p>
          <span>May 21,2020 at 05:06PM</span>
        </div>
      </MenuItem>
      <MenuItem onClick={handleNotifyMenuClose}>
        <img alt="" src={UserMenu} />
        <div>
          <p>Congrats!! you are now premium member</p>
          <span>May 21,2020 at 05:06PM</span>
        </div>
      </MenuItem>
      <MenuItem onClick={handleNotifyMenuClose}>
        <img alt="" src={UserMenu} />
        <div>
          <p>Congrats!! you are now premium member</p>
          <span>May 21,2020 at 05:06PM</span>
        </div>
      </MenuItem> */}
    </Menu>
  );

  // Sub Menu Toggle
  // const [openSubMenu, setOpenSubMenu] = React.useState(false);
  // const anchorRef = React.useRef(null);

  // const handleToggle = (e) => {
  //   setOpenSubMenu((prevOpen) => !prevOpen);
  // };

  // const handleSubMenuClose = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }

  //   setOpenSubMenu(false);
  // };

  // function handleListKeyDown(event) {
  //   if (event.key === "Tab") {
  //     event.preventDefault();
  //     setOpenSubMenu(false);
  //   }
  // }

  // const prevOpen = React.useRef(openSubMenu);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && openSubMenu === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = openSubMenu;
  // }, [openSubMenu]);

  const navMenu = (
    <div className={classes.menu}>
      {/* <Link to="/activeDebateList" className={tab1} onClick={handleTab.bind(this, 1)}>Live Debates</Link>
      <Link to="/recorded" className={tab2} onClick={handleTab.bind(this, 2)}>Recorded</Link>
      <Link to="/kpnews" className={tab3} onClick={handleTab.bind(this, 3)}>Broadcast</Link>
      <Link to="/myDebates" className={tab4} onClick={handleTab.bind(this, 4)}>My Debates</Link> */}
      <NavLink to="/vurbs" activeClassName="active">
        Vurbs
      </NavLink>
      <NavLink to="/activeDebateList" activeClassName="active">
        Live Debates
      </NavLink>
      <NavLink to="/recorded" activeClassName="active">
        Recorded Debates
      </NavLink>
      {/* <Box position="relative">
        <Button
          ref={anchorRef}
          aria-controls={openSubMenu ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={`${
            window.location.pathname === "/kpnews" ||
            window.location.pathname === "/kpshow" ||
            window.location.pathname === "/kpnewstips"
              ? "active"
              : ""
          }`}
        >
          KP News
        </Button>
        <Popper
          open={openSubMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className={classes.subMenu}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleSubMenuClose}>
                  <MenuList
                    autoFocusItem={openSubMenu}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <NavLink to="/kpshow" activeClassName="active">
                      KP Show
                    </NavLink>
                    <NavLink to="/kpnews" activeClassName="active">
                      KP Newscast
                    </NavLink>
                    <NavLink to="/kpnewstips" activeClassName="active">
                      KP News Tips
                    </NavLink>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box> */}

      <NavLink to="/myDebates" activeClassName="active">
        My Debates
      </NavLink>
    </div>
  );
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {navMenu}
      {/* <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <img alt="" src={SettingIcon} />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <img alt="" src={NotificationIcon} />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
    </Menu>
  );

  const handleSearch = (event) => {
    if (
      window.location.pathname === "/recorded" ||
      window.location.pathname === "/kpnews" ||
      window.location.pathname === "/kpshow" ||
      window.location.pathname === "/kpnewstips" ||
      window.location.pathname === "/myDebates" ||
      window.location.pathname === "/accountProfile" ||
      window.location.pathname === "/changePassword" ||
      window.location.pathname === "/userDashboard" ||
      window.location.pathname === "/vurbs"
    ) {
      setActiveDebatePage(true);
    } else {
      setActiveDebatePage(false);
    }
    //window.location.pathname !== "/activeDebateList" &&
    dispatch({
      type: "GLOBAL_SEARCH_VAL",
      data: event.target.value,
    });
  };

  const GetUserAdminSettings = async () => {
    var ValidateGetUserAdminSettings = API_Path + "api/user/admin_settings";
    let inputObj = {
      inputObject: {
        userId: props.user._id,
      },
    };
    dispatch({
      type: "LOGIN_GLOBAL_DETAILS",
      data: { ...props.loginDetails, vurbApiLoading: false },
    });
    axios
      .post(ValidateGetUserAdminSettings, inputObj, {
        headers: {
          Authorization: "Basic " + props.auth.accessToken,
        },
      })
      .then((response) => {
        var loginDetailsObject = props.loginDetails;
        loginDetailsObject["application_name"] =
          response.data.result.application_name;
        loginDetailsObject["currentPackage"] =
          response.data.result.currentPaidPackage;
        loginDetailsObject["is_disable_ads"] =
          response.data.result.is_disable_ads;
        loginDetailsObject["is_disable_broadcast"] =
          response.data.result.is_disable_broadcast;
        loginDetailsObject["logo"] = response.data.result.logo;
        loginDetailsObject["allBlockedUsers"] = response.data.result.allBlockedUsers;
        loginDetailsObject["vurbApiLoading"] = true;
        dispatch({
          type: "LOGIN_GLOBAL_DETAILS",
          data: loginDetailsObject,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <div className={classes.grow}>
        {loginPage ? <Redirect to="/signin" /> : ""}
        {debatePage ? <Redirect to="/activeDebateList" /> : ""}
        <AppBar position="static">
          <Toolbar>
            <div
              className={clsx(classes.menuContainer, classes.sectionDesktop)}
            >
              <Typography
                className={classes.title}
                style={{ cursor: "pointer" }}
                variant="h6"
                noWrap
              >
                <Link
                  to="/vurbs"
                  className={" menuLogo"}
                  // onClick={handleTab.bind(this, 1)}
                >
                  <img className={classes.webLogo} alt="" src={logoImage} />
                  {/* <Box className={classes.appLogo}>
                  {props.loginDetails !== null && props.loginDetails !== undefined ?
                    <>
                      {props.loginDetails && props.loginDetails.logo && props.loginDetails.logo !== null && props.loginDetails.logo !== "" && props.loginDetails.logo !== undefined ?
                        <img alt="" src={props.loginDetails.logo && props.loginDetails.logo.url} style={{ height: '54px', objectFit: 'contain', marginLeft: '-14px' }} /> :
                        <img alt="Logo" src={beeLogo} style={{ height: '40px', objectFit: 'contain', marginLeft: '-14px' }} />
                      }
                      <h4>
                        {props.loginDetails && props.loginDetails.application_name !== null && props.loginDetails.application_name !== "" && props.loginDetails.application_name !== undefined ?
                          props.loginDetails.application_name :
                          <img alt="Logo" src={textLogo} style={{ width: '140px', height: '15px', objectFit: 'contain' }} />
                        }
                      </h4>
                    </>
                    :
                    <img alt="" src={logoImage} />
                  }
                </Box> */}
                </Link>
              </Typography>
              <div className={classes.grow} />
              {navMenu}
              <div className={classes.grow} />
              <Hidden mdDown>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Live Debate Search…"
                    onChange={handleSearch}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                </div>
              </Hidden>
              {/* {window.location.pathname !== "/myDebates" && ( */}
              {/* <Tooltip title="Create Debate"> */}
              {/* <IconButton
                    edge="end"
                    aria-label="Create Debate"
                    aria-controls={menuNotify}
                    aria-haspopup="true"
                    onClick={handleCreateDebate}
                    color="primary"
                    className={classes.createDebateIcon}
                  >
                    <ControlPointIcon />
                   <img style={{width:"29px"}} src={createDebateNewIcon}/>
                  </IconButton> */}
              <ChatBubble />
              {/* </Tooltip> */}
              {/* )} */}
              <IconButton
                edge="end"
                aria-label="show 17 new notifications"
                aria-controls={menuNotify}
                aria-haspopup="true"
                onClick={handleNotifyMenuOpen}
                color="inherit"
              >
                <Badge
                  overlap="rectangular"
                  badgeContent={userNotificationCount}
                  color="secondary"
                  className={active === true ? "notifyanimate" : ""}
                >
                  <img alt="" src={NotificationIcon} />
                </Badge>
              </IconButton>
              {/* <IconButton aria-label="" color="inherit">
              <img alt="" src={SettingIcon} />
            </IconButton> */}
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              ><div style={{position:"relative"}}>
                <CustomImage
                  userImage={userData.profile_image}
                  size="28px"
                  marginRight="0"
                />
                {!!userData.badge_status && (
                  <span
                    className={classes.activeStatus}
                    title="Verified User/Public Figure"
                  >
                    <VerifiedUserRoundedIcon />
                  </span>
                )}
                </div>
              </IconButton>
            </div>
            <div className={clsx(classes.menuContainer, classes.sectionMobile)}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
              <Typography
                style={{ cursor: "pointer", display: "flex" }}
                variant="h6"
                noWrap
                // onClick={handleDefaultPage}
              >
                <img className={classes.mobileLogo} alt="" src={logoImage} />
              </Typography>
              <div className={classes.grow} />
              {window.location.pathname !== "/myDebates" && (
                <Tooltip title="Create Debate">
                  <IconButton
                    edge="end"
                    aria-label="Create Debate"
                    aria-controls={menuNotify}
                    aria-haspopup="true"
                    onClick={handleCreateDebate}
                    color="primary"
                    className={classes.createDebateIcon}
                  >
                    {/* <ControlPointIcon /> */}
                    <img
                      style={{ width: "29px" }}
                      src={createDebateNewIcon}
                      alt=""
                    />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                edge="end"
                aria-label="show 17 new notifications"
                aria-controls={menuNotify}
                aria-haspopup="true"
                onClick={handleNotifyMenuOpen}
                color="inherit"
              >
                <Badge
                  overlap="rectangular"
                  badgeContent={userNotificationCount}
                  // badgeContent={userNotificationQueueList.length}
                  color="secondary"
                  className={active === true ? "notifyanimate" : ""}
                >
                  <img alt="" src={NotificationIcon} />
                </Badge>
              </IconButton>
              {/* <IconButton aria-label="" color="inherit">
              <img alt="" src={SettingIcon} />
            </IconButton> */}
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <CustomImage
                  userImage={userData.profile_image}
                  size="25px"
                  marginRight="0"
                />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        {renderNotification}
      </div>
      <ConfirmationModal
        openDialog={open}
        modalHeading="Message"
        handleDialogClose={handleClose}
        handleDelete={handleLogout}
        modalMessage="Are you sure you want to log out?"
        btnCancelName="Cancel"
        btnNoConfirmName="Confirm"
        maxWidth="xs"
      />
    </>
  );
};

// export default LiveDebate
const mapStateToProps = ({
  loginDetails: { user, auth, is_disable_broadcast, logo },
  GlobalSearch: globalSearch,
  loginDetails,
}) => {
  return {
    user,
    auth,
    is_disable_broadcast,
    logo,
    globalSearch,
    loginDetails,
  };
};

export default connect(mapStateToProps)(AppHeader);
// export default AppHeader;
